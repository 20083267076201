import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import "../valetCSS/valetapp.css"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// import { Formik, Form, Field, ErrorMessage } from "formik"
import { GoPencil } from "react-icons/go" // user
import { HiOutlineArrowLeft } from "react-icons/hi" // user
import { BsCheckLg } from "react-icons/bs"
import { MdClose } from "react-icons/md"
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai" // user
import * as Yup from "yup"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import { useRef } from "react"
import { useParams } from "react-router-dom"
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar"

import "react-circular-progressbar/dist/styles.css"
import { Card, CardBody, Col, Row, Badge, Form } from "reactstrap"
import { Container, Input } from "reactstrap"
import { Link } from "react-router-dom"
import { restartButtonRequest } from "store/actions"
import { fetchViewFlashcardButtonRequest } from "store/actions"
import { fetchViewFlashcardRequest } from "store/actions"
// import avatar4 from "../assets/images/users/avatar-1.jpg"
import Dropzone from "react-dropzone"
import Dbs from "../../Assets/Images/dbs.png"
import DDz from "../../Assets/Images/ddz.png"

import Breadcrumbs from "components/Common/Breadcrumb"
const FlashCardResult = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { Mid, Tid, PPTid } = useParams()
  const [isEdit, setIsEdit] = useState(false)
  const [isAnswer, setIsAnswer] = useState(false)
  const [title, setTitle] = useState("Card Title")
  const [qna, setQNA] = useState(
    "Lorem ipsum dolor sit amet cons debitis cumque repellendus. Facilis, earum fugit."
  )
  const ViewFlashcardButton = useSelector(
    state => state.fetchViewFlashcardButtonReducer.ViewFlashcardButton
  )
  const ViewFlashcardList = useSelector(
    state => state.fetchViewFlashcardReducer.ViewFlashcard
  )
  console.log("ViewFlashcardButton", ViewFlashcardButton)
  useEffect(() => {
    dispatch(fetchViewFlashcardButtonRequest(Mid, Tid, PPTid))
    dispatch(fetchViewFlashcardRequest(PPTid, successCallBack2))
  }, [])
  const successCallBack2 = () => {}
  const restartHandler = () => {
    dispatch(restartButtonRequest(Mid, Tid, PPTid, restartButtonCallBack))
  }
  const restartButtonCallBack = () => {
    history.goBack()
  }
  const ContinueHandler = () => {
    history.push(`/topic_lessioncard/${Mid}/${Tid}`)
  }
  const showAnswer = () => {
    setIsEdit(false)
    setIsAnswer(!isAnswer)
  }

  const showQuestion = () => {
    setIsEdit(false)
    setIsAnswer(!isAnswer)
  }
  const handleTitleChange = e => {
    setTitle(e.target.value)
  }
  const handleQNAChange = e => {
    setQNA(e.target.value)
  }
  const goBack = () => {
    history.goBack()
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  const nextPage = () => {
    history.push("/lessioncard")
  }
  return (
    <div className={`main-content${classAlign}`}>
      <MetaTags>
        <title>FlashCard | StudyBudi</title>
      </MetaTags>
      {/* {(document.title = "Company | Nater")} */}

      <div className="page-content">
        <ToastContainer />
        <div
          className="inner-page-content"
          style={{
            paddingRight: "30px",
            paddingLeft: "30px",
            paddingTop: "5px",
          }}
        >
          <div className="lessionHeading">
            <div style={{ width: "10%" }}>
              <HiOutlineArrowLeft
                style={{
                  fontSize: "50px",
                  fontWeight: "200",
                  color: "#CACAD0",
                  cursor: "pointer",
                }}
                onClick={goBack}
              />
            </div>
            <div
              style={{
                // border: "1px solid #ddd",
                width: "90%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "22px",
                letterSpacing: "2px",
                color: "#404445",
                fontWeight: "500",
                textWrap: "nowrap",
                alignSelf: "center",
              }}
            >
              {ViewFlashcardList?.topic_add_title}
            </div>
          </div>
          <div
            style={{
              height: "100vh",
              backgroundColor: "white",
              borderRadius: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "3vw",
                alignItems: "center",
                // border: "1px solid #ddd",
              }}
            >
              <button type="button" className="pinkbutton">
                <MdClose
                  className="pinkbuttoncross"
                  // style={{
                  //   fontSize: "50px",
                  //   marginRight: "20px",
                  //   color: "white",
                  //   fontWeight: "bold",
                  //   paddingBottom: "10px",
                  // }}
                />
                <span
                  className="pinkbuttontext"
                  // style={{
                  //   fontSize: "35px",
                  //   color: "white",
                  //   margin: "10px",
                  // }}
                >
                  {ViewFlashcardButton?.red_count}
                </span>
              </button>
              <button type="button" className="greenbutton">
                <BsCheckLg className="pinkbuttoncross" />
                <span className="pinkbuttontext">
                  {ViewFlashcardButton?.green_count}
                </span>
              </button>
            </div>
            <div
              className="restartDiv"
              // style={{
              //   display: "flex",
              //   gap: "2rem",
              //   alignItems: "center",
              //   // border: "1px solid #ddd",
              //   marginTop: "150px",
              //   gap: "20vw",
              // }}
            >
              <button
                type="button"
                className="restartButton"
                onClick={restartHandler}
                style={{ backgroundColor: "#171c46", color: "#BCBCBC" }}
              >
                RESTART
              </button>
              <button
                type="button"
                className="restartButton"
                onClick={ContinueHandler}
                style={{ backgroundColor: "#171c46", color: "#BCBCBC" }}
              >
                CONTINUE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
FlashCardResult.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(FlashCardResult)
