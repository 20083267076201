import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import StudyBudiLogo from "../../pages/Components/Assets/Images/StudyBudiLogo.png"
import "../../App.css"
import { withTranslation } from "react-i18next"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import { ResetPasswordRequest } from "store/actions"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

const ResetPassword = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { token, tokens } = useParams()
  console.log("token", token)
  console.log("tokens", tokens)
  const [show, setShow] = useState(false)
  const initialValues = {
    password: "",
    confirm_password: "",
  }

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required!")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain at least one letter, one number, and one special character"
      ),
    confirm_password: Yup.string()
      .required("Confirm Password is required!")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain at least one letter, one number, and one special character"
      ),
  })

  const formSubmit = values => {
    console.log("emailvalue", values)
    dispatch(
      ResetPasswordRequest(
        values.password,
        values.confirm_password,
        token,
        tokens
      )
    )
    setTimeout(() => {
      history.push("/login")
    }, 2000)
  }
  const toggleButton = () => {
    setShow(prevState => !prevState)
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>forgot Password | StudyBudi</title>
      </MetaTags>
      <ToastContainer autoClose={2000} />
      <div className="welcomePageBackground" style={{ overflowX: "hidden" }}>
        <div className="AppLogin">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={formSubmit}
          >
            <Form
              id="formLogin"
              // onSubmit={handleSubmit}
              // onClick={handleSubmit}
            >
              <img
                src={StudyBudiLogo}
                style={{
                  width: "180px",
                  height: "180px",
                  marginBottom: "20px",
                }}
              />

              <div className="">
                <div className="loginfields">
                  <div className="fieldsSignup">
                    {/* <Label className="labelltrClass">Password</Label> */}
                    <div className="password-lgn real-psd">
                      <Field
                        name="password"
                        type={show ? "text" : "password"}
                        placeholder="Enter new password"
                        className="form-control"
                        // value={values.password}
                        // onChange={handleChange}
                        style={{
                          backgroundColor: "#f1f1f9",
                          height: "41px",
                          width: "327px",
                        }}
                      />
                      <button
                        type="button"
                        className="passwordBtn"
                        onClick={toggleButton}
                        // style={{ position: "absolute" }}
                      >
                        {show ? <AiFillEye /> : <AiFillEyeInvisible />}
                      </button>
                    </div>
                    <p className="text-danger error-msg">
                      <ErrorMessage name="password" />
                    </p>
                  </div>

                  <div className="fieldsSignup">
                    {/* <Label className="labelltrClass">Password</Label> */}
                    <div className="password-lgn real-psd">
                      <Field
                        name="confirm_password"
                        type={show ? "text" : "password"}
                        placeholder="Enter confirm password"
                        className="form-control"
                        // value={values.password}
                        // onChange={handleChange}
                        style={{
                          backgroundColor: "#f1f1f9",
                          height: "41px",
                          width: "327px",
                        }}
                      />
                      {/* <button
                        type="button"
                        className="passwordBtn"
                        onClick={toggleButton1}
                        // style={{ position: "absolute" }}
                      >
                        {show1 ? <AiFillEye /> : <AiFillEyeInvisible />}
                      </button> */}
                    </div>
                    <p className="text-danger error-msg">
                      <ErrorMessage name="confirm_password" />
                    </p>
                  </div>
                </div>
                <div className="submit-btn-lgn" style={{ marginTop: "20px" }}>
                  <button
                    style={{ fontWeight: "600", fontSize: "0.8rem" }}
                    className="loginbtnLgIn"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </React.Fragment>
  )
}

ResetPassword.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ResetPassword)
