import {
  TOPIC_DETAIL_REQUEST,
  TOPIC_DETAIL_CANCEL,
  SET_NETWORK_STATUS,
} from "./actionType"
import {
  call,
  put,
  takeEvery,
  race,
  take,
  cancelled,
  all,
} from "redux-saga/effects"
import axios from "axios"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"
import {
  fetchTopicDetailFailure,
  fetchTopicDetailSuccess,
  setNetworkStatus,
} from "./action"
import { ToastContainer, toast } from "react-toastify"

function* fetchTopicDetail(action) {
  const { data, successCallBack } = action.payload
  const source = axios.CancelToken.source()

  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/api/Topic_pdf_upload/`,
      data,
      {
        cancelToken: source.token,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchTopicDetailSuccess(response.data))
    successCallBack(response.data)
    console.log("topic response", response.data)
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request was cancelled", error)
    } else {
      const loginError = error.response
      console.log("topic error", loginError)
      console.log("Request was cancelled 1", error)
      {
        loginError?.data?.error
          ? toast.error(`${loginError?.data?.error}`)
          : toast.error("something went wrong we are working on it")
      }
      yield put(fetchTopicDetailFailure(error))
    }
  } finally {
    if (yield cancelled()) {
      source.cancel()
    }
  }
}

function* fetchTopicDetailSagaa() {
  while (true) {
    const action = yield take(TOPIC_DETAIL_REQUEST)
    const task = yield race({
      task: call(fetchTopicDetail, action),
      cancel: take(TOPIC_DETAIL_CANCEL),
    })

    if (task.cancel) {
      source.cancel("User cancelled the request.")
    }
  }
}
function* watchNetworkStatus() {
  while (true) {
    // Check initial status
    yield put(setNetworkStatus(navigator.onLine))

    // Watch for online and offline events
    const { online, offline } = yield race({
      online: take("online"),
      offline: take("offline"),
    })

    if (online) {
      console.log("Network is online")

      yield put(setNetworkStatus(true))
    } else if (offline) {
      console.log("Network is offline")
      yield put(setNetworkStatus(false))
    }
  }
}

export default function* fetchTopicDetailSaga() {
  yield all([fetchTopicDetailSagaa(), watchNetworkStatus()])
}

// export default fetchTopicDetailSaga

// function* fetchTopicDetailSaga() {
//   yield takeEvery(TOPIC_DETAIL_REQUEST, fetchTopicDetail)
// }

// export default fetchTopicDetailSaga
