import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import ecommerceSaga from "./e-commerce/saga"
import calendarSaga from "./calendar/saga"
import chatSaga from "./chat/saga"
import cryptoSaga from "./crypto/saga"
import invoiceSaga from "./invoices/saga"
import projectsSaga from "./projects/saga"
import tasksSaga from "./tasks/saga"
import mailsSaga from "./mails/saga"
import contactsSaga from "./contacts/saga"
import dashboardSaga from "./dashboard/saga"
import dashboardSaasSaga from "./dashboard-saas/saga"
import { watchLogin } from "./Valet/Authentication/sagas"
import addCompanySaga from "./Valet/Admin/Company/AddCompany/saga"
import addvaletSaga from "./Valet/Company/Valet/AddValet/saga"
import fetchcompanySaga from "./Valet/Admin/Company/CompanyList/saga"
import fetchvaletSaga from "./Valet/Company/Valet/ValetList/saga"
import fetchViewcompanySaga from "./Valet/Admin/Company/ViewCompany/saga"
import editCompanySaga from "./Valet/Admin/Company/EditCompany/saga"
import { DeleteCompanysaga } from "./Valet/Admin/Company/DeleteCompany/saga"
import fetchViewvaletSaga from "./Valet/Company/Valet/ViewValet/saga"
import { DeleteValetsaga } from "./Valet/Company/Valet/DeleteValet/saga"
import editValetSaga from "./Valet/Company/Valet/EditValet/saga"
import fetchuserSaga from "./Valet/Admin/User/UserList/saga"
import fetchViewuserSaga from "./Valet/Admin/User/ViewUser/saga"
import fetchbookingSaga from "./Valet/Admin/Booking/Booking List/saga"
import fetchViewbookingSaga from "./Valet/Admin/Booking/View Booking/saga"
import { DeleteBookingsaga } from "./Valet/Admin/Booking/Delete Booking/saga"
import fetchbookingvaletSaga from "./Valet/Company/BookingValet/BookingValet List/saga"
import fetchViewbookingvaletSaga from "./Valet/Company/BookingValet/ViewBookingValet/saga"
import addCompanyprofileSaga from "./Valet/Admin/Company/CompanyProfile/saga"
import fetchpaymentSaga from "./Valet/Admin/Payment/PaymentList/saga"
import fetchpaymentvaletSaga from "./Valet/Company/PaymentValet/PaymentValetList/saga"
import fetchdashboardSaga from "./Valet/Admin/Dashboard/saga"
import fetchdashboardvaletSaga from "./Valet/Company/DashboardValet/saga"
import fetchViewPaymentSaga from "./Valet/Admin/Payment/ViewPayment/saga"
import fetchcompanyfilterSaga from "./Valet/Admin/Company/CompanyListFilter.js/saga"
import fetchusertransactionSaga from "./Valet/Admin/User/UserTransaction/saga"
import RegisterUserSaga from "./Valet/Registration/saga"
import fetchGoogleDetailSaga from "./Valet/GoogleLogin/saga"
import addCourseSaga from "./Valet/User/Course/AddCourse/saga"
import fetchViewCourseSaga from "./Valet/User/Course/ViewCourse/saga"
import addModuleSaga from "./Valet/User/Course/AddModules/saga"
import ForgotPasswordSaga from "./Valet/ForgotPassword/saga"
import ResetPasswordSaga from "./Valet/ResetPassword/saga"
import fetchViewModuleSaga from "./Valet/User/Course/ViewModule/saga"
import fetchTopicDetailSaga from "./Valet/User/Course/TopicDetail/saga"
import addTopicNameSaga from "./Valet/User/Course/AddTopicName/saga"
import fetchViewTopicDetailSaga from "./Valet/User/Course/ViewTopicDetail/saga"
import fetchSingleModuleDetailSaga from "./Valet/User/Course/SingleModule/saga"
import fetchViewFlashcardSaga from "./Valet/User/Course/FlashCardList/saga"
import fetchSingleFlashcardDetailSaga from "./Valet/User/Course/SingleFlashcard/saga"
import addFlashcardButtonSaga from "./Valet/User/Course/AddFlashcardButton/saga"
import fetchViewFlashcardButtonSaga from "./Valet/User/Course/ViewFlashcardButton/saga"
import addFavModuleSaga from "./Valet/User/Course/FavModule/saga"
import addFavTopicSaga from "./Valet/User/Course/FavTopic/saga"
import fetchViewFavouriteSaga from "./Valet/User/Favourites/Favourites/saga"
import editFlashcardSaga from "./Valet/User/Course/EditFlashcard/saga"
import fetchViewLessoncardDetailSaga from "./Valet/User/Course/ViewLessoncard/saga"
import addFavLessoncardSaga from "./Valet/User/Course/FavLessoncard/saga"
import addUserDetailSaga from "./Valet/User/Account/AddUserDetail/saga"
import fetchViewUserDetailSaga from "./Valet/User/Account/ViewUserDetail/saga"
import addReferLinkSaga from "./Valet/User/Account/AddReferLink/saga"
import fetchViewReferLinkSaga from "./Valet/User/Account/ViewReferLink/saga"
import editCourseSaga from "./Valet/User/Course/EditCourse/saga"
import fetchViewRecentModuleSaga from "./Valet/User/Course/ViewRecentModule/saga"
import restartButtonSaga from "./Valet/User/Course/RestartButton/saga"
export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(mailsSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(watchLogin),
    fork(addCompanySaga),
    fork(addvaletSaga),
    fork(fetchcompanySaga),
    fork(fetchvaletSaga),
    fork(fetchViewcompanySaga),
    fork(editCompanySaga),
    fork(DeleteCompanysaga),
    fork(fetchViewvaletSaga),
    fork(DeleteValetsaga),
    fork(editValetSaga),
    fork(fetchuserSaga),
    fork(fetchViewuserSaga),
    fork(fetchbookingSaga),
    fork(fetchViewbookingSaga),
    fork(DeleteBookingsaga),
    fork(fetchbookingvaletSaga),
    fork(addCompanyprofileSaga),
    fork(fetchpaymentSaga),
    fork(fetchViewbookingvaletSaga),
    fork(fetchpaymentvaletSaga),
    fork(fetchdashboardSaga),
    fork(fetchdashboardvaletSaga),
    fork(fetchViewPaymentSaga),
    fork(fetchcompanyfilterSaga),
    fork(fetchusertransactionSaga),
    fork(RegisterUserSaga),
    fork(fetchGoogleDetailSaga),
    fork(addCourseSaga),
    fork(fetchViewCourseSaga),
    fork(addModuleSaga),
    fork(ForgotPasswordSaga),
    fork(ResetPasswordSaga),
    fork(fetchViewModuleSaga),
    fork(fetchTopicDetailSaga),
    fork(addTopicNameSaga),
    fork(fetchViewTopicDetailSaga),
    fork(fetchSingleModuleDetailSaga),
    fork(fetchViewFlashcardSaga),
    fork(fetchSingleFlashcardDetailSaga),
    fork(addFlashcardButtonSaga),
    fork(fetchViewFlashcardButtonSaga),
    fork(addFavModuleSaga),
    fork(addFavTopicSaga),
    fork(fetchViewFavouriteSaga),
    fork(editFlashcardSaga),
    fork(fetchViewLessoncardDetailSaga),
    fork(addFavLessoncardSaga),
    fork(addUserDetailSaga),
    fork(fetchViewUserDetailSaga),
    fork(addReferLinkSaga),
    fork(fetchViewReferLinkSaga),
    fork(editCourseSaga),
    fork(fetchViewRecentModuleSaga),
    fork(restartButtonSaga),
  ])
}
