import {
  ADD_FAV_MODULE_REQUEST,
  ADD_FAV_MODULE_FAILURE,
  ADD_FAV_MODULE_SUCCESS,
} from "./actionType"

export const addFavModuleRequest = (id, isFav, addFavModuleCallBack) => (
  console.log("viewid==>", id),
  {
    type: ADD_FAV_MODULE_REQUEST,
    payload: { id, isFav, addFavModuleCallBack },
  }
)

export const addFavModuleSuccess = FavModuleDetail => ({
  type: ADD_FAV_MODULE_SUCCESS,
  payload: FavModuleDetail,
})

export const addFavModuleFailure = error => ({
  type: ADD_FAV_MODULE_FAILURE,
  payload: error,
})
