import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import "../valetCSS/valetapp.css"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// import { Formik, Form, Field, ErrorMessage } from "formik"
import { BiChevronDown } from "react-icons/bi" // user
import { HiOutlineArrowLeft } from "react-icons/hi" // user
import * as Yup from "yup"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import { useRef } from "react"
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import { Card, CardBody, Col, Row, Form } from "reactstrap"
import { Container, Input } from "reactstrap"
import { Link } from "react-router-dom"
// import avatar4 from "../assets/images/users/avatar-1.jpg"
import Dropzone from "react-dropzone"
import Dbs from "../../Assets/Images/dbs.png"
import DDz from "../../Assets/Images/ddz.png"
import { fetchViewModuleRequest } from "store/actions"
import { fetchViewTopicDetailRequest } from "store/actions"
import { fetchTopicDetailRequest } from "store/actions"
import { cancelTopicDetailRequest } from "store/actions"
import { fetchSingleModuleDetailRequest } from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
const TopicDetail = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { Mid, Tid } = useParams()
  const [setTitle, setSetTitle] = useState("")
  const [description, setDescription] = useState("")
  const [selectedFiles, setselectedFiles] = useState([])
  const [loader, setLoader] = useState(0)
  const [show, setShow] = useState(false)
  const [moduleOption, setModuleOption] = useState(Mid)
  const [topicOption, setTopicOption] = useState(Tid)
  const [topicName, setTopicName] = useState("")
  // function handleAcceptedFiles(files) {
  //   console.log("files", files)
  //   files.map(file =>
  //     Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //       formattedSize: formatBytes(file.size),
  //     })
  //   )

  //   setselectedFiles(files)
  // }

  useEffect(() => {
    dispatch(fetchViewModuleRequest(ViewModuleCallBack))
    dispatch(fetchViewTopicDetailRequest(moduleOption))
    dispatch(fetchSingleModuleDetailRequest(Mid))
  }, [])

  const ViewModuleData = useSelector(
    state => state.fetchViewModuleReducer?.ViewModule?.data
  )
  const ViewTopicNameData = useSelector(
    state => state.fetchViewTopicDetailReducer?.ViewTopicDetail
  )
  const ViewSIngleModuleData = useSelector(
    state => state.fetchSingleModuleDetailReducer?.SingleModuleDetail
  )
  const ViewModuleCallBack = response => {}
  const handleModuleNameChange = event => {
    setModuleOption(event.target.value)
    dispatch(fetchViewTopicDetailRequest(event.target.value))
    dispatch(fetchSingleModuleDetailRequest(event.target.value))
  }
  const handleTopicNameChange = event => {
    setTopicOption(event.target.value)
  }
  const isOnline = useSelector(state => state.fetchTopicDetailReducer.isOnline)
  console.log("isOnline", isOnline)

  // function handleAcceptedFiles(files) {
  //   console.log("files", files)

  //   // Check if there's a file and it's a valid instance of File
  //   if (files.length && files[0] instanceof File) {
  //     const file = files[0]
  //     const updatedFile = Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //       formattedSize: formatBytes(file.size),
  //     })

  //     setselectedFiles([updatedFile]) // set the state with the single file
  //   } else {
  //     toast.error("No valid file provided")
  //   }
  // }

  function handleAcceptedFiles(files) {
    console.log("files", files)

    // Check if there's a file and it's a valid instance of File
    if (files.length && files[0] instanceof File) {
      const file = files[0]
      const fileExtension = file.name.split(".").pop().toLowerCase() // Extract the file extension

      // Allowed formats
      const allowedFormats = ["ppt", "pptx", "docx", "pdf"]

      if (allowedFormats.includes(fileExtension)) {
        const updatedFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })

        setselectedFiles([updatedFile]) // set the state with the single file
      } else {
        toast.error("File format not supported")
      }
    } else {
      toast.error("No valid file provided")
    }
  }

  // function handleAcceptedFiles(files) {
  //   console.log("files", files)

  //   const file = files[0]
  //   const updatedFile = Object.assign(file, {
  //     preview: URL.createObjectURL(file),
  //     formattedSize: formatBytes(file.size),
  //   })

  //   setselectedFiles([updatedFile]) // set the state with the single file
  // }

  console.log("selectedFiles", selectedFiles[0])
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const initialValues = {
    companyName: "",
    email: "",
    // phone: "",
    password: "",
    companyId: "",
    bookingFees: "",
  }
  const validationSchema = Yup.object({
    companyName: Yup.string().required(props.t("Name is Required")),
    email: Yup.string()
      .matches(
        /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i,
        props.t("Invalid email")
      )
      .required(props.t("Email is required")),
    // phone: Yup.string().required(props.t("Phone Number is Required")),

    companyId: Yup.string()
      .max(15, props.t("Must be 15 characters or less"))
      .required(props.t("Company ID is Required")),
    password: Yup.string()
      .required(props.t("Password is Required"))
      .min(8, props.t("Password must have atleast 8 Characters"))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        props.t(
          "Password must contain at least one letter, one number, and one special character"
        )
      ),
    bookingFees: Yup.string().required(props.t("Booking Fees is Required")),
    // address: Yup.string().required(props.t("Address is Required")),
    // profile: Yup.mixed().required(props.t("Profile is Required")),
    // avatar: Yup.mixed().required(props.t("Avatar is Required")),
    // address: Yup.string().required(props.t("Address is Required"))
  })
  const handleSubmit = values => {
    if (!setTitle) {
      toast.error("Title is required!") // You can replace this with a more user-friendly message or a toast notification
      return
    }

    // Validate description or file
    if (!description && selectedFiles.length === 0) {
      toast.error("Either a description or a file is required!") // Again, replace this with a more user-friendly method of showing error
      return
    }
    if (description && selectedFiles.length !== 0) {
      toast.error("Either a description or a file is required!") // Again, replace this with a more user-friendly method of showing error
      return
    }
    setShow(true)
    const formData = new FormData()
    if (description) {
      // formData.append("presentation_file", selectedFiles[0])
      formData.append("topic", topicOption ? topicOption : Tid)
      formData.append("title", setTitle)
      formData.append("description", description)
    } else {
      formData.append("presentation_file", selectedFiles[0])
      formData.append("topic", topicOption ? topicOption : Tid)
      formData.append("title", setTitle)
    }
    dispatch(fetchTopicDetailRequest(formData, successCallBack))

    // setTimeout(() => {
    //   setLoader(20)
    // }, 1000)
    // setTimeout(() => {
    //   setLoader(50)
    // }, 2000)
    // setTimeout(() => {
    //   setLoader(75)
    // }, 3000)
    // setTimeout(() => {
    //   setLoader(80)
    // }, 4000)
    let minVal = 0
    const maxVal = 99

    function setRandomValue() {
      const randomValue =
        minVal + Math.floor(Math.random() * (maxVal - minVal + 1))
      setLoader(randomValue)
      minVal = randomValue + 1
    }

    setTimeout(setRandomValue, 1000)
    setTimeout(setRandomValue, 3000)
    setTimeout(setRandomValue, 6000)
    setTimeout(setRandomValue, 10000)

    // else toast.error(addCompanydata.error)

    // console.log("formdata-->", formData, "$$$$", company_pic)
  }
  const successCallBack = response => {
    console.log("isResponse", response)
    setLoader(100)

    setTimeout(() => {
      history.push(`/flashcard/${response.id}`)
    }, 2000)
  }
  const fileInputRef = useRef(null)

  const setTitlehandler = e => {
    setSetTitle(e.target.value)
  }
  const setDescriptionhandler = e => {
    setDescription(e.target.value)
  }
  console.log("title", setTitle)
  console.log("description", description)
  const handleImageSelect = e => {
    const file = e.target.files[0]
    // if (file) {
    //   onImageSelect(file)
    // }
    console.log("file", file)
  }

  const goBack = () => {
    history.goBack()
  }
  const cancelAddTopicHandler = () => {
    console.log("cancelTopic")
    dispatch(cancelTopicDetailRequest())
    window.location.reload()
    // history.push("/el_topicdetail")
  }
  useEffect(() => {
    // Find the item with the id that matches the topicId
    const matchingTopic = ViewTopicNameData.find(
      item => Number(item.id) === Number(topicOption)
    )

    console.log("matchingTopic", matchingTopic)
    console.log("topicOption", topicOption)
    console.log("ViewTopicNameData", ViewTopicNameData)
    // If found, set the Topic_name to the state variable
    if (matchingTopic) {
      setTopicName(matchingTopic.Topic_name)
    }
  }, [topicOption, ViewTopicNameData])
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  const nextPage = () => {
    history.push("/topic_lessioncard")
  }
  return (
    <div className={`main-content${classAlign}`}>
      <MetaTags>
        <title>Topic Detail | StudyBudi</title>
      </MetaTags>
      {/* {(document.title = "Company | Nater")} */}

      <div className="page-content">
        <ToastContainer />
        <div
          className="inner-page-content"
          style={{
            paddingRight: "30px",
            paddingLeft: "30px",
            paddingTop: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-start",
              marginBottom: "5px",
              alignItems: "center",
              height: "50px",
              // marginTop: "5px",
            }}
          >
            <HiOutlineArrowLeft
              style={{
                fontSize: "50px",
                fontWeight: "200",
                color: "#CACAD0",
                cursor: "pointer",
              }}
              onClick={goBack}
            />
            {/* <span
              style={{
                fontSize: "50px",
                fontWeight: "200",
                color: "#CACAD0",
                fontFamily: "cursive",
              }}
            >
              ←
            </span> */}
          </div>
          <Container fluid style={{ padding: "0px" }}>
            {/* Render Breadcrumbs */}
            <Row style={{ backgroundColor: "white" }}>
              <Col lg={12} md={12} style={{ padding: "0px" }}>
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="me-4">
                        <div className="mt-4 mt-md-0">
                          <div
                            className={`avatar-md rounded-circle  mini-stat-icon `}
                          >
                            <span
                              className="avatar-title rounded-circle "
                              style={{
                                backgroundColor: "#E9F6F7",
                                // paddingBottom: "100px",
                              }}
                              // style={{ backgroundColor: "white" }}
                            >
                              {ViewSIngleModuleData?.image ? (
                                <img
                                  src={ViewSIngleModuleData?.image}
                                  alt="AAAA"
                                  style={{
                                    objectFit: "cover",
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "100%",
                                  }}
                                />
                              ) : (
                                ""
                              )}
                              {/* <img
                                src={}
                                className={`avatar-md rounded-circle  mini-stat-icon `}
                                // alt={report.title}
                                // style={{ width: "20px", height: "20px" }}
                              /> */}
                            </span>
                          </div>
                          {/* <img
                            className="rounded-circle avatar-lg"
                            alt="Skote"
                            // src={avatar4}
                          /> */}
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5>
                          {/* Advanced Algebra{" "} */}
                          <select
                            value={moduleOption}
                            onChange={handleModuleNameChange}
                            className="filterstyle"
                            style={{
                              border: "none",
                              cursor: "pointer",
                              color: "#8C9090",
                            }}
                          >
                            {/* <option value="">
                              {props.t(ViewSIngleModuleData.Title)}
                            </option> */}
                            {ViewModuleData &&
                              ViewModuleData.map(row => (
                                <option key={row.id} value={row.id}>
                                  {row.Title}
                                </option>
                              ))}
                          </select>
                          {/* <BiChevronDown
                            style={{ fontSize: "30px", color: "#BFBFBF" }}
                          /> */}
                        </h5>
                        <div
                          className="newTags-list-1"
                          style={{
                            // marginLeft: "80px",
                            // zIndex: "999",
                            // marginTop: "-20px",
                            marginBottom: "10px",
                          }}
                        >
                          {ViewSIngleModuleData?.Tags &&
                            ViewSIngleModuleData?.Tags.map(tag => (
                              // <span key={tag} className="tag">
                              <span
                                style={{ fontSize: "15px" }}
                                className="newTags"
                                key={tag}
                              >
                                {tag}
                              </span>
                              // </span>
                            ))}
                        </div>
                        {/* <div className="blueBadgeDiv">
                          <span className="blueBadge">Semester 1</span>
                          <span className="blueBadge">August</span>
                          <span className="blueBadge">Coursework</span>
                        </div> */}
                        <p
                          className="mb-3"
                          style={{ color: "#8C9090", marginTop: "10px" }}
                        >
                          {/* Topic 1
                          <BiChevronDown
                            style={{ fontSize: "30px", color: "#BFBFBF" }}
                          /> */}
                          <select
                            value={topicOption}
                            onChange={handleTopicNameChange}
                            className="filterstyle"
                            style={{
                              border: "none",
                              cursor: "pointer",
                              color: "#8C9090",
                            }}
                          >
                            <option value="">{props.t("Select Topic")}</option>
                            {ViewTopicNameData &&
                              ViewTopicNameData.map(row => (
                                <option key={row.id} value={row.id}>
                                  {row.Topic_name}
                                </option>
                              ))}
                          </select>
                        </p>

                        {/* <p className="mb-2" style={{ letterSpacing: "2px" }}>
                          Semester 1- Friday,27 Ocober 2023
                        </p> */}
                      </div>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {/* <i className="mdi mdi-dots-vertical font-size-18" /> */}
                        <span
                          className="icon-color"
                          style={{
                            fontSize: "14px",
                            color: "#CCCCCC",
                            marginLeft: "5px",
                          }}
                        >
                          4
                        </span>
                      </div>

                      {/* <i className="mdi mdi-dots-vertical font-size-18" /> */}
                    </div>
                  </CardBody>

                  <CardBody className="border-top">
                    <Row>
                      <Col sm="12">
                        <div>
                          <h5 className="text-center">
                            {/* Topic 1 */}
                            {topicName}
                          </h5>
                          <p
                            className="text-muted mb-2 text-center"
                            style={{ letterSpacing: "1px", color: "#515555" }}
                          >
                            Create a Revision card set
                          </p>
                        </div>
                      </Col>
                      {!show ? (
                        <div>
                          <Col lg="12" className="mb-2">
                            <div className="mb-3">
                              <Input
                                name="title"
                                label="title"
                                type="text"
                                value={setTitle}
                                maxLength={50}
                                placeholder="Set Title"
                                onChange={setTitlehandler}
                                style={{ height: "50px", position: "relative" }}
                              />
                              <div
                                style={{
                                  height: "34px",
                                  border: "1px Solid #CCCCCC",
                                  width: "82px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  // backgroundColor: "#EFEFEF",
                                  backgroundColor: "#171c46",
                                  color: "#CCCCCC",
                                  position: "absolute",
                                  right: 20,
                                  top: 8,
                                }}
                              >
                                OK
                              </div>
                            </div>
                            <div className="mb-3">
                              <textarea
                                className="form-control"
                                id="projectdesc"
                                rows="3"
                                value={description}
                                onChange={setDescriptionhandler}
                                placeholder="Enter Project Description..."
                              />
                            </div>
                          </Col>
                          <Col sm="12">
                            <div>
                              <h5 className="text-center">-OR-</h5>
                              <p className="text-muted mb-2 text-center">
                                or upload file (Optional)
                              </p>
                            </div>
                          </Col>
                          <Col lg="12">
                            <Form>
                              <Dropzone
                                maxFiles={1} // Limit to one file
                                onDrop={acceptedFiles => {
                                  handleAcceptedFiles(acceptedFiles)
                                }}
                                // onDrop={acceptedFiles => {
                                //   handleAcceptedFiles(acceptedFiles)
                                // }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone">
                                    <div
                                      className="dz-message needsclick"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="dz-message needsclick">
                                        <div className="mb-3">
                                          {/* <i className="display-4 text-muted bx bxs-cloud-upload" /> */}
                                          <img
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                            }}
                                            src={Dbs}
                                            alt=""
                                          />
                                        </div>
                                        <h4>Select files to upload.</h4>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {selectedFiles.map((f, i) => {
                                  return (
                                    <Card
                                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                      key={i + "-file"}
                                    >
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <img
                                              data-dz-thumbnail=""
                                              height="80"
                                              className="avatar-sm rounded bg-light"
                                              alt={f.name}
                                              src={f.preview}
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {f.name}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>{f.formattedSize}</strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  )
                                })}
                              </div>
                            </Form>
                          </Col>
                          <Col sm="4" className="mx-auto">
                            <button
                              style={{
                                fontWeight: "600",
                                fontSize: "0.8rem",
                                marginTop: "10px",
                              }}
                              className="AIbtnLgIn"
                              type="submit"
                              onClick={() => {
                                handleSubmit()
                              }}
                            >
                              GENERATE CARDS
                            </button>
                          </Col>
                        </div>
                      ) : (
                        <div>
                          <Col lg="12">
                            <div
                              style={{
                                border: "3px dashed #CCCCCC",
                                width: "100%",
                                height: "500px",
                                borderRadius: "12px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ width: "200px", height: "200px" }}>
                                <h5 className="text-center mb-3">
                                  StudyBudi is working
                                </h5>
                                <CircularProgressbarWithChildren
                                  value={loader}
                                  strokeWidth={5}
                                >
                                  {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                  <img
                                    style={{ width: 160, marginTop: -5 }}
                                    src={DDz}
                                    alt="dog"
                                  />
                                </CircularProgressbarWithChildren>
                                <h3
                                  className="text-center mb-3"
                                  style={{ marginTop: "5px" }}
                                >
                                  {loader} %
                                </h3>
                              </div>
                            </div>
                          </Col>
                          <h5
                            className="text-center mb-3 text-muted"
                            style={{ marginTop: "5px", cursor: "pointer" }}
                            onClick={() => {
                              cancelAddTopicHandler()
                            }}
                          >
                            Cancel
                          </h5>
                        </div>
                      )}
                    </Row>
                  </CardBody>
                  {/* <CardBody className="border-top">
                  <p className="text-muted mb-4">In this month</p>
                  <div className="text-center">
                    <Row>
                      <Col sm="4">
                        <div>
                          <div className="font-size-24 text-primary mb-2">
                            <i className="bx bx-send" />
                          </div>

                          <p className="text-muted mb-2">Send</p>
                          <h5>{wallet.send}</h5>

                          <div className="mt-3">
                            <Link
                              to="#"
                              className="btn btn-primary btn-sm w-md"
                            >
                              Send
                            </Link>
                          </div>
                        </div>
                      </Col>
                      <Col sm="4">
                        <div className="mt-4 mt-sm-0">
                          <div className="font-size-24 text-primary mb-2">
                            <i className="bx bx-import" />
                          </div>

                          <p className="text-muted mb-2">receive</p>
                          <h5>{wallet.receive}</h5>

                          <div className="mt-3">
                            <Link
                              to="#"
                              className="btn btn-primary btn-sm w-md"
                            >
                              Receive
                            </Link>
                          </div>
                        </div>
                      </Col>
                      <Col sm="4">
                        <div className="mt-4 mt-sm-0">
                          <div className="font-size-24 text-primary mb-2">
                            <i className="bx bx-wallet" />
                          </div>

                          <p className="text-muted mb-2">Withdraw</p>
                          <h5>{wallet.withdraw}</h5>

                          <div className="mt-3">
                            <Link
                              to="#"
                              className="btn btn-primary btn-sm w-md"
                            >
                              Withdraw
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody> */}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  )
}
TopicDetail.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(TopicDetail)
