import { put, call, takeEvery, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { toast } from "react-toastify"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"
import { ADD_REFER_LINK_REQUEST } from "./actionType"
import { addReferLinkFailure, addReferLinkSuccess } from "./action"

function* addReferLink(action) {
  const { data, addReferLinkCallBack } = action.payload
  try {
    const response = yield call(
      axios.put,
      `${BASE_URL}/api/update_refer_friend/`,

      data,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(addReferLinkSuccess(response.data))
    addReferLinkCallBack(response)
    console.log("addC-->", response.data, action.payload)
  } catch (error) {
    console.log("err====>", error.response)
    const loginError = error.response
    yield put(addReferLinkFailure(error.message))
    // toast.error()
  }
}

export default function* addReferLinkSaga() {
  yield takeLatest(ADD_REFER_LINK_REQUEST, addReferLink)
}
