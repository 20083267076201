import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import "../valetCSS/valetapp.css"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// import { Formik, Form, Field, ErrorMessage } from "formik"
import { GoPencil } from "react-icons/go" // user
import { HiOutlineArrowLeft } from "react-icons/hi" // user
import { BsCheckLg } from "react-icons/bs"
import { MdClose } from "react-icons/md"
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai" // user
import * as Yup from "yup"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import { useRef } from "react"
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import { Card, CardBody, Col, Row, Badge, Form } from "reactstrap"
import { Container, Input } from "reactstrap"
import { Link } from "react-router-dom"
// import avatar4 from "../assets/images/users/avatar-1.jpg"
import Dropzone from "react-dropzone"
import Dbs from "../../Assets/Images/dbs.png"
import DDz from "../../Assets/Images/ddz.png"
import { fetchViewFlashcardRequest } from "store/actions"
import { fetchSingleFlashcardDetailRequest } from "store/actions"
import { addFlashcardButtonRequest } from "store/actions"
import { fetchViewFlashcardButtonRequest } from "store/actions"
import { editFlashcardRequest } from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useSwipeable } from "react-swipeable"
import GoogleAds from "store/Valet/User/Account/GoogleAds/GoogleAds"

const FlashCard = props => {
  //
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  const [isEdit, setIsEdit] = useState(false)
  const [isAnswer, setIsAnswer] = useState(false)
  const [title, setTitle] = useState("Card Title")
  const [rotate, setRotate] = useState(false)
  const [questiionID, setQuestiionID] = useState("")
  const [questiionIDView, setQuestiionIDVIew] = useState("")
  const [buttonNumber, setButtonNumber] = useState(false)
  const [order, setOrder] = useState("")
  const [qna, setQNA] = useState("")
  const [previousQuestion, setPreviousQuestion] = useState("")
  const [previousAnswer, setPreviousAnswer] = useState("")
  const [swipeDelta, setSwipeDelta] = useState(0)
  const [triggerEffect, setTriggerEffect] = useState(false)
  const [queLength, setQueLength] = useState(0)
  const [btnLength, setBtnLength] = useState(0)

  // next Q
  const [firstQId, setFirstQId] = useState(0)
  const [lastQId, setLastQId] = useState(0)
  const [currentId, setCurrentId] = useState(0)
  const [index, setIndex] = useState(0)

  const ViewTopicDetail = useSelector(
    state => state.fetchTopicDetailReducer.ViewTopicDetail
  )
  const ViewFlashcardList = useSelector(
    state => state.fetchViewFlashcardReducer.ViewFlashcard
  )
  const ViewSingleFlashcard = useSelector(
    state => state.fetchSingleFlashcardDetailReducer.SingleFlashcardDetail
  )
  const ViewSingleFlashcardOrder = useSelector(
    state => state.fetchSingleFlashcardDetailReducer.order
  )
  const ViewFlashcardButton = useSelector(
    state => state.fetchViewFlashcardButtonReducer.ViewFlashcardButton
  )

  const blankOnclick = () => {
    // this function call when click on flashcard and isedit is flase
  }
  const viewSingleFlashcardHandler = (id1, order) => {
    dispatch(fetchSingleFlashcardDetailRequest(id1, successCallBack, order))
    console.log("questiionID3", questiionID)
    setQuestiionIDVIew(id1)
    // dispatch(fetchViewFlashcardButtonRequest(questiionID))
    setOrder(order)
    setIsAnswer(false)
    setIsEdit(false)
    //flashcard result
    setTriggerEffect(prev => !prev)
    setQueLength(ViewFlashcardList?.cue_cards?.length)
    setBtnLength(
      ViewFlashcardButton?.green_count + ViewFlashcardButton?.red_count
    )
  }
  const successCallBack = (data, order) => {
    console.log("vvvvv")
    console.log("ViewSingleFlashcardVVV", data)
    setQNA(data?.question)
    setPreviousQuestion(data?.question)
    setPreviousAnswer(data?.answer)
    setQuestiionID(data?.questions[0]?.id)
    console.log("questiionID", questiionID)
    console.log("wwww", data?.questions[0]?.id)

    // dispatch(fetchViewFlashcardButtonRequest(data?.questions[0]?.id))
  }
  console.log("ViewFlashcardList", ViewFlashcardList)
  console.log("ViewSingleFlashcard", ViewSingleFlashcard)
  console.log("ViewTopicDetail", ViewTopicDetail)
  console.log("ViewFlashcardButton", ViewFlashcardButton)
  console.log("ViewSingleFlashcardOrder", ViewSingleFlashcardOrder)
  console.log("questiionIDView", questiionIDView)
  console.log("firstQId", firstQId)
  console.log("lastQId", lastQId)
  console.log("currentId", currentId)
  console.log("index", index)
  console.log("questiionID", questiionID)

  useEffect(() => {
    dispatch(fetchViewFlashcardRequest(id, successCallBack2))
  }, [])
  const successCallBack2 = data => {
    console.log("questiionID2", questiionID)
    console.log("successCallBack2DATA", data)

    console.log("moduleID", data?.module_detail?.id)
    dispatch(
      fetchViewFlashcardButtonRequest(
        data?.module_detail?.id,
        data?.topic_id,
        data?.topic_add_id
      )
    )
    dispatch(fetchSingleFlashcardDetailRequest(data[0].id, successCallBack))
    console.log("moduleID", data?.module_detail?.id)

    // dispatch(fetchViewFlashcardButtonRequest(data[0].questions[0].id))
  }
  const RightFLashcardHandler = () => {
    setButtonNumber(true)
    dispatch(
      addFlashcardButtonRequest(
        ViewFlashcardList?.module_detail?.id,
        ViewFlashcardList?.topic_id,
        ViewFlashcardList?.topic_add_id,
        ViewSingleFlashcard?.id,
        true,
        addFlashcardButtonCallBack
      )
    )
    //flashcard result
    setTriggerEffect(prev => !prev)
    setQueLength(ViewFlashcardList?.cue_cards?.length)
    setBtnLength(
      ViewFlashcardButton?.green_count + ViewFlashcardButton?.red_count
    )

    // dispatch(fetchViewFlashcardButtonRequest(questiionID))
  }
  const WrongFLashcardHandler = () => {
    setButtonNumber(true)
    dispatch(
      addFlashcardButtonRequest(
        ViewFlashcardList?.module_detail?.id,
        ViewFlashcardList?.topic_id,
        ViewFlashcardList?.topic_add_id,
        ViewSingleFlashcard?.id,
        false,
        addFlashcardButtonCallBack
      )
    )
    //flashcard result
    setTriggerEffect(prev => !prev)
    setQueLength(ViewFlashcardList?.cue_cards?.length)
    setBtnLength(
      ViewFlashcardButton?.green_count + ViewFlashcardButton?.red_count
    )

    // dispatch(fetchViewFlashcardButtonRequest(questiionID))
  }

  const addFlashcardButtonCallBack = () => {
    dispatch(
      fetchViewFlashcardButtonRequest(
        ViewFlashcardList?.module_detail?.id,
        ViewFlashcardList?.topic_id,
        ViewFlashcardList?.topic_add_id
      )
    )
    dispatch(fetchViewFlashcardRequest(id, successCallBack2))
    if (currentId > lastQId) {
      // toast.success("you reached at last card,if any remaining please open it")
      toast.success("You have reached the last card in this set!")
    } else if (currentId <= lastQId && currentId >= firstQId) {
      viewSingleFlashcardHandler(currentId + 1, index + 1)
      setCurrentId(currentId + 1)
      setIndex(index + 1)
      // toast.success("Play again 2")
    }
  }
  const truncateTitle = title => {
    const words = title?.split(" ")
    if (words?.length > 5) {
      return words.slice(0, 5).join(" ") + " ..."
    }
    return title
  }
  const truncateDescription = description => {
    const words = description?.split(" ")
    if (words?.length > 10) {
      return words.slice(0, 10).join(" ") + " ..."
    }
    return description
  }

  const showAnswer = () => {
    //new
    setIsEdit(false)

    // setIsAnswer(!isAnswer)
    setRotate(true)

    setTimeout(() => {
      setIsAnswer(!isAnswer)
      setQNA(ViewSingleFlashcard?.answer)
      // setPreviousAnswer(ViewSingleFlashcard?.answer)
    }, 1000)
    setTimeout(() => {
      setRotate(false)
    }, 1000)
    // setPreviousAnswer(ViewSingleFlashcard?.answer)
    // console.log("previousAnswer", previousAnswer)
  }

  const showQuestion = () => {
    setIsEdit(false)

    // setIsAnswer(!isAnswer)
    setRotate(true)
    setTimeout(() => {
      setRotate(false)
    }, 1000)
    setTimeout(() => {
      setIsAnswer(!isAnswer)
      setQNA(ViewSingleFlashcard?.question)
      // setPreviousQuestion(ViewSingleFlashcard?.question)
    }, 1000)
    // setPreviousQuestion(ViewSingleFlashcard?.question)
    // console.log("previousQuestion", previousQuestion)
  }
  const handleTitleChange = e => {
    setTitle(e.target.value)
  }

  const editFLashcardHandler = () => {
    const editQformData = {
      module_detail: ViewFlashcardList?.module_detail?.id,
      topic: ViewFlashcardList?.topic_id,
      question: qna,
      answer: previousAnswer,
    }
    const editAformData = {
      module_detail: ViewFlashcardList?.module_detail?.id,
      topic: ViewFlashcardList?.topic_id,
      question: previousQuestion,
      answer: qna,
    }
    isAnswer
      ? dispatch(
          editFlashcardRequest(
            editAformData,
            ViewSingleFlashcard.id,
            editFLashcardCallBAck
          )
        )
      : dispatch(
          editFlashcardRequest(
            editQformData,
            ViewSingleFlashcard.id,
            editFLashcardCallBAck
          )
        )
  }
  const editFLashcardCallBAck = () => {
    // console.log("response", response)
    dispatch(
      fetchSingleFlashcardDetailRequest(ViewSingleFlashcard.id, successCallBack)
    )
  }
  const NotEditFlashcardHandler = () => {
    console.log("NotEditFlashcardHandler")
    dispatch(
      fetchSingleFlashcardDetailRequest(ViewSingleFlashcard.id, successCallBack)
    )
  }
  const handleQNAChange = e => {
    setQNA(e.target.value)
    console.log("onchangeQNA", qna)
  }
  // setQueLength(ViewFlashcardList?.cue_cards?.length)
  // setBtnLength(ViewFlashcardButton?.green_count + ViewFlashcardButton?.red_count)
  // let QLenght = ViewFlashcardList?.cue_cards?.length
  // console.log("QLenght", QLenght)
  // let BLenght =
  //   ViewFlashcardButton?.green_count + ViewFlashcardButton?.red_count
  console.log("queLength", queLength)
  console.log("BtnLenght", btnLength)
  useEffect(() => {
    if (
      queLength === btnLength &&
      btnLength !== 0
      // ViewFlashcardButton?.green_count + ViewFlashcardButton?.red_count)
    ) {
      console.log("Lenght", queLength, btnLength)
      history.push(
        `/flashcard_result/${ViewFlashcardList?.module_detail?.id}/${ViewFlashcardList?.topic_id}/${ViewFlashcardList?.topic_add_id}`
      )
      // toast.success("Flashcard")
    }
  }, [triggerEffect])
  // useEffect(() => {
  //   dispatch(fetchViewFlashcardButtonRequest(questiionID))
  // }, [])
  console.log("previousQuestion", previousQuestion)
  console.log("previousAnswer", previousAnswer)

  // Swipe handlers
  // const handlers = useSwipeable({
  //   onSwiping: event => {
  //     setSwipeDelta(event.deltaX)
  //     // setSwipeDelta(0)
  //   },
  //   onSwiped: () => {
  //     setSwipeDelta(0)
  //   },

  //   onSwipedLeft: () => {
  //     setButtonNumber(true)
  //     dispatch(
  //       addFlashcardButtonRequest(
  //         ViewFlashcardList?.module_detail?.id,
  //         ViewFlashcardList?.topic_id,
  //         ViewFlashcardList?.topic_add_id,
  //         ViewSingleFlashcard?.id,
  //         false,
  //         addFlashcardButtonCallBack
  //       )
  //     )
  //     // alert("You swiped left")
  //     // Logic for swiping left
  //   },
  //   onSwipedRight: () => {
  //     setButtonNumber(true)
  //     dispatch(
  //       addFlashcardButtonRequest(
  //         ViewFlashcardList?.module_detail?.id,
  //         ViewFlashcardList?.topic_id,
  //         ViewFlashcardList?.topic_add_id,
  //         ViewSingleFlashcard?.id,
  //         true,
  //         addFlashcardButtonCallBack
  //       )
  //     )
  //     // alert("You swiped right")
  //     // Logic for swiping right
  //   },
  //   preventDefaultTouchmoveEvent: true,
  //   trackMouse: true,
  //   trackTouch: true,
  // })

  const [startX, setStartX] = useState(0)
  const [currentX, setCurrentX] = useState(0)
  const [isDragging, setIsDragging] = useState(false)

  const blank = () => {}
  const handleTouchStart = e => {
    setStartX(e.type === "mousedown" ? e.pageX : e.touches[0].clientX)
    setIsDragging(true)
  }

  const handleTouchMove = e => {
    if (!isDragging) return

    const x = e.type === "mousemove" ? e.pageX : e.touches[0].clientX
    setCurrentX(x - startX)
  }

  const handleTouchEnd = () => {
    const threshold = 30

    if (currentX > threshold) {
      dispatch(
        addFlashcardButtonRequest(
          ViewFlashcardList?.module_detail?.id,
          ViewFlashcardList?.topic_id,
          ViewFlashcardList?.topic_add_id,
          ViewSingleFlashcard?.id,
          true,
          addFlashcardButtonCallBack
        )
      )
      //flashcard result
      setTriggerEffect(prev => !prev)
      setQueLength(ViewFlashcardList?.cue_cards?.length)
      setBtnLength(
        ViewFlashcardButton?.green_count + ViewFlashcardButton?.red_count
      )
      // if (currentId + 1 <= lastQId && currentId + 1 >= firstQId) {
      //   viewSingleFlashcardHandler(currentId + 1, index + 1)
      //   setCurrentId(currentId + 1)
      //   setIndex(index + 1)
      // }
      console.log("Card swiped in right direction")
    } else if (currentX < -threshold) {
      dispatch(
        addFlashcardButtonRequest(
          ViewFlashcardList?.module_detail?.id,
          ViewFlashcardList?.topic_id,
          ViewFlashcardList?.topic_add_id,
          ViewSingleFlashcard?.id,
          false,
          addFlashcardButtonCallBack
        )
      )
      //flashcard result
      setTriggerEffect(prev => !prev)
      setQueLength(ViewFlashcardList?.cue_cards?.length)
      setBtnLength(
        ViewFlashcardButton?.green_count + ViewFlashcardButton?.red_count
      )
      // if (currentId + 1 <= lastQId && currentId + 1 >= firstQId) {
      //   viewSingleFlashcardHandler(currentId + 1, index + 1)
      //   setCurrentId(currentId + 1)
      //   setIndex(index + 1)
      // }

      console.log("Card swiped in left direction")
    }

    setIsDragging(false)
    setCurrentX(0)
  }

  // swipe end
  const goBack = () => {
    history.goBack()
  }
  // textarea height

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  const nextPage = () => {
    history.push("/flashcard_result")
  }
  return (
    <div className={`main-content${classAlign}`}>
      <MetaTags>
        <title>FlashCard | StudyBudi</title>
      </MetaTags>
      {/* {(document.title = "Company | Nater")} */}

      <div className="page-content">
        <ToastContainer />
        <div
          className="inner-page-content"
          style={{
            paddingRight: "30px",
            paddingLeft: "30px",
            paddingTop: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: "10px",
              alignItems: "center",
              flexWrap: "wrap",
              rowGap: "10px",
              //   height: "50px",
              // marginTop: "5px",
            }}
          >
            <HiOutlineArrowLeft
              style={{
                fontSize: "50px",
                fontWeight: "200",
                color: "#CACAD0",
                cursor: "pointer",
              }}
              onClick={goBack}
            />

            <span
              style={{
                fontSize: "22px",
                letterSpacing: "2px",
                color: "#404445",
                fontWeight: "500",
                textWrap: "wrap",
              }}
            >
              {ViewFlashcardList?.topic_add_title}
              {/* {ViewFlashcardList?.cue_cards[0]?.topic_add_title} */}
            </span>
            <div
              className={questiionIDView ? "" : "dispaly-flashcard-button"}
              style={{ display: "flex", gap: "10px" }}
            >
              <button
                type="button"
                className="btn btn-rounded "
                style={{
                  backgroundColor: "#FF9A9A",
                  borderRadius: "20px",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  paddingLeft: "4px",
                }}
                onClick={WrongFLashcardHandler}
              >
                <MdClose
                  style={{
                    fontSize: "30px",
                    marginRight: "20px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                />
                <span
                  style={{
                    fontSize: "20px",
                    color: "white",
                  }}
                >
                  {ViewFlashcardButton?.red_count}
                </span>
              </button>
              <button
                type="button"
                className="btn btn-rounded "
                style={{
                  backgroundColor: "#87BF65",
                  borderRadius: "20px",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  paddingLeft: "4px",
                }}
                onClick={RightFLashcardHandler}
              >
                <BsCheckLg
                  style={{
                    fontSize: "30px",
                    marginRight: "20px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                />
                <span
                  style={{
                    fontSize: "20px",
                    color: "white",
                  }}
                >
                  {ViewFlashcardButton?.green_count}
                </span>
              </button>
            </div>
          </div>
          <Container fluid>
            {/* Render Breadcrumbs */}
            {questiionIDView ? (
              <Row>
                <Col lg={12} style={{ padding: "0px" }}>
                  <Card
                    onTouchStart={isEdit ? blank : handleTouchStart}
                    onTouchMove={isEdit ? blank : handleTouchMove}
                    onTouchEnd={isEdit ? blank : handleTouchEnd}
                    onMouseDown={isEdit ? blank : handleTouchStart}
                    onMouseMove={isEdit ? blank : handleTouchMove}
                    onMouseUp={isEdit ? blank : handleTouchEnd}
                    onMouseLeave={isEdit ? blank : handleTouchEnd}
                    // {...handlers} // add the swipe handlers to the Card component
                    style={{
                      borderRadius: "15px",
                      transform: `translateX(${currentX}px)`,
                      transition: isDragging ? "none" : "transform 0.5s",
                      userSelect: "none",

                      // transform: `translateX(${swipeDelta}px)`,
                      // transform: `translateX(${swipeDelta * 1}px)`,
                    }}
                    className={
                      isAnswer
                        ? rotate
                          ? "rotate-animation card-Swipe"
                          : ""
                        : rotate
                        ? "card-style rotate-animation card-Swipe"
                        : "card-style card-Swipe"
                    }
                    // onClick={!isAnswer ? showAnswer : showQuestion}
                  >
                    <CardBody>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          alignItems: "center",
                          // border: "1px solid black",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div className="flashcardNumber">{`${order}`}</div>
                        <div>
                          {isEdit ? (
                            <div
                              style={{
                                cursor: "pointer",
                                zIndex: "999",
                                // marginBottom: "10px",
                              }}
                              className="clearfix  mt-lg-0 float-end"
                            >
                              <AiOutlineCheck
                                style={{
                                  marginRight: "5px",
                                  backgroundColor: "92dee4",
                                  borderRadius: "50%",
                                  padding: "3px",
                                  color: "white",
                                }}
                                className={
                                  isAnswer
                                    ? " icon-style-answer"
                                    : " icon-style"
                                }
                                onClick={() => {
                                  editFLashcardHandler()
                                  setIsEdit(false)
                                  setIsAnswer(false)
                                }}
                              />
                              <AiOutlineClose
                                style={{
                                  marginRight: "5px",
                                  backgroundColor: "92dee4",
                                  borderRadius: "50%",
                                  padding: "3px",
                                  color: "red",
                                }}
                                className={
                                  isAnswer ? "icon-style-answer" : "icon-style"
                                }
                                onClick={() => {
                                  NotEditFlashcardHandler()
                                  setIsEdit(false)
                                  setIsAnswer(false)
                                  // isAnswer
                                  //   ? setQNA(previousAnswer)
                                  //   : setQNA(previousQuestion)
                                }}
                              />

                              {/* <i
                          style={{ fontSize: "40px" }}
                          className="bx bx-comment-check align-middle me-1"
                        />
                        <i
                          style={{ fontSize: "40px" }}
                          className="mdi mdi-close-thick align-middle me-1"
                          onClick={() => {
                            setIsEdit(false)
                          }}
                        /> */}
                            </div>
                          ) : (
                            <div
                              style={{ cursor: "pointer" }}
                              className="clearfix  mt-lg-0 float-end"
                            >
                              <GoPencil
                                className={
                                  isAnswer
                                    ? "bx bxs-cog align-middle me-1 icon-style-answer"
                                    : "bx bxs-cog align-middle me-1 icon-style"
                                }
                                onClick={() => {
                                  setIsEdit(true)
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {/* <Badge
                        color="#92DEE4"
                        className="#92DEE4-soft-info badge bg-#92DEE4 rounded-#92DEE4 badge-font "
                        pill
                      >
                        {`FLASHCARD ${order}`}
                      </Badge> */}
                      <div
                        onClick={
                          !isEdit
                            ? !isAnswer
                              ? showAnswer
                              : showQuestion
                            : blankOnclick
                        }
                        style={{
                          cursor: "pointer",
                          // border: "5px solid yellow",
                        }}
                      >
                        {/* <div
                          style={{
                            marginTop: "20px",
                            cursor: "pointer",

                            // border: "5px solid red",
                          }}
                          className={
                            isAnswer
                              ? "mb-3 fields-style-answer"
                              : "mb-3 fields-style"
                          }
                          onClick={
                            !isEdit
                              ? !isAnswer
                                ? showAnswer
                                : showQuestion
                              : blankOnclick
                          }
                        >
                          <Input
                            name="description"
                            type="textarea"
                            value={qna}
                            onChange={handleQNAChange}
                            disabled={!isEdit}
                            // placeholder="Enter organisation name"
                            style={isAnswer ? { color: "#ACB0B0" } : {}}
                            // className="textarea-style"
                            className={
                              isEdit
                                ? "textarea-style"
                                : "textarea-style disabled-input"
                            }
                          />
                        </div> */}
                        <div
                          style={{
                            // marginTop: "20px",
                            cursor: "pointer",
                          }}
                          className={
                            isAnswer
                              ? "mb-3 fields-style-answer"
                              : "mb-3 fields-style"
                          }
                          onClick={
                            !isEdit
                              ? !isAnswer
                                ? showAnswer
                                : showQuestion
                              : blankOnclick
                          }
                        >
                          {!isEdit && isAnswer ? (
                            <div style={{ color: "#ACB0B0", fontSize: "24px" }}>
                              {qna}
                            </div>
                          ) : (
                            <div className="textarea-wrapper">
                              <Input
                                name="description"
                                type="textarea"
                                value={qna}
                                onChange={handleQNAChange}
                                disabled={!isEdit}
                                style={isAnswer ? { color: "#ACB0B0" } : {}}
                                className={
                                  isEdit
                                    ? "textarea-style"
                                    : "textarea-style disabled-input"
                                }
                              />
                              {!isEdit && <div className="overlay"></div>}
                            </div>
                          )}
                        </div>

                        <div
                          style={{
                            cursor: "pointer",
                            // border: "2px solid black",
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            // marginTop: "60px",
                          }}
                          // className="clearfix mt-4 mt-lg-0 float-end"
                          onClick={
                            !isEdit
                              ? !isAnswer
                                ? showAnswer
                                : showQuestion
                              : blankOnclick
                          }
                        >
                          {!isAnswer ? (
                            <div
                              style={{
                                backgroundColor: "#171c46",
                                height: "25px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "5px",
                                borderRadius: "5px",
                              }}
                            >
                              <div>
                                <h2
                                  className="right-float-text"
                                  onClick={showAnswer}
                                  style={{ margin: "0px" }}
                                >
                                  {"FLASH CARD ANSWERS >"}{" "}
                                </h2>
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                backgroundColor: "#171c46",
                                height: "30px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "5px",
                                borderRadius: "5px",
                              }}
                            >
                              <div>
                                <h2
                                  className="right-float-text-question"
                                  onClick={showQuestion}
                                  style={{ margin: "0px" }}
                                >
                                  {"FLASH CARD QUESTIONS >"}{" "}
                                </h2>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              ""
            )}

            {/* /////////////////////////////////// small cards///////////////////////////////// */}
            <Row style={{ marginTop: "20px" }}>
              <Col lg={12} style={{ padding: "0px" }}>
                <Row>
                  {ViewFlashcardList?.cue_cards &&
                    ViewFlashcardList?.cue_cards.map((report, key) => (
                      <Col
                        lg={4}
                        md={6}
                        xl={3}
                        key={"_col_" + key}
                        onClick={() => {
                          window.scrollTo(0, 0)
                          setFirstQId(ViewFlashcardList?.cue_cards[0].id)
                          setIndex(report.order)
                          setCurrentId(report.id)
                          setLastQId(
                            ViewFlashcardList?.cue_cards[0].id +
                              ViewFlashcardList?.cue_cards?.length -
                              2
                          )
                          viewSingleFlashcardHandler(report.id, report.order)
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <Card
                          className=" sm-card"
                          style={{
                            backgroundColor:
                              report.interaction == null ? "#27b5ed" : "white",
                            borderRadius: "15px",
                          }}
                        >
                          <CardBody className="sm-cards-style">
                            <Badge
                              color="#92DEE4"
                              className="#92DEE4-soft-info badge bg-#92DEE4 rounded-#92DEE4  flashcardDetailNumber"
                              pill

                              // style={{
                              //   color: "#FFFFFF",
                              //   backgroundColor: "#92DEE4",
                              //   marginBottom: "20px",
                              //   padding: "20px 20px 20px 20px",
                              //   letterSpacing: "3px",
                              // }}
                            >
                              {`FLASHCARD ${report.order} `}
                            </Badge>
                            <div
                              style={{
                                color:
                                  report.interaction == null
                                    ? "white"
                                    : "black",
                                alignSelf: "flex-start",
                                marginLeft: "10px",
                              }}
                            >
                              <h3
                                className="mb-3  font-size-17 "
                                style={{
                                  color:
                                    report.interaction == null
                                      ? "white"
                                      : "black",
                                }}
                              >
                                {/* {ViewTopicDetail.title} */}
                                {truncateTitle(report?.question)}
                              </h3>
                              {/* <p className="font-size-14 mb-2 ">
                                {truncateDescription(report?.answer)}
                              </p> */}
                            </div>
                          </CardBody>
                          <div
                            style={{
                              cursor: "pointer",
                              alignSelf: "flex-end",
                              marginRight: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            {/* <Link className="right-float-text clearfix mt-4 mt-lg-0 float-end "> */}
                            <h2 className="right-float-text">{"OPEN >"} </h2>
                            {/* </Link> */}
                          </div>
                        </Card>
                      </Col>
                    ))}
                </Row>
              </Col>
            </Row>
          </Container>
          {/* //////////////////////////////////////////////// */}
          {/* google adsbygoogle */}
          <GoogleAds
            style={{ width: "100%", height: "280px", zIndex: "999" }}
          />
        </div>
      </div>
    </div>
  )
}
FlashCard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(FlashCard)
