import {
  ADD_REFER_LINK_REQUEST,
  ADD_REFER_LINK_FAILURE,
  ADD_REFER_LINK_SUCCESS,
} from "./actionType"

export const addReferLinkRequest = (formData, addReferLinkCallBack) => ({
  type: ADD_REFER_LINK_REQUEST,
  payload: {
    data: formData,
    addReferLinkCallBack: addReferLinkCallBack,
  },
})

export const addReferLinkSuccess = addReferLinkDataSaga => ({
  type: ADD_REFER_LINK_SUCCESS,
  payload: addReferLinkDataSaga,
})

export const addReferLinkFailure = error => ({
  type: ADD_REFER_LINK_FAILURE,
  payload: error,
})
