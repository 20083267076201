import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { FETCH_VIEW_FLASHCARD_BUTTON_REQUEST } from "./actionType"

import {
  fetchViewFlashcardButtonSuccess,
  fetchViewFlashcardButtonFailure,
} from "./action"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchViewFlashcardButton(action) {
  const { ModuleID, TopicID, PPTID } = action.payload
  try {
    const response = yield call(
      axios.get,
      `${BASE_URL}/api/recall-status-counts/${ModuleID}/${TopicID}/${PPTID}/`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchViewFlashcardButtonSuccess(response.data))
  } catch (error) {
    yield put(fetchViewFlashcardButtonFailure(error))
  }
}

function* fetchViewFlashcardButtonSaga() {
  yield takeEvery(FETCH_VIEW_FLASHCARD_BUTTON_REQUEST, fetchViewFlashcardButton)
}

export default fetchViewFlashcardButtonSaga
