import {
  FETCH_GOOGLE_DETAILS_FAILURE,
  FETCH_GOOGLE_DETAILS_REQUEST,
  FETCH_GOOGLE_DETAILS_SUCCESS,
} from "./actionType"

export const fetchGoogleDetailRequest = (googleToken, successCallBack2) => ({
  type: FETCH_GOOGLE_DETAILS_REQUEST,
  payload: { googleToken, successCallBack2 },
})

export const fetchGoogleDetailSuccess = googleDetails => ({
  type: FETCH_GOOGLE_DETAILS_SUCCESS,
  payload: googleDetails,
})

export const fetchGoogleDetailFailure = error => ({
  type: FETCH_GOOGLE_DETAILS_FAILURE,
  payload: error,
})
