import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { FETCH_VIEW_FAVOURITE_REQUEST } from "./actionType"

import { fetchViewFavouriteSuccess, fetchViewFavouriteFailure } from "./action"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchViewFavourite(action) {
  try {
    const response = yield call(
      axios.get,
      `${BASE_URL}/api/retrieve-all-favorites/`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchViewFavouriteSuccess(response.data))
  } catch (error) {
    yield put(fetchViewFavouriteFailure(error))
  }
}

function* fetchViewFavouriteSaga() {
  yield takeEvery(FETCH_VIEW_FAVOURITE_REQUEST, fetchViewFavourite)
}

export default fetchViewFavouriteSaga
