import {
  EDIT_COURSE_REQUEST,
  EDIT_COURSE_FAILURE,
  EDIT_COURSE_SUCCESS,
} from "./actionType"

const initialState = {
  editCourseDataSaga: [],
  loading: false,
  error: null,
}

const editCourseReducer = (state = initialState, action) => {
  console.log("Accccction=>", action)
  switch (action.type) {
    case EDIT_COURSE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case EDIT_COURSE_SUCCESS:
      return {
        ...state,
        editCourseDataSaga: action.payload.data,
        loading: false,
        error: null,
      }
    case EDIT_COURSE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default editCourseReducer
