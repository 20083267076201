import {
  FETCH_VIEW_FLASHCARD_BUTTON_FAILURE,
  FETCH_VIEW_FLASHCARD_BUTTON_REQUEST,
  FETCH_VIEW_FLASHCARD_BUTTON_SUCCESS,
} from "./actionType"

export const fetchViewFlashcardButtonRequest = (ModuleID, TopicID, PPTID) => ({
  type: FETCH_VIEW_FLASHCARD_BUTTON_REQUEST,
  payload: { ModuleID, TopicID, PPTID },
})

export const fetchViewFlashcardButtonSuccess = ViewFlashcardButton => ({
  type: FETCH_VIEW_FLASHCARD_BUTTON_SUCCESS,
  payload: ViewFlashcardButton,
})

export const fetchViewFlashcardButtonFailure = error => ({
  type: FETCH_VIEW_FLASHCARD_BUTTON_FAILURE,
  payload: error,
})
