import {
  ADD_MODULE_SUCCESS,
  ADD_MODULE_FAILURE,
  ADD_MODULE_REQUEST,
} from "./actionType"

export const addModuleRequest = (formData, successCallBack) => ({
  type: ADD_MODULE_REQUEST,
  payload: {
    data: formData,
    successCallBack: successCallBack,
  },
})

export const addModuleSuccess = addModuleDataSaga => ({
  type: ADD_MODULE_SUCCESS,
  payload: addModuleDataSaga,
})

export const addModuleFailure = error => ({
  type: ADD_MODULE_FAILURE,
  payload: error,
})
