// reducer.js
import {
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
} from "./actionType"

const initialState = {
  token: null,
  msg: "",
  status: null,
  success: null,
  error: null,
}

const SignupReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER_REQUEST:
      return {
        ...state,
        error: null,
      }
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        msg: action.payload.msg,
        status: action.payload.status,
        success: action.payload.success,
        error: null,
      }
    case REGISTER_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default SignupReducer
