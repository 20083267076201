import {
  EDIT_FLASHCARD_REQUEST,
  EDIT_FLASHCARD_FAILURE,
  EDIT_FLASHCARD_SUCCESS,
} from "./actionType"

export const editFlashcardRequest = (data, id, editFLashcardCallBAck) => ({
  type: EDIT_FLASHCARD_REQUEST,
  payload: {
    data,
    id,
    editFLashcardCallBAck,
  },
})

export const editFlashcardSuccess = editFlashcardDataSaga => ({
  type: EDIT_FLASHCARD_SUCCESS,
  payload: editFlashcardDataSaga,
})

export const editFlashcardFailure = error => ({
  type: EDIT_FLASHCARD_FAILURE,
  payload: error,
})
