import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { FETCH_SINGLE_MODULE_DETAIL_REQUEST } from "./actionType"

import {
  fetchSingleModuleDetailFailure,
  fetchSingleModuleDetailSuccess,
} from "./action"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchSingleModuleDetail(action) {
  try {
    const response = yield call(
      axios.get,
      `${BASE_URL}/api/retrieve_module_by_id/${action.payload}/`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchSingleModuleDetailSuccess(response.data))
  } catch (error) {
    yield put(fetchSingleModuleDetailFailure(error))
  }
}

function* fetchSingleModuleDetailSaga() {
  yield takeEvery(FETCH_SINGLE_MODULE_DETAIL_REQUEST, fetchSingleModuleDetail)
}

export default fetchSingleModuleDetailSaga
