import {
  ADD_FAV_MODULE_REQUEST,
  ADD_FAV_MODULE_FAILURE,
  ADD_FAV_MODULE_SUCCESS,
} from "./actionType"

const initialState = {
  FavModuleDetail: [],
  loading: false,
  error: null,
}

const FavModuleReducer = (state = initialState, action) => {
  // console.log("Action==>", action)
  switch (action.type) {
    case ADD_FAV_MODULE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case ADD_FAV_MODULE_SUCCESS:
      return {
        ...state,
        FavModuleDetail: action.payload,
        loading: false,
        error: null,
      }
    case ADD_FAV_MODULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default FavModuleReducer
