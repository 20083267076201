import {
  FETCH_SINGLE_FLASHCARD_DETAIL_FAILURE,
  FETCH_SINGLE_FLASHCARD_DETAIL_REQUEST,
  FETCH_SINGLE_FLASHCARD_DETAIL_SUCCESS,
} from "./actionType"

export const fetchSingleFlashcardDetailRequest = (
  id,
  successCallBack,
  order
) => (
  console.log("viewid==>", id),
  {
    type: FETCH_SINGLE_FLASHCARD_DETAIL_REQUEST,
    payload: { id, successCallBack, order },
  }
)

export const fetchSingleFlashcardDetailSuccess = SingleFlashcardDetail => ({
  type: FETCH_SINGLE_FLASHCARD_DETAIL_SUCCESS,
  payload: SingleFlashcardDetail,
})

export const fetchSingleFlashcardDetailFailure = error => ({
  type: FETCH_SINGLE_FLASHCARD_DETAIL_FAILURE,
  payload: error,
})
