import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { FETCH_VIEW_FLASHCARD_REQUEST } from "./actionType"

import { fetchViewFlashcardFailure, fetchViewFlashcardSuccess } from "./action"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchViewFlashcard(action) {
  // const id = localStorage.getItem("_id")
  // console.log("valetId==>", id)
  const { id, successCallBack2 } = action.payload
  try {
    const response = yield call(
      axios.get,
      `${BASE_URL}/api/cuecard/${id}/`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchViewFlashcardSuccess(response.data))
    successCallBack2(response.data)
  } catch (error) {
    yield put(fetchViewFlashcardFailure(error))
  }
}

function* fetchViewFlashcardSaga() {
  yield takeEvery(FETCH_VIEW_FLASHCARD_REQUEST, fetchViewFlashcard)
}

export default fetchViewFlashcardSaga
