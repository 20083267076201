//**********************************************//
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { Switch, BrowserRouter as Router, HashRouter } from "react-router-dom"
import { connect, useSelector } from "react-redux"

// Import Routes all
import {
  authProtectedRoutes,
  publicRoutes,
  companyProtectedRoutes,
} from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import { withTranslation } from "react-i18next"

// Import scss
import "./assets/scss/theme.scss"
import { GoogleOAuthProvider } from "@react-oauth/google"
// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

import fakeBackend from "./helpers/AuthType/fakeBackend"
import { useTranslation } from "react-i18next"
import { Redirect } from "react-router-dom"

// Activating fake backend
fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.                      ,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = props => {
  const { i18n } = useTranslation()
  const [tokenData, setTokenData] = useState("")
  function getLayout() {
    console.log("getlayout")
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  useEffect(() => {
    console.log("useeffct app")
    document.body.dir = i18n.language === "ar" ? "rtl" : "ltr"
  }, [props])
  const { role } = useSelector(state => state.authReducer)
  const { role1 } = useSelector(state => state.SignupReducer)
  const { role2 } = useSelector(state => state.fetchGoogleDetailReducer)
  const tokendata = sessionStorage.getItem("token")
  // useEffect(() => {
  //   getTokenFromSessionStorageAsync("token")
  // }, [sessionStorage.getItem("token")])

  // function getTokenFromSessionStorageAsync(key) {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       const tokendata = sessionStorage.getItem(key)
  //       resolve(tokendata)
  //       setTokenData(tokendata)
  //     } catch (error) {
  //       reject(error)
  //     }
  //   })
  // }
  console.log("AppToken==>", tokendata)

  return (
    <GoogleOAuthProvider clientId="1021611278798-d0joa6kier1itot2icu6phh7fhtt37ag.apps.googleusercontent.com">
      <React.Fragment>
        <HashRouter>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                exact
              />
            ))}

            {tokendata ? (
              authProtectedRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  exact
                />
              ))
            ) : (
              <Redirect to="/" />
            )}
          </Switch>
        </HashRouter>
      </React.Fragment>
    </GoogleOAuthProvider>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(withTranslation()(App))
