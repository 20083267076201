import {
  ADD_USER_DETAIL_REQUEST,
  ADD_USER_DETAIL_FAILURE,
  ADD_USER_DETAIL_SUCCESS,
} from "./actionType"

const initialState = {
  addUserDetailDataSaga: [],
  loading: false,
  error: null,
}

const addUserDetailReducer = (state = initialState, action) => {
  console.log("Accccction=>", action)
  switch (action.type) {
    case ADD_USER_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case ADD_USER_DETAIL_SUCCESS:
      return {
        ...state,
        addUserDetailDataSaga: action.payload.data,
        loading: false,
        error: null,
      }
    case ADD_USER_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default addUserDetailReducer
