import {
  ADD_USER_DETAIL_REQUEST,
  ADD_USER_DETAIL_FAILURE,
  ADD_USER_DETAIL_SUCCESS,
} from "./actionType"

export const addUserDetailRequest = (formData, userEditCallBack) => ({
  type: ADD_USER_DETAIL_REQUEST,
  payload: {
    data: formData,
    userEditCallBack: userEditCallBack,
  },
})

export const addUserDetailSuccess = addUserDetailDataSaga => ({
  type: ADD_USER_DETAIL_SUCCESS,
  payload: addUserDetailDataSaga,
})

export const addUserDetailFailure = error => ({
  type: ADD_USER_DETAIL_FAILURE,
  payload: error,
})
