import {
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
} from "./actionType"

export const ResetPasswordRequest = (
  password,
  confirm_password,
  token,
  tokens
) => ({
  type: RESET_PASSWORD_REQUEST,
  payload: {
    password,
    confirm_password,
    token,
    tokens,
  },
})

export const ResetPasswordSuccess = ResetPassworddatasaga => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: ResetPassworddatasaga,
})

export const ResetPasswordFailure = error => ({
  type: RESET_PASSWORD_FAILURE,
  payload: error,
})
