import { put, call, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { RESET_PASSWORD_REQUEST } from "./actionType"
import { ResetPasswordSuccess, ResetPasswordFailure } from "./action"
import { toast } from "react-toastify"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* ResetPassword(action) {
  const { password, confirm_password, token, tokens } = action.payload
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/api/reset-password/${token}/${tokens}/`,

      { password, confirm_password }
    )
    yield put(ResetPasswordSuccess(response.data))
    console.log("addC-->", response.data, action.payload)

    // toast.success("Password Reset Successfully")
  } catch (error) {
    const loginError = error.response
    yield put(ResetPasswordFailure(error.message))
    // toast.error(`${loginError?.data?.message}`)
  }
}

export default function* ResetPasswordSaga() {
  yield takeLatest(RESET_PASSWORD_REQUEST, ResetPassword)
}
