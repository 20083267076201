// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Dashboard

// Maps
import MapsVector from "../pages/Maps/MapsVector"
import MapsLeaflet from "../pages/Maps/MapsLeaflet"

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons"
import IconDripicons from "../pages/Icons/IconDripicons"
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import IconFontawesome from "../pages/Icons/IconFontawesome"

//components
import Course from "pages/Components/Pages/Course/Course"
import EducationLevel from "pages/Components/Pages/Course/EducationLevel"
import ELTopicDetail from "pages/Components/Pages/Course/ELTopicDetail"
import Home from "pages/Components/Pages/HomePage/Home"
import ForgotPassword from "pages/Authentication/ForgotPassword"
import ResetPassword from "pages/Authentication/ResetPassword"
import TopicDetail from "pages/Components/Pages/Course/TopicDetail"
import FlashCard from "pages/Components/Pages/Course/FlashCard"
import Favourites from "pages/Components/Pages/Favourites/Favourites"
import TopicLessionCard from "pages/Components/Pages/Course/TopicLessionCard"
import LessionCard from "pages/Components/Pages/Course/LessionCard"
import FlashCardResult from "pages/Components/Pages/Course/FlashCardResult"
import Account from "pages/Components/Pages/Account/Account"

// import ForgotPasswordAfter from "pages/Components/Pages/ForgotPassword/ForgotPasswordAfter"
import SignUp from "pages/Authentication/SignUp"
// import ResetPassword from "pages/Components/Pages/ForgotPassword/ResetPassword"

import WelcomePage from "pages/Components/Pages/WelcomePage/WelcomePage"
var authProtectedRoutes = [
  /* after login */

  // { path: "/dashboardnew", component: Dashboard },

  { path: "/course", component: Course },
  { path: "/educationlevel", component: EducationLevel },
  {
    path: ["/el_topicdetail", "/el_topicdetail/:Mid"],
    component: ELTopicDetail,
  },
  { path: "/home", component: Home },
  {
    path: ["/topic_detail/:Tid", "/topic_detail/:Mid/:Tid"],
    component: TopicDetail,
  },
  { path: "/flashcard/:id", component: FlashCard },
  { path: "/favourites", component: Favourites },
  {
    path: ["/topic_lessioncard/:Mid", "/topic_lessioncard/:Mid/:Tid"],
    component: TopicLessionCard,
  },
  { path: "/lessioncard", component: LessionCard },
  {
    path: ["/flashcard_result", "/flashcard_result/:Mid/:Tid/:PPTid"],
    component: FlashCardResult,
  },
  { path: "/account", component: Account },

  // { path: "/forgot-password", component: ForgotPassword },

  // //profile
  { path: "/profile", component: UserProfile },

  // Icons
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },

  // Maps
  { path: "/maps-vector", component: MapsVector },
  { path: "/maps-leaflet", component: MapsLeaflet },
]

var companyProtectedRoutes = [
  /* after login */
  // { path: "/dashboard", component: Dashboard },

  // { path: "/dashboardnew", component: Dashboard },

  // { path: "/customers", component: Customers },

  // //profile
  { path: "/profile", component: UserProfile },

  // Icons
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },

  // Maps
  // { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },
  { path: "/maps-leaflet", component: MapsLeaflet },
]

const publicRoutes = [
  // { path: "/welcomepage", component: WelcomePage },
  { path: "/", component: WelcomePage },
  // { path: "/admin", component: Login },
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  // { path: "/signup", component: SignUp },
  // { path: "/signup/:Number/:UserId", component: SignUp },
  { path: ["/signup", "/signup/:number"], component: SignUp },
  { path: "/forgot_password", component: ForgotPassword },
  { path: "/reset_password/:token/:tokens", component: ResetPassword },
  // { path: "/register", component: Signup },
  // { path: "/course", component: Course },
  // { path: "/reset_password/:token", component: ResetPassword },
  // { path: "/forgot_password", component: ForgotPassword },
  // { path: "/forgot_password_after", component: ForgotPasswordAfter },
]

export { authProtectedRoutes, publicRoutes, companyProtectedRoutes }
