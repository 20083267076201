import {
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
} from "./actionType"

const initialState = {
  token: null,
  error: null,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload,
        error: null,
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        token: null,
        error: action.payload,
      }
    default:
      return state
  }
}

export default authReducer

// const initialState = {
//   token: sessionStorage.getItem("token"),
//   isAuthenticated: false,
//   error: null,
//   role: sessionStorage.getItem("role"),
//   status: false,
// }

// const authReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case LOGIN_REQUEST:
//       return { ...state, error: null }
//     case LOGIN_SUCCESS:
//       sessionStorage.setItem("token", action.payload.data.data.token.access)
//       // sessionStorage.setItem("name", action.payload.data.data.name)
//       // sessionStorage.setItem(
//       //   "companyname",
//       //   action.payload.data.data.companyName
//       // )
//       // sessionStorage.setItem("email", action.payload.data.data.email)
//       // sessionStorage.setItem("role", action.payload.data.type)
//       // sessionStorage.setItem("_id", action.payload.data.data._id)
//       console.log("loginPayload==>", action.payload)
//       return {
//         ...state,
//         isAuthenticated: true,
//         loginToken: action.payload.token.access,
//         role: action.payload.data.type,
//         status: action.payload.data,
//         error: null,
//       }
//     case LOGIN_FAILURE:
//       return { ...state, error: action.payload.error }
//     case LOGOUT:
//       sessionStorage.removeItem("token")
//       return { ...state, isAuthenticated: false, loginToken: null }
//     default:
//       return state
//   }
// }

// export default authReducer
