import { put, call, takeLatest, takeEvery, all } from "redux-saga/effects"
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE } from "./actionType"
import { loginsuccess, loginFailure } from "./action "
// import { Login_API_URL } from "../BaseURL/baseURL";
import axios from "axios"
import { toast } from "react-toastify"
import { BASE_URL } from "../BaseURL/baseURL"

export function* loginSaga(action) {
  try {
    // const response = yield axios.post(
    //   `${BASE_URL}/api/login/`,
    //   action.payload,
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }
    // )
    const { email, password, successCallBack } = action.payload
    const response = yield call(
      axios.post,
      // "http://13.234.30.42:5000/admin/login",
      `${BASE_URL}/api/login/`,
      { email, password }
      // {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // }
    )
    const data = response.data

    if (response.status === 200) {
      const token = data.token.access
      sessionStorage.setItem("token", token)
      sessionStorage.setItem("UserEmail", data.user.email)
      sessionStorage.setItem("UserId", data.user.id)
      yield put({ type: LOGIN_SUCCESS, payload: token })
      console.log("data22", data)
    } else {
      yield put({ type: LOGIN_FAILURE, payload: data.msg })
    }
  } catch (error) {
    // console.log("error5", error)
    // console.log("errors=-->", error.response)
    const loginError = error.response
    toast.error(`${loginError?.data?.errors}`)
    // console.log("error111", `${loginError?.data?.errors?.non_field_errors}`)
    yield put({
      type: LOGIN_FAILURE,
      payload: `${loginError?.data?.errors?.non_field_errors}`,
    })
  }
}

export function* watchLogin() {
  yield takeLatest(LOGIN_REQUEST, loginSaga)
}

// export function* loginSaga(action) {
//   try {
//     const { email, password, successCallBack } = action.payload
//     const response = yield call(
//       axios.post,
//       // "http://13.234.30.42:5000/admin/login",
//       `${BASE_URL}/api/login/`,
//       { email, password }
//     )
//     console.log("AAA", response)
//     yield put(loginsuccess(response.data))
//     console.log("login1", response.data)
//     console.log("login1", response)
//     toast.success("Login successful!")
//     successCallBack(response)
//   } catch (error) {
//     console.log("errors=-->", error.response)

//     const loginError = error.response
//     yield put(loginFailure(error.message))
//     toast.error(`${loginError?.data?.errors?.non_field_errors}`)
//   }
// }

// export function* watchLogin() {
//   yield takeLatest(LOGIN_REQUEST, loginSaga)
// }
