export const CourseOptions = [
  { value: "Accounting and Finance", label: "Accounting and Finance" },
  { value: "Agriculture and Forestry", label: "Agriculture and Forestry" },
  { value: "Anatomy and Physiology", label: "Anatomy and Physiology" },
  {
    value: "Aeronautical Manafacturing Engineering",
    label: "Aeronautical Manafacturing Engineering",
  },
  { value: "Anthropology", label: "Anthropology" },
  { value: "Archaeology", label: "Archaeology" },
  { value: "Architecture", label: "Architecture" },
  { value: "Art Design", label: "Art Design" },
  { value: "Biological Sciences", label: "Biological Sciences" },
  { value: "Building", label: "Building" },
  {
    value: "Business and Management Studies",
    label: "Business and Management Studies",
  },
  { value: "Chemical Engineering", label: "Chemical Engineering" },
  { value: "Chemistry", label: "Chemistry" },
  { value: "Civil Engineering", label: "Civil Engineering" },
  {
    value: "Classics and Ancient History",
    label: "Classics and Ancient History",
  },
  {
    value: "Communication and Media Studies",
    label: "Communication and Media Studies",
  },
  { value: "Complementary Medicine", label: "Complementary Medicine" },
  { value: "Computer Science", label: "Computer Science" },
  { value: "Counselling", label: "Counselling" },
  { value: "Creative Writing", label: "Creative Writing" },
  { value: "Criminology", label: "Criminology" },
  { value: "Dentistry", label: "Dentistry" },
  { value: "Drama Dance and Cinematics", label: "Drama Dance and Cinematics" },
  { value: "Economics", label: "Economics" },
  { value: "Education", label: "Education" },
  {
    value: "Electrical and Electronic Engineering",
    label: "Electrical and Electronic Engineering",
  },
  { value: "English", label: "English" },
  { value: "Fashion", label: "Fashion" },
  { value: "Film Making", label: "Film Making" },
  { value: "Food Science", label: "Food Science" },
  { value: "Forensic Science", label: "Forensic Science" },
  { value: "General Engineering", label: "General Engineering" },
  {
    value: "Geography and Environmental Science",
    label: "Geography and Environmental Science",
  },
  { value: "Geology", label: "Geology" },
  { value: "Health and Social Care", label: "Health and Social Care" },
  { value: "History", label: "History" },
  {
    value: "History of Art Architecture and Design",
    label: "History of Art Architecture and Design",
  },
  {
    value: "Hospitality Leisure Recreation and Tourism",
    label: "Hospitality Leisure Recreation and Tourism",
  },
  { value: "Information Technology", label: "Information Technology" },
  {
    value: "Land and Property Management",
    label: "Land and Property Management",
  },
  { value: "Law", label: "Law" },
  { value: "Linguistics", label: "Linguistics" },
  { value: "Marketing", label: "Marketing" },
  { value: "Materials Technology", label: "Materials Technology" },
  { value: "Mathematics", label: "Mathematics" },
  { value: "Mechanical Engineering", label: "Mechanical Engineering" },
  { value: "Medical Technology", label: "Medical Technology" },
  { value: "Medicine", label: "Medicine" },
  { value: "Music", label: "Music" },
  { value: "Nursing", label: "Nursing" },
  { value: "Occupational Therapy", label: "Occupational Therapy" },
  { value: "Pharmacology and Pharmacy", label: "Pharmacology and Pharmacy" },
  { value: "Philosophy", label: "Philosophy" },
  { value: "Physics and Astronomy", label: "Physics and Astronomy" },
  { value: "Physiotherapy", label: "Physiotherapy" },
  { value: "Politics", label: "Politics" },
  { value: "Psychology", label: "Psychology" },
  { value: "Robotics", label: "Robotics" },
  { value: "Social Policy", label: "Social Policy" },
  { value: "Social Work", label: "Social Work" },
  { value: "Sociology", label: "Sociology" },
  { value: "Sports Science", label: "Sports Science" },
  { value: "Veterinary Medicine", label: "Veterinary Medicine" },
  { value: "Youth Work", label: "Youth Work" },
]

export const DegreeOptions = [
  { value: "Bachelor of Arts (BA)", label: "Bachelor of Arts (BA)" },
  { value: "Bachelor of Science (BSc)", label: "Bachelor of Science (BSc)" },
  {
    value: "Bachelor of Education (BEd)",
    label: "Bachelor of Education (BEd)",
  },
  {
    value: "Bachelor of Engineering (BEng)",
    label: "Bachelor of Engineering (BEng)",
  },
  { value: "Bachelor of Laws (LLB)", label: "Bachelor of Laws (LLB)" },
  {
    value: "Bachelor of Medicine, Bachelor of Surgery (MB ChB)",
    label: "Bachelor of Medicine, Bachelor of Surgery (MB ChB)",
  },
  { value: "Master of Arts (MA)", label: "Master of Arts (MA)" },
  { value: "Master of Science (MSc)", label: "Master of Science (MSc)" },
  { value: "Master of Education (MEd)", label: "Master of Education (MEd)" },
  { value: "Master of Research (MRes)", label: "Master of Research (MRes)" },
  {
    value: "Master of Philosophy (MPhil)",
    label: "Master of Philosophy (MPhil)",
  },
  {
    value: "Master of Business Administration (MBA)",
    label: "Master of Business Administration (MBA)",
  },
  {
    value: "Master's Degree in Law (LLM)",
    label: "Master's Degree in Law (LLM)",
  },
]

export const UniversityOptions = [
  {
    value: "Robert Gordon University (Aberdeen)",
    label: "Robert Gordon University (Aberdeen)",
  },
  {
    value: "University of Aberdeen (Aberdeen)",
    label: "University of Aberdeen (Aberdeen)",
  },
  {
    value: "Aberystwyth University (Aberystwyth)",
    label: "Aberystwyth University (Aberystwyth)",
  },
  { value: "Bangor University (Bangor)", label: "Bangor University (Bangor)" },
  { value: "Bath Spa University (Bath)", label: "Bath Spa University (Bath)" },
  { value: "University of Bath (Bath)", label: "University of Bath (Bath)" },
  {
    value: "Queen's University Belfast (Belfast)",
    label: "Queen's University Belfast (Belfast)",
  },
  {
    value: "Aston University (Birmingham)",
    label: "Aston University (Birmingham)",
  },
  {
    value: "Birmingham City University (Birmingham)",
    label: "Birmingham City University (Birmingham)",
  },
  {
    value: "Newman University, Birmingham (Birmingham)",
    label: "Newman University, Birmingham (Birmingham)",
  },
  {
    value: "University College Birmingham (Birmingham)",
    label: "University College Birmingham (Birmingham)",
  },
  {
    value: "University of Birmingham (Birmingham)",
    label: "University of Birmingham (Birmingham)",
  },
  {
    value: "University of Bolton (Bolton)",
    label: "University of Bolton (Bolton)",
  },
  {
    value: "University of Bradford (Bradford)",
    label: "University of Bradford (Bradford)",
  },
  {
    value: "University of Brighton (Brighton)",
    label: "University of Brighton (Brighton)",
  },
  {
    value: "University of Sussex (Brighton)",
    label: "University of Sussex (Brighton)",
  },
  {
    value: "University of Bristol (Bristol)",
    label: "University of Bristol (Bristol)",
  },
  {
    value: "University of the West of England (Bristol)",
    label: "University of the West of England (Bristol)",
  },
  {
    value: "University of Buckingham (Buckingham)",
    label: "University of Buckingham (Buckingham)",
  },
  {
    value: "Anglia Ruskin University (Cambridge)",
    label: "Anglia Ruskin University (Cambridge)",
  },
  {
    value: "University of Cambridge (Cambridge)",
    label: "University of Cambridge (Cambridge)",
  },
  {
    value: "Canterbury Christ Church University (Canterbury)",
    label: "Canterbury Christ Church University (Canterbury)",
  },
  {
    value: "University of Kent (Canterbury)",
    label: "University of Kent (Canterbury)",
  },
  {
    value: "Cardiff Metropolitan University (Cardiff)",
    label: "Cardiff Metropolitan University (Cardiff)",
  },
  {
    value: "Cardiff University (Cardiff)",
    label: "Cardiff University (Cardiff)",
  },
  {
    value: "University of Wales (Cardiff)",
    label: "University of Wales (Cardiff)",
  },
  {
    value: "University of Cumbria (Carlisle)",
    label: "University of Cumbria (Carlisle)",
  },
  {
    value: "University of Wales Trinity Saint David (Carmarthen)",
    label: "University of Wales Trinity Saint David (Carmarthen)",
  },
  {
    value: "Writtle University College (Chelmsford)",
    label: "Writtle University College (Chelmsford)",
  },
  {
    value: "University of Gloucestershire (Cheltenham)",
    label: "University of Gloucestershire (Cheltenham)",
  },
  {
    value: "University of Chester (Chester)",
    label: "University of Chester (Chester)",
  },
  {
    value: "University of Chichester (Chichester)",
    label: "University of Chichester (Chichester)",
  },
  {
    value: "The Royal Agricultural University (Cirencester)",
    label: "The Royal Agricultural University (Cirencester)",
  },
  {
    value: "University of Essex (Colchester)",
    label: "University of Essex (Colchester)",
  },
  {
    value: "Ulster University (Coleraine)",
    label: "Ulster University (Coleraine)",
  },
  {
    value: "Coventry University (Coventry)",
    label: "Coventry University (Coventry)",
  },
  {
    value: "University of Warwick (Coventry)",
    label: "University of Warwick (Coventry)",
  },
  {
    value: "Cranfield University (Cranfield)",
    label: "Cranfield University (Cranfield)",
  },
  {
    value: "University of Derby (Derby)",
    label: "University of Derby (Derby)",
  },
  {
    value: "Abertay University (Dundee)",
    label: "Abertay University (Dundee)",
  },
  {
    value: "University of Dundee (Dundee)",
    label: "University of Dundee (Dundee)",
  },
  { value: "Durham University (Durham)", label: "Durham University (Durham)" },
  {
    value: "University of West London (Ealing)",
    label: "University of West London (Ealing)",
  },
  {
    value: "Edinburgh Napier University (Edinburgh)",
    label: "Edinburgh Napier University (Edinburgh)",
  },
  {
    value: "Heriot-Watt University (Edinburgh)",
    label: "Heriot-Watt University (Edinburgh)",
  },
  {
    value: "Queen Margaret University (Edinburgh)",
    label: "Queen Margaret University (Edinburgh)",
  },
  {
    value: "Scotland's Rural College (Edinburgh)",
    label: "Scotland's Rural College (Edinburgh)",
  },
  {
    value: "University of Edinburgh (Edinburgh)",
    label: "University of Edinburgh (Edinburgh)",
  },
  {
    value: "Royal Holloway, University of London (Egham)",
    label: "Royal Holloway, University of London (Egham)",
  },
  {
    value: "University of Exeter (Exeter)",
    label: "University of Exeter (Exeter)",
  },
  {
    value: "Falmouth University (Falmouth)",
    label: "Falmouth University (Falmouth)",
  },
  {
    value: "University for the Creative Arts (Farnham)",
    label: "University for the Creative Arts (Farnham)",
  },
  {
    value: "Glasgow Caledonian University (Glasgow)",
    label: "Glasgow Caledonian University (Glasgow)",
  },
  {
    value: "Royal Conservatoire of Scotland (Glasgow)",
    label: "Royal Conservatoire of Scotland (Glasgow)",
  },
  {
    value: "The Glasgow School of Art (Glasgow)",
    label: "The Glasgow School of Art (Glasgow)",
  },
  {
    value: "University of Glasgow (Glasgow)",
    label: "University of Glasgow (Glasgow)",
  },
  {
    value: "University of Strathclyde (Glasgow)",
    label: "University of Strathclyde (Glasgow)",
  },
  {
    value: "Hartpury University and Hartpury College (Gloucester)",
    label: "Hartpury University and Hartpury College (Gloucester)",
  },
  {
    value: "The University of Law (Guildford)",
    label: "The University of Law (Guildford)",
  },
  {
    value: "University of Surrey (Guildford)",
    label: "University of Surrey (Guildford)",
  },
  {
    value: "University of Hertfordshire (Hatfield)",
    label: "University of Hertfordshire (Hatfield)",
  },
  {
    value: "Buckinghamshire New University (High Wycombe)",
    label: "Buckinghamshire New University (High Wycombe)",
  },
  {
    value: "University of Huddersfield (Huddersfield)",
    label: "University of Huddersfield (Huddersfield)",
  },
  { value: "University of Hull (Hull)", label: "University of Hull (Hull)" },
  {
    value: "University of the Highlands and Islands (Inverness)",
    label: "University of the Highlands and Islands (Inverness)",
  },
  {
    value: "University of Suffolk (Ipswich)",
    label: "University of Suffolk (Ipswich)",
  },
  { value: "Keele University (Keele)", label: "Keele University (Keele)" },
  {
    value: "Kingston University (Kingston upon Thames)",
    label: "Kingston University (Kingston upon Thames)",
  },
  {
    value: "Lancaster University (Lancaster)",
    label: "Lancaster University (Lancaster)",
  },
  {
    value: "Leeds Arts University (Leeds)",
    label: "Leeds Arts University (Leeds)",
  },
  {
    value: "Leeds Beckett University (Leeds)",
    label: "Leeds Beckett University (Leeds)",
  },
  {
    value: "Leeds Conservatoire (Leeds)",
    label: "Leeds Conservatoire (Leeds)",
  },
  {
    value: "Leeds Trinity University (Leeds)",
    label: "Leeds Trinity University (Leeds)",
  },
  {
    value: "Northern School of Contemporary Dance (Leeds)",
    label: "Northern School of Contemporary Dance (Leeds)",
  },
  {
    value: "University of Leeds (Leeds)",
    label: "University of Leeds (Leeds)",
  },
  {
    value: "De Montfort University (Leicester)",
    label: "De Montfort University (Leicester)",
  },
  {
    value: "University of Leicester (Leicester)",
    label: "University of Leicester (Leicester)",
  },
  {
    value: "Bishop Grosseteste University (Lincoln)",
    label: "Bishop Grosseteste University (Lincoln)",
  },
  {
    value: "University of Lincoln (Lincoln)",
    label: "University of Lincoln (Lincoln)",
  },
  {
    value: "Liverpool Hope University (Liverpool)",
    label: "Liverpool Hope University (Liverpool)",
  },
  {
    value: "Liverpool John Moores University (Liverpool)",
    label: "Liverpool John Moores University (Liverpool)",
  },
  {
    value: "Liverpool School of Tropical Medicine (Liverpool)",
    label: "Liverpool School of Tropical Medicine (Liverpool)",
  },
  {
    value: "The Liverpool Institute for Performing Arts (Liverpool)",
    label: "The Liverpool Institute for Performing Arts (Liverpool)",
  },
  {
    value: "University of Liverpool (Liverpool)",
    label: "University of Liverpool (Liverpool)",
  },
  {
    value: "Birkbeck, University of London (London)",
    label: "Birkbeck, University of London (London)",
  },
  { value: "BPP University (London)", label: "BPP University (London)" },
  {
    value: "City, University of London (London)",
    label: "City, University of London (London)",
  },
  {
    value: "European School of Economics (London)",
    label: "European School of Economics (London)",
  },
  {
    value: "Goldsmiths, University of London (London)",
    label: "Goldsmiths, University of London (London)",
  },
  {
    value: "Guildhall School of Music and Drama (London)",
    label: "Guildhall School of Music and Drama (London)",
  },
  {
    value: "Imperial College London (London)",
    label: "Imperial College London (London)",
  },
  {
    value: "King's College London (London)",
    label: "King's College London (London)",
  },
  {
    value: "London Business School (London)",
    label: "London Business School (London)",
  },
  {
    value: "London Metropolitan University (London)",
    label: "London Metropolitan University (London)",
  },
  {
    value:
      "London School of Hygiene and Tropical Medicine, University of London (London)",
    label:
      "London School of Hygiene and Tropical Medicine, University of London (London)",
  },
  {
    value: "London South Bank University (London)",
    label: "London South Bank University (London)",
  },
  {
    value: "Middlesex University (London)",
    label: "Middlesex University (London)",
  },
  {
    value: "Queen Mary University of London (London)",
    label: "Queen Mary University of London (London)",
  },
  {
    value: "Ravensbourne University London (London)",
    label: "Ravensbourne University London (London)",
  },
  {
    value: "Regent's University London (London)",
    label: "Regent's University London (London)",
  },
  {
    value: "Richmond, The American International University in London (London)",
    label: "Richmond, The American International University in London (London)",
  },
  {
    value: "Rose Bruford College (London)",
    label: "Rose Bruford College (London)",
  },
  {
    value: "Royal College of Art (London)",
    label: "Royal College of Art (London)",
  },
  {
    value: "Royal College of Music (London)",
    label: "Royal College of Music (London)",
  },
  {
    value: "School of Advanced Study, University of London (London)",
    label: "School of Advanced Study, University of London (London)",
  },
  {
    value: "SOAS, University of London (London)",
    label: "SOAS, University of London (London)",
  },
  {
    value: "St George's, University of London (London)",
    label: "St George's, University of London (London)",
  },
  {
    value: "The Courtauld Institute of Art, University of London (London)",
    label: "The Courtauld Institute of Art, University of London (London)",
  },
  {
    value: "The London Institute of Banking and Finance (London)",
    label: "The London Institute of Banking and Finance (London)",
  },
  {
    value: "The London School of Economics and Political Science (London)",
    label: "The London School of Economics and Political Science (London)",
  },
  {
    value: "The Royal Academy of Music, University of London (London)",
    label: "The Royal Academy of Music, University of London (London)",
  },
  {
    value: "The Royal Central School of Speech and Drama (London)",
    label: "The Royal Central School of Speech and Drama (London)",
  },
  {
    value: "The Royal Veterinary College University of London (London)",
    label: "The Royal Veterinary College University of London (London)",
  },
  {
    value: "Trinity Laban Conservatoire of Music and Dance (London)",
    label: "Trinity Laban Conservatoire of Music and Dance (London)",
  },
  {
    value: "University College London (London)",
    label: "University College London (London)",
  },
  {
    value: "University of East London (London)",
    label: "University of East London (London)",
  },
  {
    value: "University of Greenwich (London)",
    label: "University of Greenwich (London)",
  },
  {
    value: "University of London (London)",
    label: "University of London (London)",
  },
  {
    value: "University of Roehampton (London)",
    label: "University of Roehampton (London)",
  },
  {
    value: "University of the Arts London (London)",
    label: "University of the Arts London (London)",
  },
  {
    value: "University of Westminster (London)",
    label: "University of Westminster (London)",
  },
  {
    value: "Loughborough University (Loughborough)",
    label: "Loughborough University (Loughborough)",
  },
  {
    value: "University of Bedfordshire (Luton)",
    label: "University of Bedfordshire (Luton)",
  },
  {
    value: "Manchester Metropolitan University (Manchester)",
    label: "Manchester Metropolitan University (Manchester)",
  },
  {
    value: "Royal Northern College of Music (Manchester)",
    label: "Royal Northern College of Music (Manchester)",
  },
  {
    value: "University of Manchester (Manchester)",
    label: "University of Manchester (Manchester)",
  },
  {
    value: "Teesside University (Middlesbrough)",
    label: "Teesside University (Middlesbrough)",
  },
  {
    value: "Newcastle University (Newcastle upon Tyne)",
    label: "Newcastle University (Newcastle upon Tyne)",
  },
  {
    value: "Northumbria University (Newcastle upon Tyne)",
    label: "Northumbria University (Newcastle upon Tyne)",
  },
  {
    value: "Harper Adams University (Newport)",
    label: "Harper Adams University (Newport)",
  },
  {
    value: "The University of Northampton (Northampton)",
    label: "The University of Northampton (Northampton)",
  },
  {
    value: "Norwich University of the Arts (Norwich)",
    label: "Norwich University of the Arts (Norwich)",
  },
  {
    value: "University of East Anglia (Norwich)",
    label: "University of East Anglia (Norwich)",
  },
  {
    value: "Nottingham Trent University (Nottingham)",
    label: "Nottingham Trent University (Nottingham)",
  },
  {
    value: "University of Nottingham (Nottingham)",
    label: "University of Nottingham (Nottingham)",
  },
  {
    value: "Edge Hill University (Ormskirk)",
    label: "Edge Hill University (Ormskirk)",
  },
  {
    value: "Oxford Brookes University (Oxford)",
    label: "Oxford Brookes University (Oxford)",
  },
  {
    value: "University of Oxford (Oxford)",
    label: "University of Oxford (Oxford)",
  },
  {
    value: "University of the West of Scotland (Paisley)",
    label: "University of the West of Scotland (Paisley)",
  },
  {
    value: "Arts University Plymouth (Plymouth)",
    label: "Arts University Plymouth (Plymouth)",
  },
  {
    value: "Plymouth Marjon University (Plymouth)",
    label: "Plymouth Marjon University (Plymouth)",
  },
  {
    value: "University of Plymouth (Plymouth)",
    label: "University of Plymouth (Plymouth)",
  },
  {
    value: "University of South Wales (Pontypridd)",
    label: "University of South Wales (Pontypridd)",
  },
  {
    value: "Arts University Bournemouth (Poole)",
    label: "Arts University Bournemouth (Poole)",
  },
  {
    value: "Bournemouth University (Poole)",
    label: "Bournemouth University (Poole)",
  },
  {
    value: "University of Portsmouth (Portsmouth)",
    label: "University of Portsmouth (Portsmouth)",
  },
  {
    value: "University of Central Lancashire (Preston)",
    label: "University of Central Lancashire (Preston)",
  },
  {
    value: "University of Reading (Reading)",
    label: "University of Reading (Reading)",
  },
  {
    value: "University of Salford (Salford)",
    label: "University of Salford (Salford)",
  },
  {
    value: "Sheffield Hallam University (Sheffield)",
    label: "Sheffield Hallam University (Sheffield)",
  },
  {
    value: "University of Sheffield (Sheffield)",
    label: "University of Sheffield (Sheffield)",
  },
  {
    value: "Solent University (Southampton)",
    label: "Solent University (Southampton)",
  },
  {
    value: "University of Southampton (Southampton)",
    label: "University of Southampton (Southampton)",
  },
  {
    value: "University of St Andrews (St Andrews)",
    label: "University of St Andrews (St Andrews)",
  },
  {
    value: "University of Stirling (Stirling)",
    label: "University of Stirling (Stirling)",
  },
  {
    value: "Staffordshire University (Stoke-on-Trent)",
    label: "Staffordshire University (Stoke-on-Trent)",
  },
  {
    value: "University of Sunderland (Sunderland)",
    label: "University of Sunderland (Sunderland)",
  },
  {
    value: "Swansea University (Swansea)",
    label: "Swansea University (Swansea)",
  },
  {
    value: "St Mary's University, Twickenham (Twickenham)",
    label: "St Mary's University, Twickenham (Twickenham)",
  },
  {
    value: "Brunel University London (Uxbridge)",
    label: "Brunel University London (Uxbridge)",
  },
  {
    value: "University of Winchester (Winchester)",
    label: "University of Winchester (Winchester)",
  },
  {
    value: "University of Wolverhampton (Wolverhampton)",
    label: "University of Wolverhampton (Wolverhampton)",
  },
  {
    value: "University of Worcester (Worcester)",
    label: "University of Worcester (Worcester)",
  },
  {
    value: "Wrexham Glyndwr University (Wrexham)",
    label: "Wrexham Glyndwr University (Wrexham)",
  },
  {
    value: "The University of York (York)",
    label: "The University of York (York)",
  },
  {
    value: "York St John University (York)",
    label: "York St John University (York)",
  },
]
