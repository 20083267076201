import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { ADD_FAV_LESSONCARD_REQUEST } from "./actionType"

import { addFavLessoncardFailure, addFavLessoncardSuccess } from "./action"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* addFavLessoncard(action) {
  const {
    lessoncardID,
    isFav,
    moduleOption,
    topicOption,
    addFavLessoncardCallBack,
  } = action.payload
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/api/favorite-topic-add/`,

      {
        module_detail_id: moduleOption,
        topic_id: topicOption,
        topic_add_id: lessoncardID,
        is_favorite: isFav,
      },

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    addFavLessoncardCallBack(response)
    yield put(addFavLessoncardSuccess(response.data))
  } catch (error) {
    yield put(addFavLessoncardFailure(error))
  }
}

function* addFavLessoncardSaga() {
  yield takeEvery(ADD_FAV_LESSONCARD_REQUEST, addFavLessoncard)
}

export default addFavLessoncardSaga
