import {
  ADD_FAV_TOPIC_REQUEST,
  ADD_FAV_TOPIC_FAILURE,
  ADD_FAV_TOPIC_SUCCESS,
} from "./actionType"

export const addFavTopicRequest = (
  id,
  isFav,
  moduleId,
  addFavTopicCallBack
) => (
  console.log("viewid==>", id),
  {
    type: ADD_FAV_TOPIC_REQUEST,
    payload: { id, isFav, moduleId, addFavTopicCallBack },
  }
)

export const addFavTopicSuccess = FavTopicDetail => ({
  type: ADD_FAV_TOPIC_SUCCESS,
  payload: FavTopicDetail,
})

export const addFavTopicFailure = error => ({
  type: ADD_FAV_TOPIC_FAILURE,
  payload: error,
})
