import {
  EDIT_COURSE_REQUEST,
  EDIT_COURSE_FAILURE,
  EDIT_COURSE_SUCCESS,
} from "./actionType"

export const editCourseRequest = (data, id, editCourseCallBack) => ({
  type: EDIT_COURSE_REQUEST,
  payload: {
    data,
    id,
    editCourseCallBack,
  },
})

export const editCourseSuccess = editCourseDataSaga => ({
  type: EDIT_COURSE_SUCCESS,
  payload: editCourseDataSaga,
})

export const editCourseFailure = error => ({
  type: EDIT_COURSE_FAILURE,
  payload: error,
})
