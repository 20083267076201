import {
  FETCH_SINGLE_FLASHCARD_DETAIL_REQUEST,
  FETCH_SINGLE_FLASHCARD_DETAIL_FAILURE,
  FETCH_SINGLE_FLASHCARD_DETAIL_SUCCESS,
} from "./actionType"

const initialState = {
  SingleFlashcardDetail: [],
  loading: false,
  error: null,
  // order: "",
}

const fetchSingleFlashcardDetailReducer = (state = initialState, action) => {
  // console.log("Action==>", action)
  switch (action.type) {
    case FETCH_SINGLE_FLASHCARD_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FETCH_SINGLE_FLASHCARD_DETAIL_SUCCESS:
      return {
        ...state,
        SingleFlashcardDetail: action.payload,
        // order: action.payload.order,
        loading: false,
        error: null,
      }
    case FETCH_SINGLE_FLASHCARD_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default fetchSingleFlashcardDetailReducer
