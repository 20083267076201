import {
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
} from "./actionType"

export const ForgotPasswordRequest = email => ({
  type: FORGOT_PASSWORD_REQUEST,
  payload: {
    email,
  },
})

export const ForgotPasswordSuccess = ForgotPassworddatasaga => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: ForgotPassworddatasaga,
})

export const ForgotPasswordFailure = error => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: error,
})
