import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { FETCH_VIEW_COURSE_REQUEST } from "./actionType"

import { fetchViewCourseSuccess, fetchViewCourseFailure } from "./action"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchViewCourse(action) {
  // const id = localStorage.getItem("_id")
  // console.log("valetId==>", id)
  const UserId = sessionStorage.getItem("UserId")
  try {
    const response = yield call(
      axios.get,
      `${BASE_URL}/api/course-detail-retrieve/${UserId}/`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchViewCourseSuccess(response.data))
  } catch (error) {
    yield put(fetchViewCourseFailure(error))
  }
}

function* fetchViewCourseSaga() {
  yield takeEvery(FETCH_VIEW_COURSE_REQUEST, fetchViewCourse)
}

export default fetchViewCourseSaga
