import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { FETCH_VIEW_REFER_LINK_REQUEST } from "./actionType"

import { fetchViewReferLinkSuccess, fetchViewReferLinkFailure } from "./action"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchViewReferLink(action) {
  try {
    const response = yield call(
      axios.get,
      `${BASE_URL}/api/get_refer_friend/`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchViewReferLinkSuccess(response.data))
  } catch (error) {
    yield put(fetchViewReferLinkFailure(error))
  }
}

function* fetchViewReferLinkSaga() {
  yield takeEvery(FETCH_VIEW_REFER_LINK_REQUEST, fetchViewReferLink)
}

export default fetchViewReferLinkSaga
