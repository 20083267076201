import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import "../valetCSS/valetapp.css"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// import { Formik, Form, Field, ErrorMessage } from "formik"
import { BiChevronDown } from "react-icons/bi" // user
import { HiOutlineArrowLeft } from "react-icons/hi" // user
import * as Yup from "yup"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import { useRef } from "react"
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import { Card, CardBody, Col, Row, Form } from "reactstrap"
import { Container, Input } from "reactstrap"
import { Link } from "react-router-dom"
// import avatar4 from "../assets/images/users/avatar-1.jpg"
import Knob from "../../../AllCharts/knob/knob"
import flashcard from "../../Assets/Images/flashcard's card.png"

import Breadcrumbs from "components/Common/Breadcrumb"
const LessionCard = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [readonly, setreadonly] = useState(22)
  const [heart, setHeart] = useState(false)
  const reports = [
    {
      title: "Module Title",
      iconClass: "bx-user",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-archive-in",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-user",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-archive-in",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-user",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-archive-in",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-user",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-archive-in",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-user",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-archive-in",
      description: "Reference",
      moduldetails: "Module Detail",
    },
  ]

  const [selectedFiles, setselectedFiles] = useState([])
  const [loader, setLoader] = useState(20)
  const [show, setShow] = useState(false)
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const initialValues = {
    companyName: "",
    email: "",
    // phone: "",
    password: "",
    companyId: "",
    bookingFees: "",
  }
  const validationSchema = Yup.object({
    companyName: Yup.string().required(props.t("Name is Required")),
    email: Yup.string()
      .matches(
        /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i,
        props.t("Invalid email")
      )
      .required(props.t("Email is required")),
    // phone: Yup.string().required(props.t("Phone Number is Required")),

    companyId: Yup.string()
      .max(15, props.t("Must be 15 characters or less"))
      .required(props.t("Company ID is Required")),
    password: Yup.string()
      .required(props.t("Password is Required"))
      .min(8, props.t("Password must have atleast 8 Characters"))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        props.t(
          "Password must contain at least one letter, one number, and one special character"
        )
      ),
    bookingFees: Yup.string().required(props.t("Booking Fees is Required")),
    // address: Yup.string().required(props.t("Address is Required")),
    // profile: Yup.mixed().required(props.t("Profile is Required")),
    // avatar: Yup.mixed().required(props.t("Avatar is Required")),
    // address: Yup.string().required(props.t("Address is Required"))
  })
  const handleSubmit = values => {
    console.log("values-->", values)
    if (!phone) {
      setPhoneError(props.t("Phone Number is Required"))
      return
    } else {
      setPhoneError("")
    }
    if (!place) {
      setSearchInputError(props.t("Address is Required"))
      return
    } else {
      setSearchInputError("")
    }

    if (!company_profile) {
      setProfileError(props.t("Profile is Required"))
      return
    } else {
      setProfileError("")
    }

    if (!company_pic) {
      setAvatarError(props.t("Document is Required"))
      return
    } else {
      setAvatarError("")
    }
    setLoader(true)
    const formData = new FormData()
    const use_obj = { ...values, document: values.company_pic }
    console.log(use_obj)

    formData.append("companyName", values.companyName.trimStart())
    formData.append("email", values.email)
    formData.append("phone", phone)
    formData.append("address", place)
    formData.append("companyId", values.companyId.trimStart())
    formData.append("password", values.password)
    formData.append("bookingFees", values.bookingFees)
    formData.append("profile", company_profile)
    formData.append("avatar", company_pic)
    formData.append("latitude", latlng.lat)
    formData.append("longitude", latlng.lng)
    dispatch(
      addCompanyRequest(formData, () => {
        console.log("Hogaygayagya")
        history.push("/company")
      })
    )
    setLoader(false)
    // setTimeout(() => {
    //   history.push("/company")
    // }, 2000)
    // else toast.error(addCompanydata.error)

    // console.log("formdata-->", formData, "$$$$", company_pic)
  }

  const fileInputRef = useRef(null)

  const handleImageSelect = e => {
    const file = e.target.files[0]
    // if (file) {
    //   onImageSelect(file)
    // }
    console.log("file", file)
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  const nextPage = () => {
    history.push("/favourites")
  }
  return (
    <div className={`main-content${classAlign}`}>
      <MetaTags>
        <title>Topic Detail | StudyBudi</title>
      </MetaTags>
      {/* {(document.title = "Company | Nater")} */}

      <div className="page-content">
        <ToastContainer />
        <div
          className="inner-page-content"
          style={{
            paddingRight: "30px",
            paddingLeft: "30px",
            paddingTop: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-start",
              marginBottom: "5px",
              alignItems: "center",
              height: "50px",
              // marginTop: "5px",
            }}
          >
            <HiOutlineArrowLeft
              style={{ fontSize: "50px", fontWeight: "200", color: "#CACAD0" }}
            />
            {/* <span
              style={{
                fontSize: "50px",
                fontWeight: "200",
                color: "#CACAD0",
                fontFamily: "cursive",
              }}
            >
              ←
            </span> */}
          </div>
          <Container fluid style={{ padding: "0px" }}>
            {/* Render Breadcrumbs */}
            <Row style={{ backgroundColor: "white" }}>
              <Col lg={12} md={12} style={{ padding: "0px" }}>
                <Card style={{ marginBottom: "0px" }}>
                  <CardBody>
                    <div className="d-flex">
                      <div className="me-4">
                        <div className="mt-4 mt-md-0">
                          <div
                            className={`avatar-md rounded-circle  mini-stat-icon `}
                          >
                            <span
                              className="avatar-title rounded-circle "
                              style={{
                                backgroundColor: "#E9F6F7",
                                // paddingBottom: "100px",
                              }}
                              // style={{ backgroundColor: "white" }}
                            >
                              {/* <img
                                src={}
                                className={`avatar-md rounded-circle  mini-stat-icon `}
                                // alt={report.title}
                                // style={{ width: "20px", height: "20px" }}
                              /> */}
                            </span>
                          </div>
                          {/* <img
                            className="rounded-circle avatar-lg"
                            alt="Skote"
                            // src={avatar4}
                          /> */}
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5>
                          Advanced Algebra{" "}
                          {/* <BiChevronDown
                            style={{ fontSize: "30px", color: "#BFBFBF" }}
                          /> */}
                        </h5>
                        <div className="blueBadgeDiv">
                          <span className="blueBadge">Semester 1</span>
                          <span className="blueBadge">August</span>
                          <span className="blueBadge">Coursework</span>
                        </div>
                        <p
                          className="mb-3"
                          style={{ color: "#8C9090", marginTop: "10px" }}
                        >
                          Topic 1
                          <BiChevronDown
                            style={{ fontSize: "30px", color: "#BFBFBF" }}
                          />
                        </p>

                        {/* <p className="mb-2" style={{ letterSpacing: "2px" }}>
                          Semester 1- Friday,27 Ocober 2023
                        </p> */}
                      </div>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {/* <i className="mdi mdi-dots-vertical font-size-18" /> */}
                        <span
                          className="icon-color"
                          style={{
                            fontSize: "14px",
                            color: "#CCCCCC",
                            marginLeft: "5px",
                          }}
                        >
                          4
                        </span>
                      </div>

                      {/* <i className="mdi mdi-dots-vertical font-size-18" /> */}
                    </div>
                  </CardBody>
                  <CardBody className="border-top">
                    <Row>
                      <Col sm="12">
                        <div>
                          <h5 className="text-center">Topic 1</h5>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          {/* <div className="CouseDetailHeading"> */}

          {/* </div> */}
          <Container fluid style={{ padding: "0px", marginTop: "30px" }}>
            {/* Render Breadcrumbs */}
            <Row>
              <Col lg={12} md={12} style={{ padding: "0px" }}>
                <Row>
                  {reports &&
                    reports.map((report, key) => (
                      <Col md="6" key={"_col_" + key}>
                        <Card>
                          <CardBody style={{ padding: "13px 5px 5px 13px" }}>
                            <div className="row align-items-center">
                              <div className="col-8">
                                <div className="d-flex align-items-center">
                                  <div className="me-4">
                                    <div className="avatar-md rounded-circle mini-stat-icon">
                                      <span
                                        className="avatar-title rounded-circle"
                                        style={{
                                          backgroundColor: "#E9F6F7",
                                          width: "60px",
                                          height: "60px",
                                        }}
                                      >
                                        <img
                                          src={flashcard}
                                          // className={`avatar-sm rounded-circle  mini-stat-icon `}
                                          // alt={report.title}
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    <h5
                                      style={{
                                        fontSize: "15px",
                                        letterSpacing: "2px",
                                      }}
                                    >
                                      Math lesson
                                    </h5>
                                    <p
                                      className="mb-3"
                                      style={{ color: "#8C9090" }}
                                    >
                                      15 cards
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="col-4 d-flex "
                                style={{
                                  paddingRight: "10px",
                                  paddingBottom: "10px",
                                  justifyContent: "space-around",
                                }}
                              >
                                {heart ? (
                                  <i
                                    className="mdi mdi-heart"
                                    style={{
                                      fontSize: "20px",
                                      color: "#92DEE4", // Custom text color
                                      //
                                    }}
                                    onClick={() => {
                                      setHeart(!heart)
                                    }}
                                  />
                                ) : (
                                  <i
                                    className="mdi mdi-heart-outline "
                                    style={{
                                      fontSize: "20px",
                                      color: "#CCCCCC",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setHeart(!heart)
                                    }}
                                  />
                                )}
                                <Knob
                                  value={readonly}
                                  fgColor="#0BBFCD"
                                  displayInput={false}
                                  thickness={0.12}
                                  readOnly={true}
                                  height={55}
                                  width={55}
                                  onChange={e => {
                                    setreadonly(e)
                                  }}
                                />
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  {/* <Col md="6">

            </Col> */}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  )
}
LessionCard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(LessionCard)
