import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { FETCH_VIEW_RECENT_MODULE_REQUEST } from "./actionType"

import {
  fetchViewRecentModuleSuccess,
  fetchViewRecentModuleFailure,
} from "./action"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchViewRecentModule(action) {
  try {
    const response = yield call(
      axios.get,
      `${BASE_URL}/api/recently-viewed/`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )

    yield put(fetchViewRecentModuleSuccess(response.data))
  } catch (error) {
    yield put(fetchViewRecentModuleFailure(error))
  }
}

function* fetchViewRecentModuleSaga() {
  yield takeEvery(FETCH_VIEW_RECENT_MODULE_REQUEST, fetchViewRecentModule)
}

export default fetchViewRecentModuleSaga
