import {
  RESTART_BUTTON_FAILURE,
  RESTART_BUTTON_REQUEST,
  RESTART_BUTTON_SUCCESS,
} from "./actionType"

export const restartButtonRequest = (
  ModuleID,
  TopicID,
  PPTID,
  restartButtonCallBack
) => ({
  type: RESTART_BUTTON_REQUEST,
  payload: {
    ModuleID,
    TopicID,
    PPTID,
    restartButtonCallBack,
  },
})

export const restartButtonSuccess = restartButtonDataSaga => ({
  type: RESTART_BUTTON_SUCCESS,
  payload: restartButtonDataSaga,
})

export const restartButtonFailure = error => ({
  type: RESTART_BUTTON_FAILURE,
  payload: error,
})
