import {
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
} from "./actionType"

const initialState = {
  ForgotPassworddatasaga: [],
  loading: false,
  error: null,
}

const ForgotPasswordReducer = (state = initialState, action) => {
  console.log("Accccction=>", action)
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        ForgotPassworddatasaga: action.payload,
        loading: false,
        error: null,
      }
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default ForgotPasswordReducer
