import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import "../valetCSS/valetapp.css"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import * as Yup from "yup"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import { addCourseRequest } from "store/actions"
import { fetchViewModuleRequest } from "store/actions"
import { DegreeOptions, CourseOptions, UniversityOptions } from "./DegreeDetail"
import { fetchViewCourseRequest } from "store/actions"
import BottomBar from "components/VerticalLayout/BottomBar"
import CreatableSelect from "react-select/creatable"
// import GoogleAds from "store/Valet/User/Account/GoogleAds/GoogleAds"

const Course = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const ViewCourseData = useSelector(
    state => state.fetchViewCourseReducer?.ViewCourse?.data
  )
  const ViewCourseDataDegreeLevel = useSelector(
    state => state.fetchViewCourseReducer?.ViewCourse?.data?.Degree_level
  )
  console.log("ViewCourseDataDegreeLevel", ViewCourseDataDegreeLevel)
  console.log("ViewCourseData", ViewCourseData)
  useEffect(() => {
    dispatch(fetchViewCourseRequest())
  }, [])
  const ViewModuleData = useSelector(
    state => state.fetchViewModuleReducer?.ViewModule?.data
  )
  console.log("ViewModuleData", ViewModuleData)
  useEffect(() => {
    dispatch(fetchViewModuleRequest(ViewModuleCallBack))
  }, [])
  const ViewModuleCallBack = () => {}
  const [degreeOption, setDegreeOption] = useState(null)
  const [degreeOptionError, setDegreeOptionError] = useState("")

  const [courseNameOption, setCourseNameOption] = useState(null)
  const [courseNameOptionError, setCourseNameOptionError] = useState("")

  const [universityNameOption, setUniversityNameOption] = useState(null)
  const [universityNameOptionError, setUniversityNameOptionError] = useState("")

  const moduleDetailHandler = id => {
    history.push(`/topic_lessioncard/${id}`)
  }
  const DegreeChange = degreeOption => {
    setDegreeOption(degreeOption)
    setDegreeOptionError("")
    // handle further with the selected option
    console.log(`degree Option:`, degreeOption)
  }
  const CourseNameChange = courseNameOption => {
    setCourseNameOption(courseNameOption)
    setCourseNameOptionError("")
    // handle further with the selected option
    console.log(`degree Option:`, courseNameOption)
  }
  const UniversityNameChange = universityNameOption => {
    setUniversityNameOption(universityNameOption)
    setUniversityNameOptionError("")
    // handle further with the selected option
    console.log(`degree Option:`, universityNameOption)
  }

  const initialValues = {
    courseYear: "",
  }
  const validationSchema = Yup.object({
    courseYear: Yup.string()
      .matches(
        /^[0-9]{1}$/,
        " It must be 1 digits long and contain only numbers."
      )
      .required(props.t("Course Year is Required")),
  })

  const handleSubmit = values => {
    if (!degreeOption) {
      setDegreeOptionError(props.t("Degree Level is Required"))
      return
    } else {
      setDegreeOptionError("")
    }
    if (!courseNameOption) {
      setCourseNameOptionError(props.t("Course Name is Required"))
      return
    } else {
      setCourseNameOptionError("")
    }
    if (!universityNameOption) {
      setUniversityNameOptionError(props.t("University Name is Required"))
      return
    } else {
      setUniversityNameOptionError("")
    }
    dispatch(
      addCourseRequest(
        degreeOption.value,
        courseNameOption.value,
        values.courseYear,
        universityNameOption.value,
        successCallBack
      )
    )
  }
  const successCallBack = response => {
    // toast.success("Course Added Successfully")
    setTimeout(() => {
      history.push("/educationlevel")
    }, 1000)
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  const nextPage = () => {
    history.push("/educationlevel")
  }
  return (
    <div className={`main-content${classAlign}`}>
      <MetaTags>
        <title>Course | StudyBudi</title>
      </MetaTags>
      {/* {(document.title = "Company | Nater")} */}
      <div className="page-content">
        {/* <ToastContainer /> */}

        <div className="inner-page-content">
          {ViewCourseData === undefined ? (
            <div>
              <div className="CouseDetailHeading">
                <div style={{ width: "70%", color: "#626565" }}>
                  {" "}
                  Enter Course Details
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    width: "30%",
                    color: "#CCCCCC",
                    fontSize: "15px",
                  }}
                >
                  <button
                    // onClick={handleSubmit}
                    type="submit"
                    // onClick={nextClick}
                    style={{
                      cursor: "pointer",
                      width: "7rem",
                      height: "2rem",

                      backgroundColor: "#171c46",
                      border: "1px solid #BCBCBC",
                      color: "#BCBCBC",
                      borderRadius: "5px",
                    }}
                  >
                    {"Add New"}
                  </button>
                </div>
              </div>
              <div className="courseform">
                <div
                  style={{
                    width: "150px",
                    height: "50px",
                    backgroundColor: "#0BAFBC",
                    borderRadius: "40px",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "30px",
                    color: "white",
                    marginBottom: "20px",
                  }}
                >
                  Degree
                </div>
                <CreatableSelect
                  value={degreeOption}
                  onChange={DegreeChange}
                  options={DegreeOptions}
                  isSearchable={true}
                  className="coursedropdown"
                  classNamePrefix="my-select"
                  placeholder="Degree Level eg.BSc"
                />
                <p className="textdanger1">{props.t(degreeOptionError)}</p>
                {/* <Select
              className="basic-single"
              classNamePrefix="select"
              // defaultValue={colourOptions[0]}
              // name="color"
              options={options}
            /> */}
                {/* <select
              value={sortOption}
              onChange={handleSortChange}
              className="coursedropdown"
              placeholder="course"
            >
              <option value="" disabled selected>
                {props.t("Select Course")}
              </option>

              <option value="A-Z">{props.t(Constant.az)}</option>
              <option value="Z-A">{props.t(Constant.za)}</option>
              <option value="Newest">{props.t(Constant.newest)}</option>
              <option value="Oldest">{props.t(Constant.oldest)}</option>
            </select> */}
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ touched }) => (
                    <Form>
                      <CreatableSelect
                        value={courseNameOption}
                        onChange={CourseNameChange}
                        options={CourseOptions}
                        isSearchable={true}
                        className="coursedropdown"
                        classNamePrefix="my-select"
                        placeholder="Course Name"
                      />
                      <p className="textdanger1">
                        {props.t(courseNameOptionError)}
                      </p>

                      {/* <div className="inputdiv">
                    <Field
                      className="form-control"
                      style={{ height: "50px", backgroundColor: "white" }}
                      type="text"
                      name="courseName"
                      placeholder={"Course Name"}
                      maxLength={50}
                    />
                    <p className="textdanger1">
                      <ErrorMessage name={props.t("courseName")} />
                    </p>
                  </div> */}

                      <div className="inputdivCourse">
                        <Field
                          className="form-control"
                          style={{ height: "50px" }}
                          type="text"
                          name="courseYear"
                          placeholder={"Course Year"}
                        />

                        <p className="textdanger1">
                          <ErrorMessage name={props.t("courseYear")} />
                        </p>
                      </div>
                      <CreatableSelect
                        value={universityNameOption}
                        onChange={UniversityNameChange}
                        options={UniversityOptions}
                        isSearchable={true}
                        className="coursedropdown"
                        classNamePrefix="my-select"
                        placeholder="University Name"
                      />
                      <p className="textdanger1">
                        {props.t(universityNameOptionError)}
                      </p>
                      {/* <div className="inputdiv">
                    <Field
                      className="form-control"
                      style={{ height: "50px" }}
                      type="text"
                      name="universityName"
                      placeholder={"University Name"}
                      maxLength={50}
                    />
                    <p className="textdanger1">
                    
                      <ErrorMessage name="universityName" />
                    </p>
                  </div> */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          paddingRight: "15px",
                          color: "white",
                          // border: "2px solid black",
                        }}
                      >
                        <button
                          // onClick={handleSubmit}
                          type="submit"
                          // onClick={nextClick}
                          style={{
                            cursor: "pointer",
                            width: "5rem",
                            height: "2rem",
                            // backgroundColor: "#EFEFEF",
                            backgroundColor: "#171c46",
                            border: "1px solid #BCBCBC",
                            color: "#BCBCBC",
                            borderRadius: "5px",
                          }}
                        >
                          {"Next "}
                        </button>
                      </div>
                      {/* <div className="inlineFieldsBtn">
                    <button
                      className={`cancelbtn${classAlign}`}
                      type="button"
                      onClick={() => cancelHandler()}
                    >
                      {props.t(Constant.cancel)}
                    </button>
                    <button className={`submitbtn${classAlign}`} type="submit">
                      {props.t(Constant.submit)}
                    </button>
                  </div> */}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : (
            // <div>
            //   <div className="CouseDetailHeading">
            //     <div style={{ width: "90%", color: "#626565" }}>
            //       {" "}
            //       Education Level
            //     </div>
            //   </div>
            //   {/* <div className="courseform">
            //     <div
            //       style={{
            //         width: "150px",
            //         height: "50px",
            //         backgroundColor: "#0BAFBC",
            //         borderRadius: "40px",
            //         display: "flex",
            //         alignItems: "center",
            //         paddingLeft: "30px",
            //         color: "white",
            //       }}
            //     >
            //       Degree
            //     </div>
            //     <div
            //       style={{
            //         display: "flex",
            //         flexDirection: "row",
            //         marginTop: "15px",
            //         paddingBottom: "20px",
            //       }}
            //     >
            //       <div
            //         style={{
            //           width: "70%",
            //           //   border: "2px solid black",
            //           paddingLeft: "40px",
            //         }}
            //       >
            //         <h6
            //           style={{
            //             margin: "0px",
            //             color: "#CAF1F4",
            //             letterSpacing: "2px",
            //           }}
            //         >
            //           {ViewCourseData?.Degree_level}
            //         </h6>
            //         <h6 style={{ color: "#CAF1F4", letterSpacing: "2px" }}>
            //           {ViewCourseData?.Course_name}
            //         </h6>
            //         <h6 style={{ color: "#65DEE8", letterSpacing: "2px" }}>
            //           {ViewCourseData?.University_name}
            //         </h6>
            //       </div>
            //       <div
            //         style={{
            //           width: "30%",
            //           //   border: "2px solid black",
            //           color: "#EDFBFB",
            //           display: "flex",
            //           alignItems: "center",
            //           justifyContent: "center",
            //           paddingBottom: "30px",
            //         }}
            //       >
            //         {`YEAR  ${ViewCourseData?.Course_year}`}
            //       </div>
            //     </div>
            //   </div> */}
            //   <div className="courseform">
            //     <div
            //       style={{
            //         width: "150px",
            //         height: "50px",
            //         backgroundColor: "#0BAFBC",
            //         borderRadius: "40px",
            //         display: "flex",
            //         alignItems: "center",
            //         paddingLeft: "30px",
            //         color: "white",
            //       }}
            //     >
            //       Degree
            //     </div>
            //     <div className="degreeDetailDiv">
            //       <div className="degreeDetailText">
            //         <h6 className="degreeDetaillevel">
            //           {ViewCourseData?.Degree_level}
            //         </h6>
            //         <h6 style={{ color: "#CAF1F4", letterSpacing: "2px" }}>
            //           {ViewCourseData?.Course_name}
            //         </h6>
            //         <h6 style={{ color: "#65DEE8", letterSpacing: "2px" }}>
            //           {ViewCourseData?.University_name}
            //         </h6>
            //       </div>
            //       {ViewCourseData?.Course_year ? (
            //         <div className="degreeDetailYear">
            //           {`YEAR  ${ViewCourseData?.Course_year}`}
            //         </div>
            //       ) : (
            //         ""
            //       )}
            //     </div>
            //   </div>
            // </div>
            ""
          )}
          <div className="CouseDetailHeading">
            <div style={{ width: "70%", color: "#626565" }}> My Modules</div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                width: "30%",
                color: "#CCCCCC",
                fontSize: "15px",
                // border: "2px solid ",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/educationlevel")
                // Window.reload()
              }}
            >
              {/* + */}
              <button
                // onClick={handleSubmit}
                type="submit"
                // onClick={nextClick}
                style={{
                  cursor: "pointer",
                  width: "7rem",
                  height: "2rem",
                  // backgroundColor: "#EFEFEF",
                  backgroundColor: "#171c46",
                  border: "1px solid #BCBCBC",
                  color: "#BCBCBC",
                  borderRadius: "5px",
                }}
              >
                {"Add New"}
              </button>
            </div>
          </div>

          <div className="Carddiv">
            <Row>
              <Col xl="12">
                <Row>
                  {ViewModuleData &&
                    ViewModuleData.map((report, key) => (
                      <Col md="6" key={"_col_" + key}>
                        <Card
                          className="mini-stats-wid"
                          style={{
                            backgroundColor: "#FDFDFD",
                            cursor: "pointer",
                            // height: "108px",
                            borderRadius: "10px",
                          }}
                          onClick={() => {
                            // moduleDetailHandler(report.id)
                            history.push(`/el_topicdetail/${report.id}`)
                          }}
                        >
                          <CardBody>
                            <div className="d-flex justify-content-center">
                              <div
                                className={`avatar-sm rounded-circle  mini-stat-icon `}
                              >
                                <span
                                  className="avatar-title rounded-circle "
                                  style={{
                                    backgroundColor: "#E9F6F7",
                                  }}
                                >
                                  {report.image ? (
                                    <img
                                      src={`https://studybudi.co.uk/${report.image}`}
                                      alt="AAAA"
                                      style={{
                                        objectFit: "cover",
                                        width: "100%",
                                        height: "100%",
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                              <div
                                className="flex-grow-1 "
                                style={{
                                  marginLeft: "1vw",
                                }}
                              >
                                <div className="P-Div">
                                  <p
                                    className="text-muted fw-medium"
                                    style={{
                                      color: "#838888",
                                      marginBottom: "0px",
                                      marginLeft: "2vw",
                                    }}
                                  >
                                    {report.Title}
                                  </p>
                                  <span
                                    style={{
                                      alignSelf: "right",
                                      color: "#BFC5C6",
                                    }}
                                  >
                                    6
                                  </span>
                                </div>
                                <p
                                  className="mb-0"
                                  style={{
                                    color: "#838888",
                                    marginLeft: "2vw",
                                    fontSize: "11px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {report.Reference}
                                </p>
                                <p
                                  className="mb-0"
                                  style={{
                                    color: "#838888",
                                    marginLeft: "2vw",
                                    marginTop: "10px",
                                    fontSize: "13px",
                                  }}
                                >
                                  {report.Others}
                                </p>
                                {/* <div className="blueBadgeDiv">
                                  <span className="blueBadgeSmall">
                                    Semester 1
                                  </span>
                                  <span className="blueBadgeSmall">August</span>
                                  <span className="blueBadgeSmall">
                                    Coursework
                                  </span>
                                </div> */}
                              </div>
                              <div style={{ marginLeft: "auto" }}>
                                <h6
                                  className="mb-0"
                                  style={{ color: "#838888" }}
                                >
                                  {report.course}
                                </h6>
                              </div>
                            </div>
                            <div
                              className="newTags-list"
                              style={{
                                // marginLeft: "80px",
                                // zIndex: "999",
                                // marginTop: "-20px",
                                marginBottom: "10px",
                              }}
                            >
                              {report.Tags &&
                                report.Tags.map(tag => (
                                  // <span key={tag} className="tag">
                                  <span
                                    style={{ fontSize: "10px" }}
                                    className="newTags"
                                    key={tag}
                                  >
                                    {tag}
                                  </span>
                                  // </span>
                                ))}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}
Course.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Course)
