import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { FETCH_VIEW_MODULE_REQUEST } from "./actionType"

import { fetchViewModuleSuccess, fetchViewModuleFailure } from "./action"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchViewModule(action) {
  const { ViewModuleCallBack } = action.payload

  try {
    const response = yield call(
      axios.get,
      `${BASE_URL}/api/retrieve_module_detail/`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    ViewModuleCallBack(response)
    yield put(fetchViewModuleSuccess(response.data))
  } catch (error) {
    yield put(fetchViewModuleFailure(error))
  }
}

function* fetchViewModuleSaga() {
  yield takeEvery(FETCH_VIEW_MODULE_REQUEST, fetchViewModule)
}

export default fetchViewModuleSaga
