import {
  ADD_FLASHCARD_BUTTON_FAILURE,
  ADD_FLASHCARD_BUTTON_REQUEST,
  ADD_FLASHCARD_BUTTON_SUCCESS,
} from "./actionType"

export const addFlashcardButtonRequest = (
  ModuleID,
  TopicID,
  PPTID,
  questiionID,
  recalled_correctly,
  addFlashcardButtonCallBack
) => ({
  type: ADD_FLASHCARD_BUTTON_REQUEST,
  payload: {
    ModuleID,
    TopicID,
    PPTID,
    questiionID,
    recalled_correctly,
    addFlashcardButtonCallBack,
  },
})

export const addFlashcardButtonSuccess = addFlashcardButtonDataSaga => ({
  type: ADD_FLASHCARD_BUTTON_SUCCESS,
  payload: addFlashcardButtonDataSaga,
})

export const addFlashcardButtonFailure = error => ({
  type: ADD_FLASHCARD_BUTTON_FAILURE,
  payload: error,
})
