import { put, call, takeEvery, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { toast } from "react-toastify"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"
import { ADD_TOPIC_NAME_REQUEST } from "./actionType"
import { addTopicNameFailure, addTopicNameSuccess } from "./action"

function* addTopicName(action) {
  const { topicValue, ModuleID, addTopicNameCallBack } = action.payload
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/api/Topic_add/`,

      { Topic_name: topicValue, module_detail: ModuleID },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    addTopicNameCallBack(response)
    yield put(addTopicNameSuccess(response.data))

    console.log("addC-->", response.data, action.payload)
  } catch (error) {
    console.log("err====>", error.response)
    const loginError = error.response
    yield put(addTopicNameFailure(error.message))
    // toast.error()
  }
}

export default function* addTopicNameSaga() {
  yield takeLatest(ADD_TOPIC_NAME_REQUEST, addTopicName)
}
