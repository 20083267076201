import { put, call, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { FORGOT_PASSWORD_REQUEST } from "./actionType"
import { ForgotPasswordSuccess, ForgotPasswordFailure } from "./action"
import { toast } from "react-toastify"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* ForgotPassword(action) {
  console.log("email", action.payload)
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/api/forget_password/`,

      action.payload
    )
    yield put(ForgotPasswordSuccess(response.data))
    console.log("forgotPswd-->", response.data, action.payload)

    toast.success("Email Sent Successfully")
  } catch (error) {
    const loginError = error.response
    console.log("forgotpswdError", loginError)
    yield put(ForgotPasswordFailure(error.message))
    toast.error(`${loginError?.data?.non_field_errors}`)
  }
}

export default function* ForgotPasswordSaga() {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, ForgotPassword)
}
