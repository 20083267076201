import {
  FETCH_VIEW_TOPIC_DETAIL_REQUEST,
  FETCH_VIEW_TOPIC_DETAIL_FAILURE,
  FETCH_VIEW_TOPIC_DETAIL_SUCCESS,
} from "./actionType"

export const fetchViewTopicDetailRequest = id => (
  console.log("viewid==>", id),
  {
    type: FETCH_VIEW_TOPIC_DETAIL_REQUEST,
    payload: id,
  }
)

export const fetchViewTopicDetailSuccess = ViewTopicDetail => ({
  type: FETCH_VIEW_TOPIC_DETAIL_SUCCESS,
  payload: ViewTopicDetail,
})

export const fetchViewTopicDetailFailure = error => ({
  type: FETCH_VIEW_TOPIC_DETAIL_FAILURE,
  payload: error,
})
