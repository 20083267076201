import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"

import { Label } from "reactstrap"
// import "../../App.css"
import { useHistory } from "react-router-dom"

//redux
import { useSelector, useDispatch } from "react-redux"
// Formik validation
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.png"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import naterpurple_logo from "../../pages/Components/Assets/Images/NaterPurple_logo.png"
import new_nater_logo from "../../pages/Components/Assets/Images/new_nater_logo.jpg"
import naterlogin_logo from "../../pages/Components/Assets/Images/naterlogin_logo.png"
import tweetnow_logo from "../../pages/Components/Assets/Images/tweetnowlogo.png"
import StudyBudiLogo from "../../pages/Components/Assets/Images/StudyBudiLogo.png"

import "../../App.css"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import { MdEmail } from "react-icons/md"
import { Link } from "react-router-dom"
//Import config
import { withTranslation } from "react-i18next"
// import { GoogleLogin } from "react-google-login"
import { GoogleLogin } from "@react-oauth/google"
import { loginRequest } from "../../store/actions"
import { borderRadius, fontSize } from "@mui/system"
import { fetchGoogleDetailRequest } from "../../store/actions"

const Login = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [show, setShow] = useState(false)

  const ViewGoogleDetails = useSelector(
    state => state.fetchGoogleDetailReducer.googleDetails
  )

  console.log("ViewGoogleDetails", ViewGoogleDetails)
  const initialValues = {
    email: "",
    password: "",
  }
  const nextClick = () => {
    alert("Under Development")
  }
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i,
        "Invalid email"
      )
      .required("Email is required!"),
    password: Yup.string()
      .required("Password is required!")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain at least one letter, one number, and one special character"
      ),
  })

  const formSubmit = values => {
    // handleSubmit()
    // alert(JSON.stringify(errors))
    dispatch(loginRequest(values.email, values.password, successCallBack))
    // setTimeout(() => {
    //   // role === "admin"
    //   //   ? history.push("/dashboard")
    //   //   : history.push("/dashboardvalet")
    //   if (role === "admin") {
    //     history.push("/dashboard")
    //   } else if (role === "company") {
    //     history.push("/dashboardvalet")
    //   } else {
    //     history.push("/")
    //   }
    // }, 2000)
    // Window.reload()
  }

  const successCallBack = response => {
    toast.success("Login Successfully")
    // alert("tfxdfgyugtfghyjugv")
    let role = sessionStorage.getItem("role")
    // if (role === "admin") {
    //   history.push("/dashboard")
    // } else if (role === "company") {
    //   history.push("/dashboardvalet")
    // }
    //if type == admin
  }

  const successCallBack2 = response => {
    console.log("googleRes==>", response)
    // console.log("pushCourse")
    // setTimeout(() => {
    //   console.log("successCallback==>")
    //   history.push("/course")
    // }, 2000)
    Window.reload()
  }
  const responseMessage = response => {
    console.log("google response", response)
    dispatch(fetchGoogleDetailRequest(response.credential, successCallBack2))
  }

  const errorMessage = error => {
    console.log("google error", error)
  }
  // const responseGoogle = response => {
  //   console.log("google response", response)
  // }
  const toggleButton = () => {
    setShow(prevState => !prevState)
  }

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | StudyBudi</title>
      </MetaTags>
      <ToastContainer autoClose={2000} />
      <div className="welcomePageBackground" style={{ overflowX: "hidden" }}>
        <div className="AppLogin">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={formSubmit}
          >
            <Form
              id="formLogin"
              // onSubmit={handleSubmit}
              // onClick={handleSubmit}
            >
              <img
                src={StudyBudiLogo}
                style={{
                  width: "150px",
                  height: "150px",
                  marginBottom: "30px",
                }}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <GoogleLogin
                  shape="pill"
                  width="327px"
                  text="continue_with"
                  logo_alignment="center"
                  onSuccess={responseMessage}
                  onError={errorMessage}
                />
              </div>
              {/* <div>
                <GoogleLogin
                  className="googlelogin"
                  // clientId="<YOUR_GOOGLE_CLIENT_ID>"
                  clientId="1021611278798-d0joa6kier1itot2icu6phh7fhtt37ag.apps.googleusercontent.com"
                  buttonText="Continue with Google"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={"single_host_origin"}
                />
              </div> */}
              <br />
              <span>- OR -</span>
              <br />
              <br />
              <div className="">
                <div className="loginfields">
                  <div className="fieldsSignup">
                    {/* <Label className="labelltrClass">Email</Label> */}
                    <div className="password-lgn1">
                      <Field
                        name="email"
                        className="form-control"
                        placeholder="Enter email address"
                        type="email"
                        // value={values.email}
                        // onChange={handleChange}
                        style={{
                          backgroundColor: "#f1f1f9",
                          height: "41px",
                          width: "327px",
                        }}
                      />
                      {/* <div className="fieldIcon">
                        <MdEmail />
                      </div> */}
                    </div>
                    <p className="text-danger error-msg">
                      <ErrorMessage name="email" />
                    </p>
                  </div>

                  <div className="fieldsSignup">
                    {/* <Label className="labelltrClass">Password</Label> */}
                    <div className="password-lgn1 real-psd">
                      <Field
                        name="password"
                        type={show ? "text" : "password"}
                        placeholder="Enter password"
                        className="form-control"
                        // value={values.password}
                        // onChange={handleChange}
                        style={{
                          backgroundColor: "#f1f1f9",
                          height: "41px",
                          width: "327px",
                        }}
                      />
                      <button
                        type="button"
                        className="passwordBtn"
                        onClick={toggleButton}
                      >
                        {show ? <AiFillEye /> : <AiFillEyeInvisible />}
                      </button>
                    </div>
                    <p className="text-danger error-msg">
                      <ErrorMessage name="password" />
                    </p>
                  </div>
                </div>
                <p style={{ cursor: "pointer" }}>
                  <Link to="/forgot_password" className="textfield">
                    Forgot Password?
                  </Link>
                </p>
                {/* {error && <p>{error}</p>}
              <br /> */}

                <div className="submit-btn-lgn">
                  <button
                    style={{ fontWeight: "600", fontSize: "0.8rem" }}
                    className="loginbtnLgIn"
                    type="submit"
                  >
                    LOG IN
                  </button>
                </div>
                {/* <p
                  style={{
                    color: "grey",
                    marginTop: "10px",
                  }}
                >
                  {" Didn't have an Account?"}
                  <Link to="/register" className="textfield">
                    Signup
                  </Link>
                </p> */}
              </div>
            </Form>
          </Formik>
        </div>
      </div>

      {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                      </div> */}

      {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div> */}

      {/* <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div> */}

      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}

Login.propTypes = {
  // history: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(Login)
