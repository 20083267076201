import {
  FETCH_VIEW_REFER_LINK_FAILURE,
  FETCH_VIEW_REFER_LINK_REQUEST,
  FETCH_VIEW_REFER_LINK_SUCCESS,
} from "./actionType"

export const fetchViewReferLinkRequest = () => ({
  type: FETCH_VIEW_REFER_LINK_REQUEST,
  //   payload: id,
})

export const fetchViewReferLinkSuccess = ViewReferLink => ({
  type: FETCH_VIEW_REFER_LINK_SUCCESS,
  payload: ViewReferLink,
})

export const fetchViewReferLinkFailure = error => ({
  type: FETCH_VIEW_REFER_LINK_FAILURE,
  payload: error,
})
