import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar.png"
import { textAlign } from "@mui/system"
// import StudyBudiLogo from "../../pages/Components/Assets/Images/StudyBudiLogo.png"
import StudyBudiLogo from "../../../../src/pages/Components/Assets/Images/StudyBudiLogo.png"
const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const schoolName = JSON.parse(localStorage.getItem("schoolName"))
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState(schoolName)
  let role = sessionStorage.getItem("role")
  let name = sessionStorage.getItem("name")
  let companyname = sessionStorage.getItem("companyname")
  let email = sessionStorage.getItem("email")
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
          style={{
            display: "flex",
            flexDirection: "row",
            marginRight: "10px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            className="rounded-circle header-profile-user"
            src={StudyBudiLogo}
            alt="Header Avatar"
          />
          <span
            className="d-none d-xl-inline-block ms-2 me-1"
            style={{
              color: "white",
              maxWidth: "100px",
              fontSize: "15px",
            }}
          >
            {username}
          </span>
          <i
            className="mdi mdi-chevron-down d-none d-xl-inline-block"
            style={{ color: "white" }}
          />
        </DropdownToggle>
        {/* <DropdownMenu className={`dropdown-menu-end_${classAlign}`}> */}
        {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"/>
            {props.t("Profile")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1"/>
            {props.t("My Wallet")}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1"/>
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1"/>
            {props.t("Lock screen")}
          </DropdownItem> */}
        {/* {role === "admin" ? (
            <div style={{ marginLeft: "15px", marginRight: "15px" }}>
             
              {props.t(name)}{" "}
            </div>
          ) : (
            <div style={{ marginLeft: "15px", marginRight: "15px" }}>
              
              {props.t(companyname)}{" "}
            </div>
          )}
          <div style={{ marginLeft: "15px", marginRight: "15px" }}>
            
            {props.t(email)}
          </div> */}
        {/* <DropdownItem>
            <i className="bx bx-wallet font-size-16 align-middle me-1"/>
            {props.t(email)}
          </DropdownItem> */}
        {/* <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1"/>
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1"/>
            {props.t("Lock screen")}
          </DropdownItem> */}
        {/* <div className="dropdown-divider " />
        <Link to="/logout" className="dropdown-item">
          <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
          <span>{props.t("Logout")}</span>
        </Link> */}
        {/* </DropdownMenu> */}
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
