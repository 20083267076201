// /********************************************** /
import { React, Suspense } from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { authProtectedRoutes, companyProtectedRoutes } from "routes"
import { useSelector } from "react-redux"
const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const tokendata = sessionStorage.getItem("token")
  // const userEmail = sessionStorage.getItem("userEmail")
  // const role = sessionStorage.getItem("role")
  const { role } = useSelector(state => state.authReducer)
  let currentPath = window.location.hash.split("#")[1]
  console.log("currentPath", currentPath.split("/")[1])
  const publicPath = [
    "/",
    "/login",
    "/signup",
    "/reset_password",
    "/forgot_password",
  ]
  return (
    <>
      {console.log("token==>", tokendata)}
      {/* {tokendata ? ( */}
      {tokendata === null &&
      authProtectedRoutes.filter(a => window.location.hash.includes(a.path))
        .length > 0 ? (
        (console.log("routeIf==>", tokendata), (<Redirect to="/login" />))
      ) : tokendata !== null &&
        (currentPath == "/signup" ||
          currentPath == "/login" ||
          currentPath == "/" ||
          currentPath == "/forgot_password" ||
          currentPath.split("/")[1] == "signup" ||
          currentPath == "/reset_password") ? (
        (console.log("routeElse==>"), (<Redirect to={"/course"} />))
      ) : (
        <Route
          exact
          {...rest}
          render={props => (
            <Layout>
              <Suspense fallback={<div>Loading...</div>}>
                <Component {...props} />
              </Suspense>
            </Layout>
          )}
        ></Route>
      )}
      {/* ) : userEmail !== null &&
        (currentPath == "/" || currentPath == "/signup") ? (
        (console.log("userEmail!=null"), (<Redirect to="/course" />))
      ) : userEmail === null &&
        authProtectedRoutes.filter(a => window.location.hash.includes(a.path))
          .length > 0 ? (
        (console.log("userEmail=null"), (<Redirect to="/" />))
      ) : (
        <Route
          exact
          {...rest}
          render={props => (
            <Layout>
              <Suspense fallback={<div>Loading...</div>}>
                <Component {...props} />
              </Suspense>
            </Layout>
          )}
        ></Route>
      )} */}
    </>
  )
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
