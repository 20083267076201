import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { ADD_FAV_TOPIC_REQUEST } from "./actionType"

import { addFavTopicFailure, addFavTopicSuccess } from "./action"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* addFavTopic(action) {
  const { id, isFav, moduleId, addFavTopicCallBack } = action.payload
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/api/favorite-topic/`,

      {
        module_detail_id: moduleId,
        topic_id: id,
        is_favorite: isFav,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    console.log("responseresponseresponse", response)
    addFavTopicCallBack(response)
    yield put(addFavTopicSuccess(response.data))
  } catch (error) {
    console.log("eeee", error)
    yield put(addFavTopicFailure(error))
  }
}

function* addFavTopicSaga() {
  yield takeEvery(ADD_FAV_TOPIC_REQUEST, addFavTopic)
}

export default addFavTopicSaga
