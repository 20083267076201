import {
  FETCH_VIEW_USER_DETAIL_FAILURE,
  FETCH_VIEW_USER_DETAIL_REQUEST,
  FETCH_VIEW_USER_DETAIL_SUCCESS,
} from "./actionType"

export const fetchViewUserDetailRequest = () => ({
  type: FETCH_VIEW_USER_DETAIL_REQUEST,
  //   payload: id,
})

export const fetchViewUserDetailSuccess = ViewUserDetail => ({
  type: FETCH_VIEW_USER_DETAIL_SUCCESS,
  payload: ViewUserDetail,
})

export const fetchViewUserDetailFailure = error => ({
  type: FETCH_VIEW_USER_DETAIL_FAILURE,
  payload: error,
})
