import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"
import { FETCH_GOOGLE_DETAILS_REQUEST } from "./actionType"
import { fetchGoogleDetailFailure, fetchGoogleDetailSuccess } from "./action"

function* fetchGoogleDetail(action) {
  try {
    const { googleToken, successCallBack2 } = action.payload
    const response = yield call(
      axios.post,
      `${BASE_URL}/api/google-login/`,

      { access_token: googleToken }
      // {
      //   headers: {
      //     Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      //   },
      // }
    )
    console.log("googleresponse1", response.data)
    yield put(fetchGoogleDetailSuccess(response.data))
    sessionStorage.setItem("UserName", response.data.data.name)
    sessionStorage.setItem("UserEmail", response.data.data.email)
    sessionStorage.setItem("UserId", response.data.data.id)
    sessionStorage.setItem("token", response.data.data.token)

    // {
    //   response.data.data.email ?
    successCallBack2(response.data)
    //   : ""
    // }
  } catch (error) {
    yield put(fetchGoogleDetailFailure(error))
  }
}

function* fetchGoogleDetailSaga() {
  yield takeEvery(FETCH_GOOGLE_DETAILS_REQUEST, fetchGoogleDetail)
}

export default fetchGoogleDetailSaga
