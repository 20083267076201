import { put, call, takeEvery, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { toast } from "react-toastify"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"
import { EDIT_COURSE_REQUEST } from "./actionType"
import { editCourseFailure, editCourseSuccess } from "./action"

function* editCourse(action) {
  const { data, id, editCourseCallBack } = action.payload
  try {
    const response = yield call(
      axios.put,
      `${BASE_URL}/api/update_course_detail/${id}/`,

      data,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(editCourseSuccess(response.data))

    editCourseCallBack(response.data)
    // toast.success(`${success}`)
  } catch (error) {
    console.log("err====>", error.response)
    const loginError = error.response
    yield put(editCourseFailure(error.message))
    // toast.error()
  }
}

export default function* editCourseSaga() {
  yield takeLatest(EDIT_COURSE_REQUEST, editCourse)
}
