import {
  FETCH_VIEW_MODULE_FAILURE,
  FETCH_VIEW_MODULE_REQUEST,
  FETCH_VIEW_MODULE_SUCCESS,
} from "./actionType"

export const fetchViewModuleRequest = ViewModuleCallBack => ({
  type: FETCH_VIEW_MODULE_REQUEST,
  payload: { ViewModuleCallBack },
})

export const fetchViewModuleSuccess = ViewModule => ({
  type: FETCH_VIEW_MODULE_SUCCESS,
  payload: ViewModule,
})

export const fetchViewModuleFailure = error => ({
  type: FETCH_VIEW_MODULE_FAILURE,
  payload: error,
})
