import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import "../valetCSS/valetapp.css"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import * as Yup from "yup"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import { fetchViewCourseRequest } from "store/actions"
import { useRef } from "react"
import { addModuleRequest } from "store/actions"
import { fetchViewModuleRequest } from "store/actions"
const EducationLevel = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [inputValue, setInputValue] = useState("")
  const [tags, setTags] = useState([])
  const [tagsError, setTagsError] = useState("")
  const id = sessionStorage.getItem("CourseID")
  const ViewCourseData = useSelector(
    state => state.fetchViewCourseReducer?.ViewCourse?.data
  )
  console.log("ViewCourseData", ViewCourseData)
  useEffect(() => {
    dispatch(fetchViewCourseRequest(ViewModuleCallBack))
  }, [])
  const ViewModuleData = useSelector(
    state => state.fetchViewModuleReducer?.ViewModule?.data
  )
  console.log("ViewModuleData", ViewModuleData)
  useEffect(() => {
    dispatch(fetchViewModuleRequest(ViewModuleCallBack))
  }, [])
  const ViewModuleCallBack = () => {}
  const [selectedOption, setSelectedOption] = useState(null)
  const [image, setImage] = useState("")
  const [previewImage, setPreviewImage] = useState("")
  const handleChange = selectedOption => {
    setSelectedOption(selectedOption)
    // handle further with the selected option
    console.log(`Option selected:`, selectedOption)
  }

  const reports = [
    {
      title: "Module Title",
      iconClass: "bx-user",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-archive-in",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-purchase-tag-alt",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-swim",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-copy-alt",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-run",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-dollar-circle",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-group",
      description: "Reference",
      moduldetails: "Module Detail",
    },
  ]
  const options = [
    { value: "A-Z", label: "0" },
    { value: "A-Z", label: "1" },
    { value: "A-Z", label: "2" },
    { value: "A-Z", label: "3" },
    { value: "A-Z", label: "4" },
    { value: "A-Z", label: "5" },
  ]
  const fileInputRef = useRef(null)

  const handleImageSelect = e => {
    setImage(e.target.files[0])
    const file = e.target.files[0]
    if (file) {
      setPreviewImage(URL.createObjectURL(file))
    }
  }
  const newTopicHandler = () => {
    history.push("/el_topicdetail")
  }
  const successCallBack = response => {
    console.log("addModuleresponse", response)
    dispatch(fetchViewModuleRequest(ViewModuleCallBack))
    toast.success("Module Added Successfully")
    setTimeout(() => {
      history.push(`/el_topicdetail/${response.data.id}`)
    }, 2000)
  }

  const handleInputChange = e => {
    setInputValue(e.target.value)
  }

  const handleKeyDown = e => {
    if (e.key === "," || e.key === "Enter") {
      e.preventDefault() // prevent the default behavior of adding a comma or new line

      if (tags.length < 4) {
        // Update the tags array
        const newTag = inputValue.trim()
        if (newTag && !tags.includes(newTag)) {
          setTags([...tags, newTag])
          setTagsError("")
        }
      } else {
        // Show toast message if more than 4 tags
        toast.error("you can add only 4 tags")
      }

      // Reset the input value
      setInputValue("")
    }
    // setTagsError("")
  }

  const handleRemoveTag = tagToRemove => {
    setTags(tags.filter(tag => tag !== tagToRemove))
  }
  const initialValues = {
    Title: "",
    // Reference: "",
    // Others: "",
  }
  const validationSchema = Yup.object({
    Title: Yup.string().required(props.t("Module Title is Required")),
    // Reference: Yup.string().required(props.t("Reference is Required")),
    // Others: Yup.string().required(props.t("Others is Required")),
  })
  const handleSubmit = values => {
    console.log("tags1", tags)
    console.log("tagsError", tagsError)
    if (Array.isArray(tags) && tags.length === 0) {
      setTagsError("tags is Required")
      return
    } else {
      setTagsError("")
    }
    console.log("values-->", values, image)
    const formData = new FormData()
    formData.append("Title", values.Title)
    formData.append("Tags", tags)
    // tags.forEach((item, index) => {
    //   formData.append("Tags", item)
    // })
    // formData.append("Reference", values.Reference)
    // formData.append("Others", values.Others)
    formData.append("image", image)
    formData.append("course", id)
    dispatch(addModuleRequest(formData, successCallBack))
    // setTimeout(() => {
    //   history.push("/company")
    // }, 2000)
  }
  // tags

  console.log("inputValue", inputValue)
  console.log("tags", tags)

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  const nextPage = () => {
    history.push("/el_topicdetail")
  }
  return (
    <div className={`main-content${classAlign}`}>
      <MetaTags>
        <title>Education Level | StudyBudi</title>
      </MetaTags>
      {/* {(document.title = "Company | Nater")} */}

      <div className="page-content">
        <ToastContainer />
        <div className="inner-page-content">
          {/* <div className="CouseDetailHeading">
            <div style={{ width: "90%", color: "#626565" }}>
              {" "}
              Education Level
            </div>
          </div>
          <div className="courseform">
            <div
              style={{
                width: "150px",
                height: "50px",
                backgroundColor: "#0BAFBC",
                borderRadius: "40px",
                display: "flex",
                alignItems: "center",
                paddingLeft: "30px",
                color: "white",
              }}
            >
              Degree
            </div>
            <div className="degreeDetailDiv">
              <div className="degreeDetailText">
                <h6 className="degreeDetaillevel">
                  {ViewCourseData?.Degree_level}
                </h6>
                <h6 style={{ color: "#CAF1F4", letterSpacing: "2px" }}>
                  {ViewCourseData?.Course_name}
                </h6>
                <h6 style={{ color: "#65DEE8", letterSpacing: "2px" }}>
                  {ViewCourseData?.University_name}
                </h6>
              </div>
              {ViewCourseData?.Course_year ? (
                <div className="degreeDetailYear">
                  {`YEAR  ${ViewCourseData?.Course_year}`}
                </div>
              ) : (
                ""
              )}
            </div>
          </div> */}
          <div className="CouseDetailHeading">
            <div style={{ width: "70%", color: "#626565" }}>
              {" "}
              Modules Details
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                width: "30%",
                color: "#CCCCCC",
                fontSize: "15px",
                // border: "2px solid ",
                cursor: "pointer",
              }}
              onClick={() => {
                // history.push("/educationlevel")
                // Window.reload()
              }}
            >
              {/* + */}
              {/* <button
                // onClick={handleSubmit}
                type="submit"
                // onClick={newTopicHandler}
                style={{
                  cursor: "pointer",
                  width: "7rem",
                  height: "2rem",
                  // backgroundColor: "#EFEFEF",
                  backgroundColor: "#171c46",
                  border: "1px solid #BCBCBC",
                  color: "#BCBCBC",
                  borderRadius: "5px",
                }}
              >
                {"Add New"}
              </button> */}
            </div>
          </div>
          {/* <div className="moduleDetailHeading">
            <div style={{ width: "90%", color: "#626565" }}>
              {" "}
              Modules Details
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                width: "10%",
                color: "#CCCCCC",
                fontSize: "23px",
              }}
            >
              +
            </div>
          </div> */}
          <div className="modulesDetails1">
            <div className="addModules">
              <div
                style={{
                  border: "1px solid grey",
                  borderRadius: "10px",
                  backgroundColor: "#fdfdfd",
                }}
              >
                <div
                  style={{
                    //   border: "2px solid black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                >
                  {" "}
                  <label
                    htmlFor="fileInput"
                    style={{
                      // border: "2px solid black",
                      cursor: "pointer",
                      borderRadius: "50%",
                      height: "75px",
                      width: "75px",
                      backgroundColor: "#DCF2F4",
                      color: "#C9D6D7",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {previewImage ? (
                      <img
                        src={previewImage}
                        alt="Selected Preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <>
                        Add
                        <br />
                        Icon
                      </>
                    )}
                    {/* Add
                    <br /> Icon */}
                    {/* <img src={image} /> */}
                  </label>
                  <input
                    ref={fileInputRef}
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleImageSelect}
                  />
                </div>
                <div
                  style={{
                    //   border: "2px solid black",
                    padding: "20px 20px 0px 20px",
                  }}
                >
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ touched }) => (
                      <Form>
                        <div className="inputdiv">
                          <Field
                            className="form-control"
                            style={{ height: "50px", backgroundColor: "white" }}
                            type="text"
                            name="Title"
                            maxLength={50}
                            placeholder={"Module Title"}
                          />
                          <p className="textdanger1">
                            <ErrorMessage name={props.t("Title")} />
                          </p>
                        </div>
                        <div className="inputdiv">
                          <div>
                            <input
                              className="form-control"
                              style={{ height: "50px" }}
                              type="text"
                              value={inputValue}
                              maxLength={15}
                              onChange={handleInputChange}
                              onKeyDown={handleKeyDown}
                              placeholder="Add a tag and press enter or comma..."
                            />
                            <p className="textdanger1">{props.t(tagsError)}</p>
                            <div
                              className="tags-list"
                              style={{ marginTop: "20px" }}
                            >
                              {tags.map(tag => (
                                <span key={tag} className="tag">
                                  {tag}
                                  <button onClick={() => handleRemoveTag(tag)}>
                                    x
                                  </button>
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>

                        {/* <div
                          className="inputdiv"
                          style={{ marginBottom: "10px" }}
                        >
                          <Field
                            className="form-control"
                            style={{ height: "50px" }}
                            type="text"
                            name="Reference"
                            placeholder={"Reference"}
                          />

                          <p className="textdanger1">
                            <ErrorMessage name={props.t("Reference")} />
                          </p>
                        </div> */}

                        {/* <div className="inputdiv">
                          <Field
                            className="form-control"
                            style={{ height: "50px" }}
                            type="text"
                            name="Others"
                            placeholder={"Other Details eg.Dates, Semester"}
                          />
                          <p className="textdanger1">
                          
                            <ErrorMessage name="Others" />
                          </p>
                        </div> */}
                        {/* <div className="blueBadgeDiv">
                          <span className="blueBadge">Semester 1</span>
                          <span className="blueBadge">August</span>
                          <span className="blueBadge">Coursework</span>
                        </div> */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "space-between",

                            //   paddingTop: "10px",
                            paddingBottom: "10px",
                            //   paddingRight: "15px",
                            color: "black",
                            // border: "2px solid black",
                          }}
                        >
                          <div
                            style={{
                              cursor: "pointer",
                              paddingTop: "60px",
                              fontSize: "14px",
                              color: "#838687",
                            }}
                          >
                            {/* <button
                              type="submit"
                              style={{
                                backgroundColor: "#fdfdfd",
                                border: "none",
                                color: "#959899",
                              }}
                            >
                              {"Add Topics >"}
                            </button> */}
                            <button
                              // onClick={handleSubmit}
                              type="submit"
                              // onClick={nextClick}
                              style={{
                                cursor: "pointer",
                                width: "7rem",
                                height: "2rem",
                                backgroundColor: "#171c46",
                                // backgroundColor: "#EFEFEF",
                                border: "1px solid #BCBCBC",
                                color: "#BCBCBC",
                                borderRadius: "5px",
                              }}
                            >
                              {"Add Topic"}
                            </button>
                            {/* {"Add Topics >"} */}
                          </div>
                          <div
                            style={{
                              cursor: "pointer",
                              paddingTop: "60px",
                              fontSize: "14px",
                              color: "#838687",
                            }}
                          >
                            {/* <button
                              type="submit"
                              style={{
                                backgroundColor: "#fdfdfd",
                                border: "none",
                                color: "#959899",
                              }}
                            >
                              {"Add Topics >"}
                            </button> */}
                            <button
                              // onClick={handleSubmit}
                              type="submit"
                              // onClick={nextClick}
                              style={{
                                cursor: "pointer",
                                width: "7rem",
                                height: "2rem",
                                // backgroundColor: "#EFEFEF",
                                backgroundColor: "#171c46",
                                border: "1px solid #BCBCBC",
                                color: "#BCBCBC",
                                borderRadius: "5px",
                              }}
                            >
                              {"Save"}
                            </button>
                            {/* {"Add Topics >"} */}
                          </div>
                        </div>
                        {/* <div className="inlineFieldsBtn">
                    <button
                      className={`cancelbtn${classAlign}`}
                      type="button"
                      onClick={() => cancelHandler()}
                    >
                      {props.t(Constant.cancel)}
                    </button>
                    <button className={`submitbtn${classAlign}`} type="submit">
                      {props.t(Constant.submit)}
                    </button>
                  </div> */}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div className="modulesEducationlevel">
              {" "}
              <div className="ELCarddiv">
                <Row>
                  <Col xl="12">
                    <Row>
                      {ViewModuleData &&
                        ViewModuleData.map((report, key) => (
                          <Col md="12" key={"_col_" + key}>
                            <Card
                              className="mini-stats-wid"
                              style={{
                                backgroundColor: "#FDFDFD",
                                // height: "108px",
                                borderRadius: "10px",
                              }}
                            >
                              <CardBody>
                                <div className="d-flex justify-content-center">
                                  <div
                                    className={`avatar-sm rounded-circle  mini-stat-icon `}
                                  >
                                    <span
                                      className="avatar-title rounded-circle "
                                      style={{
                                        backgroundColor: "#E9F6F7",
                                      }}
                                    >
                                      {report.image ? (
                                        <img
                                          src={`https://studybudi.co.uk/${report.image}`}
                                          alt="AAAA"
                                          style={{
                                            objectFit: "cover",
                                            width: "100%",
                                            height: "100%",
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </div>
                                  <div
                                    className="flex-grow-1 "
                                    style={{
                                      marginLeft: "1vw",
                                    }}
                                  >
                                    <div className="P-Div">
                                      <p
                                        className="text-muted fw-medium"
                                        style={{
                                          color: "#838888",
                                          marginBottom: "0px",
                                          marginLeft: "2vw",
                                        }}
                                      >
                                        {report.Title}
                                      </p>
                                      <span
                                        style={{
                                          alignSelf: "right",
                                          color: "#BFC5C6",
                                        }}
                                      >
                                        6
                                      </span>
                                    </div>
                                    <p
                                      className="mb-0"
                                      style={{
                                        color: "#838888",
                                        marginLeft: "2vw",
                                        fontSize: "11px",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {report.Reference}
                                    </p>
                                    <p
                                      className="mb-0"
                                      style={{
                                        color: "#838888",
                                        marginLeft: "2vw",
                                        marginTop: "10px",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {report.Others}
                                    </p>
                                    {/* <div className="blueBadgeDiv">
                                      <span className="blueBadgeSmall">
                                        Semester 1
                                      </span>
                                      <span className="blueBadgeSmall">
                                        August
                                      </span>
                                      <span className="blueBadgeSmall">
                                        Coursework
                                      </span>
                                    </div> */}
                                  </div>
                                  <div style={{ marginLeft: "auto" }}>
                                    <h6
                                      className="mb-0"
                                      style={{ color: "#838888" }}
                                    >
                                      {report.course}
                                    </h6>
                                  </div>
                                </div>
                                <div
                                  className="newTags-list"
                                  style={{
                                    // marginLeft: "80px",
                                    // zIndex: "999",
                                    // marginTop: "-20px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {report.Tags &&
                                    report.Tags.map(tag => (
                                      // <span key={tag} className="tag">
                                      <span
                                        style={{ fontSize: "10px" }}
                                        className="newTags"
                                        key={tag}
                                      >
                                        {tag}
                                      </span>
                                      // </span>
                                    ))}
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          {/* <div className="Carddiv">
            <Row>
              <Col xl="12">
                <Row>
                  {reports.map((report, key) => (
                    <Col md="6" key={"_col_" + key}>
                      <Card
                        className="mini-stats-wid"
                        style={{
                          backgroundColor: "#FDFDFD",
                          height: "108px",
                          borderRadius: "10px",
                        }}
                      >
                        <CardBody>
                          <div className="d-flex justify-content-center">
                            <div
                              className={`avatar-sm rounded-circle  mini-stat-icon `}
                            >
                              <span
                                className="avatar-title rounded-circle "
                                style={{
                                  backgroundColor: "#E9F6F7",
                                }}
                              ></span>
                            </div>
                            <div
                              className="flex-grow-1 "
                              style={{
                                marginLeft: "1vw",
                              }}
                            >
                              <p
                                className="text-muted fw-medium"
                                style={{
                                  color: "#838888",
                                  marginBottom: "0px",
                                  marginLeft: "2vw",
                                }}
                              >
                                {report.title}
                              </p>
                              <p
                                className="mb-0"
                                style={{
                                  color: "#838888",
                                  marginLeft: "2vw",
                                  fontSize: "11px",
                                  marginTop: "5px",
                                }}
                              >
                                {report.description}
                              </p>
                              <p
                                className="mb-0"
                                style={{
                                  color: "#838888",

                                  marginTop: "10px",
                                  fontSize: "13px",
                                }}
                              >
                                {report.moduldetails}
                              </p>
                            </div>
                            <div style={{ marginLeft: "auto" }}>
                              <h6 className="mb-0" style={{ color: "#838888" }}>
                                0
                              </h6>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </div> */}
        </div>
      </div>
    </div>
  )
}
EducationLevel.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(EducationLevel)
