// sagas.js
import { takeLatest, put, call } from "redux-saga/effects"
import axios from "axios"
import { REGISTER_USER_REQUEST } from "./actionType"
import { registerUserSuccess, registerUserFailure } from "./action"
import { toast } from "react-toastify"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"
// export function* registeruser(action) {
//   try {
//     const { email, Phone_number, password, confirm_password } = action.payload
//     const response = yield call(
//       axios.post,
//       // "http://13.234.30.42:5000/admin/login",
//       `${BASE_URL}/api/register/`,
//       { email, Phone_number, password, confirm_password }
//     )
//     console.log("AAA", response)
//     yield put(registerUserSuccess(response.data))
//     console.log("login1", response.data)
//     console.log("login1", response)
//     toast.success("Login successful!")
//     // successCallBack(response)
//   } catch (error) {
//     console.log("errors=-->", error.response)

//     const loginError = error.response
//     yield put(registerUserFailure(error.message))
//     toast.error(`${loginError?.data?.errors?.non_field_errors}`)
//   }
// }

// export function* registeruserSaga() {
//   yield takeLatest(REGISTER_USER_REQUEST, registeruser)
// }

// Helper function to make the API request
const registerUserApi = async userData => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/register/`,

      userData
    )
    return response.data
  } catch (error) {
    throw error
  }
}

// Saga worker function
function* registerUsersaga1(action) {
  try {
    const {
      email,
      Phone_number,
      password,
      confirm_password,
      refer_code,
      successCallBack,
    } = action.payload
    console.log("refer_code", refer_code)
    const userData = {
      email,
      Phone_number,
      password,
      confirm_password,
      refer_code,
    }
    const data = yield call(registerUserApi, userData)

    yield put(registerUserSuccess(data))
    console.log("data", data)
    const token = data.token.access
    sessionStorage.setItem("token", token)
    sessionStorage.setItem("UserEmail", data.user.email)
    sessionStorage.setItem("UserId", data.user.id)
    successCallBack(data)
  } catch (error) {
    console.log("referCode1", action.payload.refer_code)
    const loginError = error.response
    console.log("signuperror", loginError)
    yield put(registerUserFailure(error.message))
    toast.error(`${loginError?.data?.error}`)
  }
}

// Saga watcher function
function* RegisterUserSaga() {
  yield takeLatest(REGISTER_USER_REQUEST, registerUsersaga1)
}

export default RegisterUserSaga
