import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { FaUserGraduate, FaHome, FaChalkboardTeacher } from "react-icons/fa"
import { MdFamilyRestroom, MdTextsms } from "react-icons/md"
import { AiTwotoneAlert } from "react-icons/ai"
import { BsCarFrontFill } from "react-icons/bs"
import { SiCashapp } from "react-icons/si" // payment
import { MdPayment } from "react-icons/md" // payment
import { MdMan2 } from "react-icons/md"
import { MdOutlineMan3 } from "react-icons/md"
import { SlCalender } from "react-icons/sl" // booking
import { FaParking } from "react-icons/fa" // booking
import { GrUserWorker } from "react-icons/gr"
import { MdPregnantWoman } from "react-icons/md"
import { ImManWoman } from "react-icons/im"
import { FaRegUser } from "react-icons/fa" // user
import { BiUser } from "react-icons/bi" // user
import { MdDashboard } from "react-icons/md" // dashboard
import { BsFillBuildingsFill } from "react-icons/bs" //company
import { HiBuildingOffice2 } from "react-icons/hi2" //company
import { AiFillCar } from "react-icons/ai" //valet
import { BsBook } from "react-icons/bs" //valet
import { FiHome } from "react-icons/fi"
import { SlHeart } from "react-icons/sl"
import { AiFillSetting } from "react-icons/ai"
import { AiOutlineArrowLeft } from "react-icons/ai"
import { Constant } from "pages/Resources/Constant"
import Swal from "sweetalert2"
import { useHistory } from "react-router-dom"

import { ValetIconsvg } from "store/Valet/SvgIcon/SvgIcon"

import { Col } from "reactstrap"

import "../../App.css"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { Icon } from "@iconify/react"
// import { color } from "@mui/system"

const SidebarContent = props => {
  const ref = useRef()
  let currentPath = window.location.hash.split("#")[1]
  const [color, setColor] = useState({
    a: {},
    b: null,
    c: null,
    d: null,
  })
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  const history = useHistory()
  const loc = window.location.hash
  const selectedTab = loc.slice(0, -5)
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  const swalmsg = () => {
    Swal.fire({
      text: props.t("Are you sure you want to Logout?"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
      confirmButtonText: props.t("OK"),
      cancelButtonText: props.t("Cancel"),
      showConfirmButton: true,
      showCancelButton: true,
    }).then(willDelete => {
      if (willDelete.isConfirmed) {
        history.push("/logout")
      } else {
      }
    })
  }
  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }

  let UserEmail = sessionStorage.getItem("UserEmail")
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" style={{ position: "fixed", top: "120px" }}>
          <ul
            className={`metismenu list-unstyled ${classAlign}`}
            id="side-menu"
          >
            {/* <li className="menu-title">{props.t("Menu")} </li> */}
            {/* <li
              className={
                props.location.pathname === "/dashboard" ? "activeTab" : ""
              }
            >
              <Link to="/dashboard" className="sidebarListItem">
                <FaHome className={`sidebarIcon${classAlign}`} />
                <span className="sidebarListText">
                  {props.t(Constant.dashboard)}
                </span>
              </Link>
            </li> */}
            {/* <div className="sidebarDivUserDetail"> */}
            {/* <div
                style={{
                  width: "18%",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "white",
                }}
              ></div> */}
            {/* <div
                style={{
                  width: "60%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  color: "white",
                 
                }}
              >
                <span>user</span>

                <span style={{ fontSize: "0.6rem" }}>
                  
                  {UserEmail}
                </span>
              </div> */}
            {/* <Col xl="4" sm="6">
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="4">
                        <div className="text-lg-center">
                          <img
                            src={StudyBudiLogo}
                            className="avatar-sm me-3 mx-lg-auto mb-3 mt-1 float-start float-lg-none rounded-circle"
                            alt="img"
                          />
                        </div>
                      </Col>

                      <Col lg="8">
                        <div>
                          <h5 className="text-truncate mb-4 mb-lg-5">
                            {"user"}
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col> */}
            {/* </div> */}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                // border: "1.2px solid red",
              }}
            >
              <div
                style={{
                  border: "1.2px solid #0C0F26",
                  width: "90%",
                  alignSelf: "center",
                }}
              ></div>
              <div
                style={{
                  border: "1.2px solid #373B5F",
                  marginBottom: "20px",
                  width: "90%",
                  alignSelf: "center",
                }}
              ></div>
            </div>
            {/* <hr style={{ color: "white" }} />
            <hr style={{ color: "white" }} /> */}
            <li
              className={props.location.pathname === "/home" ? "activeTab" : ""}
            >
              <Link
                to="/home"
                className="sidebarListItem backgroundSizeHome"
                // style={{ padding: "1.2rem 7.3rem 1.2rem 3.4rem" }}
              >
                <FiHome className={`sidebarIcon${classAlign}`} />
                <span className="sidebarListText">{props.t("HOME")}</span>
              </Link>
            </li>
            <li
              className={
                props.location.pathname === "/course" ||
                props.location.pathname === "/educationlevel" ||
                props.location.pathname === "/el_topicdetail" ||
                props.location.pathname === "/topic_detail" ||
                props.location.pathname === "/flashcard" ||
                props.location.pathname === "/topic_lessioncard" ||
                props.location.pathname === "/lessioncard" ||
                props.location.pathname === "/flashcard_result" ||
                props.location.pathname.split("/")[1] === "topic_lessioncard" ||
                props.location.pathname.split("/")[1] === "flashcard" ||
                props.location.pathname.split("/")[1] === "flashcard_result" ||
                props.location.pathname.split("/")[1] === "topic_detail"
                  ? "activeTab"
                  : ""
              }
            >
              <Link
                to="/course"
                className="sidebarListItem backgroundSizeCourse"
                // style={{ padding: "1.2rem 6.1rem 1.2rem 3.4rem" }}
              >
                <BsBook className={`sidebarIcon${classAlign}`} />
                <span className="sidebarListText">{props.t("COURSE")}</span>
              </Link>
            </li>

            <li
              className={
                props.location.pathname === "/favourites" ||
                props.location.pathname.split("/")[1] === "view_bookings" ||
                selectedTab === "#/view_teacher" ||
                selectedTab === "#/edit_teacher"
                  ? "activeTab"
                  : ""
              }
            >
              <Link
                to="/favourites"
                className="sidebarListItem backgroundSizeFavourites"
                // style={{ padding: "1.2rem 4.2rem 1.2rem 3.4rem" }}
              >
                <SlHeart className={`sidebarIcon${classAlign}`} />
                <span className="sidebarListText">{props.t("FAVOURITES")}</span>
              </Link>
            </li>

            <li
              className={
                props.location.pathname === "/account" ||
                props.location.pathname.split("/")[1] === "view_payment" ||
                selectedTab === "#/view_student" ||
                selectedTab === "#/edit_student"
                  ? "activeTab"
                  : ""
              }
            >
              <Link
                to="/account"
                className="sidebarListItem backgroundSizeAccount"
                // style={{ padding: "1.2rem 5.4rem 1.2rem 3.4rem" }}
              >
                <BiUser className={`sidebarIcon${classAlign}`} />
                <span className="sidebarListText">{props.t("ACCOUNT")}</span>
              </Link>
            </li>
            {/* <li
              className={
                props.location.pathname === "/user_list" ||
                props.location.pathname.split("/")[1] === "view_user" ||
                props.location.pathname.split("/")[1] === "user_transaction"
                  ? "activeTab"
                  : ""
              }
            >
              <Link
                to=""
                className="sidebarListItem backgroundSizeUpgrade"
                // style={{ padding: "1.2rem 5.4rem 1.2rem 3.4rem" }}
              >
                <AiFillSetting className={`sidebarIcon${classAlign}`} />
                <span className="sidebarListText">{props.t("UPGRADE")}</span>
              </Link>
            </li> */}

            <li
              className={
                props.location.pathname === "/parking_location"
                  ? "activeTab"
                  : ""
              }
            >
              {/* <div onClick={swalmsg}> */}
              <Link
                to={currentPath}
                onClick={swalmsg}
                className="sidebarListItem backgroundSizeLogout"
                // style={{ padding: "1.2rem 5.8rem 1.2rem 3.4rem" }}
              >
                <AiOutlineArrowLeft className={`sidebarIcon${classAlign}`} />
                <span className="sidebarListText">{props.t("LOG OUT")}</span>
              </Link>
              {/* </div> */}
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
