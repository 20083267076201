import {
  FETCH_VIEW_COURSE_FAILURE,
  FETCH_VIEW_COURSE_REQUEST,
  FETCH_VIEW_COURSE_SUCCESS,
} from "./actionType"

export const fetchViewCourseRequest = () => ({
  type: FETCH_VIEW_COURSE_REQUEST,
  //   payload: id,
})

export const fetchViewCourseSuccess = ViewCourse => ({
  type: FETCH_VIEW_COURSE_SUCCESS,
  payload: ViewCourse,
})

export const fetchViewCourseFailure = error => ({
  type: FETCH_VIEW_COURSE_FAILURE,
  payload: error,
})
