import {
  FETCH_VIEW_FLASHCARD_BUTTON_FAILURE,
  FETCH_VIEW_FLASHCARD_BUTTON_REQUEST,
  FETCH_VIEW_FLASHCARD_BUTTON_SUCCESS,
} from "./actionType"

const initialState = {
  ViewFlashcardButton: [],
  loading: false,
  error: null,
}

const fetchViewFlashcardButtonReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VIEW_FLASHCARD_BUTTON_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FETCH_VIEW_FLASHCARD_BUTTON_SUCCESS:
      return {
        ...state,
        ViewFlashcardButton: action.payload,
        loading: false,
        error: null,
      }
    case FETCH_VIEW_FLASHCARD_BUTTON_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default fetchViewFlashcardButtonReducer
