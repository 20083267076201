import {
  ADD_FAV_LESSONCARD_REQUEST,
  ADD_FAV_LESSONCARD_FAILURE,
  ADD_FAV_LESSONCARD_SUCCESS,
} from "./actionType"

export const addFavLessoncardRequest = (
  lessoncardID,
  isFav,
  moduleOption,
  topicOption,
  addFavLessoncardCallBack
) => ({
  type: ADD_FAV_LESSONCARD_REQUEST,
  payload: {
    lessoncardID,
    isFav,
    moduleOption,
    topicOption,
    addFavLessoncardCallBack,
  },
})

export const addFavLessoncardSuccess = FavLessoncardDetail => ({
  type: ADD_FAV_LESSONCARD_SUCCESS,
  payload: FavLessoncardDetail,
})

export const addFavLessoncardFailure = error => ({
  type: ADD_FAV_LESSONCARD_FAILURE,
  payload: error,
})
