import { put, call, takeEvery, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { ADD_MODULE_REQUEST } from "./actionType"
import { addModuleSuccess, addModuleFailure } from "./action"
import { toast } from "react-toastify"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* addModule(action) {
  const id = sessionStorage.getItem("CourseID")
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/api/create_module_detail/`,

      action.payload.data,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(addModuleSuccess(response.data))
    console.log("addC-->", response.data, action.payload)
    action.payload.successCallBack(response.data)
    // toast.success(`${success}`)
  } catch (error) {
    // console.log("err====>", error.response?.data?.message)
    const loginError = error.response
    yield put(addModuleFailure(error.message))
    // toast.error(`${loginError?.data?.message}`)
  }
}

export default function* addModuleSaga() {
  yield takeLatest(ADD_MODULE_REQUEST, addModule)
}
