import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import "../valetCSS/valetapp.css"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import Knob from "../../../AllCharts/knob/knob"
import flashcard from "../../Assets/Images/flashcard's card.png"
import { fetchViewFavouriteRequest } from "store/actions"
import { addFavModuleRequest } from "store/actions"
import { addFavTopicRequest } from "store/actions"
import { addFavLessoncardRequest } from "store/actions"
const Favourites = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [readonly, setreadonly] = useState(22)

  const [heart, setHeart] = useState(false)
  const ViewFavourite = useSelector(
    state => state.fetchViewFavouriteReducer?.ViewFavourite
  )
  console.log("ViewFavourite", ViewFavourite)

  const reports = [
    {
      title: "Module Title",
      iconClass: "bx-user",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-archive-in",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-archive-in",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-archive-in",
      description: "Reference",
      moduldetails: "Module Detail",
    },
  ]
  useEffect(() => {
    dispatch(fetchViewFavouriteRequest())
  }, [])

  const heartModuleHandler = (id, isFav) => {
    dispatch(addFavModuleRequest(id, isFav, addFavModuleCallBack))
  }
  const addFavModuleCallBack = () => {
    dispatch(fetchViewFavouriteRequest())
  }
  const heartTopicHandler = (id, isFav, moduleId) => {
    dispatch(addFavTopicRequest(id, isFav, moduleId, addFavTopicCallBack))
  }
  const addFavTopicCallBack = () => {
    dispatch(fetchViewFavouriteRequest())
  }
  const heartLessoncardHandler = (lessoncardID, isFav, ModuleID, TopicID) => {
    console.log("ModuleID", ModuleID)
    dispatch(
      addFavLessoncardRequest(
        lessoncardID,
        isFav,
        ModuleID,
        TopicID,
        addFavLessoncardCallBack
      )
    )
  }
  const addFavLessoncardCallBack = () => {
    dispatch(fetchViewFavouriteRequest())
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  const nextPage = () => {
    history.push("/home")
  }
  return (
    <div className={`main-content${classAlign}`}>
      <MetaTags>
        <title>Favourites | StudyBudi</title>
      </MetaTags>
      {/* {(document.title = "Company | Nater")} */}

      <div className="page-content">
        {/* <ToastContainer /> */}
        <div className="inner-page-content" style={{ padding: "0px 20px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              letterSpacing: "2px",
              fontSize: "22px",
              paddingTop: "20px",
              paddingBottom: "20px",
              //   fontWeight: "bold",
              color: "#404445",
            }}
          >
            My Favourites
          </div>
          <div
            // className="CouseDetailHeading"
            style={{
              width: "100% !important",
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "5px",
              marginBottom: "5px",
            }}
          >
            <div style={{ color: "#626565" }}> My Modules</div>
            <div
              style={{
                color: "#CCCCCC",
                fontSize: "23px",
              }}
            >
              +
            </div>
          </div>
          <Row>
            {ViewFavourite?.favorite_module_details &&
              ViewFavourite?.favorite_module_details.map((report, key) => (
                <Col md="6" key={"_col_" + key}>
                  <Card>
                    <CardBody>
                      <div className="d-flex">
                        <div className="me-4">
                          <div className=" mt-md-0">
                            <div
                              className={`avatar-md rounded-circle  mini-stat-icon `}
                            >
                              <span
                                className="avatar-title rounded-circle "
                                style={{
                                  backgroundColor: "#E9F6F7",
                                  // paddingBottom: "100px",
                                }}
                                // style={{ backgroundColor: "white" }}
                              >
                                {" "}
                                {report.image ? (
                                  <img
                                    src={`https://studybudi.co.uk/${report.image}`}
                                    alt="AAAA"
                                    style={{
                                      objectFit: "cover",
                                      width: "100%",
                                      height: "100%",
                                      borderRadius: "100%",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                                {/* <img
                                src={}
                                className={`avatar-md rounded-circle  mini-stat-icon `}
                                // alt={report.title}
                                // style={{ width: "20px", height: "20px" }}
                              /> */}
                              </span>
                            </div>
                            {/* <img
                            className="rounded-circle avatar-lg"
                            alt="Skote"
                            // src={avatar4}
                          /> */}
                          </div>
                        </div>

                        <div
                          className="flex-grow-1"
                          style={{ letterSpacing: "2px" }}
                        >
                          <h5 className="mb-1" style={{ fontSize: "15px" }}>
                            {report.Title}
                            {/* Advanced Algebra{" "} */}
                          </h5>
                        </div>
                        <div className="icon-tabs1">
                          {report.is_favorite ? (
                            <i
                              className="mdi mdi-heart"
                              style={{
                                fontSize: "20px",
                                color: "#92DEE4", // Custom text color
                                cursor: "pointer",
                                //
                              }}
                              onClick={() => {
                                heartModuleHandler(report.id, false)
                                setHeart(!heart)
                              }}
                            />
                          ) : (
                            <i
                              className="mdi mdi-heart-outline "
                              style={{
                                fontSize: "20px",
                                color: "#CCCCCC",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                heartModuleHandler(report.id, true)
                                setHeart(!heart)
                              }}
                            />
                          )}
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              className="icon-color"
                              style={{
                                fontSize: "14px",
                                color: "#CCCCCC",
                                marginLeft: "5px",
                              }}
                            >
                              4
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        className="newTags-list"
                        style={{
                          // marginLeft: "80px",
                          // zIndex: "999",
                          // marginTop: "-20px",
                          marginBottom: "10px",
                        }}
                      >
                        {report.tags &&
                          report.tags.map(tag => (
                            // <span key={tag} className="tag">
                            <span
                              style={{ fontSize: "10px" }}
                              className="newTags"
                              key={tag}
                            >
                              {tag}
                            </span>
                            // </span>
                          ))}
                      </div>
                      {/* <div className="blueBadgeFavSmallDiv">
                        <span className="blueBadgeSmall">Semester 1</span>
                        <span className="blueBadgeSmall">August</span>
                        <span className="blueBadgeSmall">Coursework</span>
                      </div> */}
                      {/* <div
                        className="newTags-list"
                        style={{
                          marginLeft: "80px",
                          zIndex: "999",
                          marginTop: "-20px",
                          marginBottom: "10px",
                        }}
                      >
                        {reports &&
                          reports.map(tag => (
                        
                            <span className="newTags" key={tag}>
                              Semesterqwertyu
                            </span>
                          ))}
                      </div> */}
                    </CardBody>
                  </Card>
                </Col>
              ))}
            {/* <Col md="6">

            </Col> */}
          </Row>

          <div
            // className="CouseDetailHeading"
            style={{
              width: "100% !important",
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "5px",
              marginBottom: "5px",
            }}
          >
            <div style={{ color: "#626565" }}> My Topic</div>
            <div
              style={{
                color: "#CCCCCC",
                fontSize: "23px",
              }}
            >
              +
            </div>
          </div>
          <Row>
            {ViewFavourite?.favorite_topics &&
              ViewFavourite?.favorite_topics.map((report, key) => (
                <Col md="6" key={"_col_" + key}>
                  <Card>
                    <CardBody>
                      <div className="d-flex">
                        <div className="">
                          <div className=" mt-md-0">
                            <div
                              className={`avatar-md rounded-circle  mini-stat-icon `}
                            >
                              <span
                                className="avatar-title rounded-circle "
                                style={{
                                  backgroundColor: "#E9F6F7",
                                  width: "40px",
                                  height: "40px",
                                  // paddingBottom: "100px",
                                }}
                                // style={{ backgroundColor: "white" }}
                              >
                                {report.module_detail.image ? (
                                  <img
                                    src={`https://studybudi.co.uk/${report.module_detail.image}`}
                                    alt="AAAA"
                                    style={{
                                      objectFit: "cover",
                                      width: "100%",
                                      height: "100%",
                                      borderRadius: "100%",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                                {/* <img
                                src={}
                                className={`avatar-md rounded-circle  mini-stat-icon `}
                                // alt={report.title}
                                // style={{ width: "20px", height: "20px" }}
                              /> */}
                              </span>
                            </div>
                            {/* <img
                            className="rounded-circle avatar-lg"
                            alt="Skote"
                            // src={avatar4}
                          /> */}
                          </div>
                        </div>

                        <div
                          className="flex-grow-1"
                          style={{ letterSpacing: "2px" }}
                        >
                          <h5 className="mb-1" style={{ fontSize: "15px" }}>
                            {report.module_detail.Title}
                            {/* Advanced Algebra{" "} */}
                          </h5>
                          {/* <div className="blueBadgeFavSmallDiv">
                            <span className="blueBadgeSmall">Semester 1</span>
                            <span className="blueBadgeSmall">August</span>
                            <span className="blueBadgeSmall">Coursework</span>
                          </div> */}
                          {/* <p
                            className="mb-2"
                            style={{ fontSize: "14px", color: "#8C9090" }}
                          >
                            {report.module_detail.Reference}
                           
                          </p>
                          <p
                            className="mb-2"
                            style={{ fontSize: "14px", color: "#8C9090" }}
                          >
                            {report.module_detail.Others}
                            
                          </p> */}

                          {/* <div className="blueBadgeSmallTopicDiv">
                            <span className="blueBadgeSmall">Semester 1</span>
                            <span className="blueBadgeSmall">August</span>
                            <span className="blueBadgeSmall">Coursework</span>
                          </div> */}
                          {/* <p
                            className="mb-1"
                            style={{
                              fontSize: "15px",
                              letterSpacing: "2px",
                              marginTop: "5px",
                            }}
                          >
                            {report.Topic_name}
                          </p> */}
                        </div>
                        <div className="icon-tabs1">
                          {report.is_favorite ? (
                            <i
                              className="mdi mdi-heart"
                              style={{
                                fontSize: "20px",
                                color: "#92DEE4", // Custom text color
                                cursor: "pointer",
                                //
                              }}
                              onClick={() => {
                                heartTopicHandler(
                                  report?.id,
                                  false,
                                  report.module_detail.id
                                )
                                setHeart(!heart)
                              }}
                            />
                          ) : (
                            <i
                              className="mdi mdi-heart-outline "
                              style={{
                                fontSize: "20px",
                                color: "#CCCCCC",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                heartTopicHandler(
                                  report?.id,
                                  true,
                                  report.module_detail.id
                                )
                                setHeart(!heart)
                              }}
                            />
                          )}
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              className="icon-color"
                              style={{
                                fontSize: "14px",
                                color: "#CCCCCC",
                                marginLeft: "5px",
                              }}
                            >
                              4
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* <div className="blueBadgeSmallDiv">
                        <span className="blueBadgeSmall">Semester 1</span>
                        <span className="blueBadgeSmall">August</span>
                        <span className="blueBadgeSmall">Coursework</span>
                      </div> */}
                      {/* <div className="blueBadgeFavSmallDiv2">
                        <span className="blueBadgeSmall">Semester 1</span>
                        <span className="blueBadgeSmall">August</span>
                        <span className="blueBadgeSmall">Coursework</span>
                      </div> */}
                      {/* //tags */}
                      {/* <div
                        className="newTags-list"
                        style={{
                          marginLeft: "70px",
                          zIndex: "999",
                          marginTop: "-20px",
                          marginBottom: "10px",
                        }}
                      >
                        {reports &&
                          reports.map(tag => (
                            
                            <span className="newTags" key={tag}>
                             
                              Semesterqwertyu
                            </span>
                            
                          ))}
                      </div> */}
                      <div
                        className="newTags-list "
                        style={{
                          marginLeft: "70px",
                          zIndex: "999",
                          marginTop: "-16px",
                          marginBottom: "10px",
                        }}
                      >
                        {report.module_detail.tags &&
                          report.module_detail.tags.map(tag => (
                            // <span key={tag} className="tag">
                            <span
                              style={{ fontSize: "10px" }}
                              className="newTags"
                              key={tag}
                            >
                              {tag}
                            </span>
                            // </span>
                          ))}
                      </div>
                      <p
                        className="mb-1"
                        style={{
                          fontSize: "15px",
                          letterSpacing: "2px",
                          marginTop: "5px",
                          paddingLeft: "70px",
                        }}
                      >
                        {report.Topic_name}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            {/* <Col md="6">

            </Col> */}
          </Row>
          <div
            // className="CouseDetailHeading"
            style={{
              width: "100% !important",
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "5px",
              marginBottom: "5px",
            }}
          >
            <div style={{ color: "#626565" }}> My Flashcards</div>
            <div
              style={{
                color: "#CCCCCC",
                fontSize: "23px",
              }}
            >
              +
            </div>
          </div>
          <Row>
            {ViewFavourite?.favorite_topic_adds &&
              ViewFavourite?.favorite_topic_adds.map((report, key) => (
                <Col md="6" key={"_col_" + key}>
                  <Card>
                    <CardBody style={{ padding: "13px 5px 5px 13px" }}>
                      <div className="row align-items-center">
                        <div className="col-8">
                          <div className="d-flex align-items-center">
                            <div className="me-4">
                              <div className="avatar-md rounded-circle mini-stat-icon">
                                <span
                                  className="avatar-title rounded-circle"
                                  style={{
                                    backgroundColor: "#E9F6F7",
                                    width: "60px",
                                    height: "60px",
                                  }}
                                >
                                  <img
                                    src={flashcard}
                                    // className={`avatar-sm rounded-circle  mini-stat-icon `}
                                    // alt={report.title}
                                    style={{ width: "30px", height: "30px" }}
                                  />
                                </span>
                              </div>
                            </div>
                            <div>
                              <h5
                                style={{
                                  fontSize: "15px",
                                  letterSpacing: "2px",
                                }}
                              >
                                {/* Math lesson */}
                                {report.title}
                              </h5>
                              <p className="mb-3" style={{ color: "#8C9090" }}>
                                {/* 15 cards */}
                                {`${report.cue_card_count} cards`}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-4 d-flex "
                          style={{
                            paddingRight: "10px",
                            paddingBottom: "10px",
                            justifyContent: "space-around",
                          }}
                        >
                          {report.is_favorite ? (
                            <i
                              className="mdi mdi-heart"
                              style={{
                                fontSize: "20px",
                                color: "#92DEE4", // Custom text color
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                heartLessoncardHandler(
                                  report?.id,
                                  false,
                                  report.module_detail_id,
                                  report.topic
                                )
                              }}
                            />
                          ) : (
                            <i
                              className="mdi mdi-heart-outline "
                              style={{
                                fontSize: "20px",
                                color: "#CCCCCC",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                heartLessoncardHandler(
                                  report?.id,
                                  true,
                                  report.module_detail_id,
                                  report.topic
                                )
                              }}
                            />
                          )}
                          <Knob
                            value={report.green_percentage}
                            fgColor="#0BBFCD"
                            displayInput={false}
                            thickness={0.12}
                            readOnly={true}
                            height={55}
                            width={55}
                            onChange={e => {
                              setreadonly(e)
                            }}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            {/* <Col md="6">

            </Col> */}
          </Row>
        </div>
      </div>
    </div>
  )
}
Favourites.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Favourites)
