import { put, call, takeEvery, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { toast } from "react-toastify"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"
import { ADD_FLASHCARD_BUTTON_REQUEST } from "./actionType"
import { addFlashcardButtonFailure, addFlashcardButtonSuccess } from "./action"

function* addFlashcardButton(action) {
  const {
    ModuleID,
    TopicID,
    PPTID,
    questiionID,
    recalled_correctly,
    addFlashcardButtonCallBack,
  } = action.payload
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/api/recall-status/update/`,

      {
        module_detail_id: ModuleID,
        topic_id: TopicID,
        topic_add_id: PPTID,
        question_id: questiionID,
        recalled_correctly: recalled_correctly,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    addFlashcardButtonCallBack(response)
    yield put(addFlashcardButtonSuccess(response.data))

    console.log("addC-->", response.data, action.payload)
  } catch (error) {
    console.log("err====>", error.response)
    const loginError = error.response
    yield put(addFlashcardButtonFailure(error.message))
    // toast.error()
  }
}

export default function* addFlashcardButtonSaga() {
  yield takeLatest(ADD_FLASHCARD_BUTTON_REQUEST, addFlashcardButton)
}
