import {
  FETCH_VIEW_FAVOURITE_FAILURE,
  FETCH_VIEW_FAVOURITE_REQUEST,
  FETCH_VIEW_FAVOURITE_SUCCESS,
} from "./actionType"

export const fetchViewFavouriteRequest = () => ({
  type: FETCH_VIEW_FAVOURITE_REQUEST,
  //   payload: id,
})

export const fetchViewFavouriteSuccess = ViewFavourite => ({
  type: FETCH_VIEW_FAVOURITE_SUCCESS,
  payload: ViewFavourite,
})

export const fetchViewFavouriteFailure = error => ({
  type: FETCH_VIEW_FAVOURITE_FAILURE,
  payload: error,
})
