// AdSenseAd.js
import React, { useEffect } from "react"

const GoogleAds = style => {
  useEffect(() => {
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [])

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block", ...style }}
      data-ad-client="ca-pub-1944583375311695"
      data-ad-slot="7813720533"
      // data-ad-slot="3040987575"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  )
}

export default GoogleAds
