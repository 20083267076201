import {
  ADD_TOPIC_NAME_FAILURE,
  ADD_TOPIC_NAME_REQUEST,
  ADD_TOPIC_NAME_SUCCESS,
} from "./actionType"

export const addTopicNameRequest = (
  topicValue,
  ModuleID,
  addTopicNameCallBack
) => ({
  type: ADD_TOPIC_NAME_REQUEST,
  payload: {
    topicValue,
    ModuleID,
    addTopicNameCallBack,
  },
})

export const addTopicNameSuccess = addTopicNameDataSaga => ({
  type: ADD_TOPIC_NAME_SUCCESS,
  payload: addTopicNameDataSaga,
})

export const addTopicNameFailure = error => ({
  type: ADD_TOPIC_NAME_FAILURE,
  payload: error,
})
