import {
  ADD_COURSE_REQUEST,
  ADD_COURSE_FAILURE,
  ADD_COURSE_SUCCESS,
} from "./actionType"

export const addCourseRequest = (
  selectedOption,
  courseName,
  courseYear,
  universityName,
  successCallBack
) => ({
  type: ADD_COURSE_REQUEST,
  payload: {
    Degree_level: selectedOption,
    Course_name: courseName,
    Course_year: courseYear,
    University_name: universityName,
    successCallBack: successCallBack,
  },
})

export const addCourseSuccess = addCourseDataSaga => ({
  type: ADD_COURSE_SUCCESS,
  payload: addCourseDataSaga,
})

export const addCourseFailure = error => ({
  type: ADD_COURSE_FAILURE,
  payload: error,
})
