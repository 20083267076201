import {
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
} from "./actionType"

const initialState = {
  ResetPassworddatasaga: [],
  loading: false,
  error: null,
}

const ResetPasswordReducer = (state = initialState, action) => {
  console.log("Accccction=>", action)
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        ResetPassworddatasaga: action.payload,
        loading: false,
        error: null,
      }
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default ResetPasswordReducer
