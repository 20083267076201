import {
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
} from "./actionType"

export const registerUserRequest = (
  email,
  Phone_number,
  password,
  confirm_password,
  refer_code,
  successCallBack
) => ({
  type: REGISTER_USER_REQUEST,
  payload: {
    email,
    Phone_number,
    password,
    confirm_password,
    refer_code: refer_code,
    successCallBack,
  },
})

export const registerUserSuccess = data => ({
  type: REGISTER_USER_SUCCESS,
  payload: data,
})

export const registerUserFailure = error => ({
  type: REGISTER_USER_FAILURE,
  payload: error,
})
