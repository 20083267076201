import {
  TOPIC_DETAIL_FAILURE,
  TOPIC_DETAIL_REQUEST,
  TOPIC_DETAIL_SUCCESS,
  SET_NETWORK_STATUS,
} from "./actionType"
const initialState = {
  ViewTopicDetail: [],
  loading: false,
  error: null,
  isOnline: navigator.onLine,
}

const fetchTopicDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOPIC_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case TOPIC_DETAIL_SUCCESS:
      return {
        ...state,
        ViewTopicDetail: action.payload,
        loading: false,
        error: null,
      }
    case TOPIC_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case SET_NETWORK_STATUS:
      return {
        ...state,
        isOnline: action.payload,
      }
    default:
      return state
  }
}

export default fetchTopicDetailReducer
