import {
  ADD_TOPIC_NAME_FAILURE,
  ADD_TOPIC_NAME_REQUEST,
  ADD_TOPIC_NAME_SUCCESS,
} from "./actionType"

const initialState = {
  addTopicNameDataSaga: [],
  loading: false,
  error: null,
}

const addTopicNameReducer = (state = initialState, action) => {
  console.log("Accccction=>", action)
  switch (action.type) {
    case ADD_TOPIC_NAME_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case ADD_TOPIC_NAME_SUCCESS:
      return {
        ...state,
        addTopicNameDataSaga: action.payload,
        loading: false,
        error: null,
      }
    case ADD_TOPIC_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default addTopicNameReducer
