import {
  FETCH_SINGLE_MODULE_DETAIL_REQUEST,
  FETCH_SINGLE_MODULE_DETAIL_FAILURE,
  FETCH_SINGLE_MODULE_DETAIL_SUCCESS,
} from "./actionType"

export const fetchSingleModuleDetailRequest = id => (
  console.log("viewid==>", id),
  {
    type: FETCH_SINGLE_MODULE_DETAIL_REQUEST,
    payload: id,
  }
)

export const fetchSingleModuleDetailSuccess = SingleModuleDetail => ({
  type: FETCH_SINGLE_MODULE_DETAIL_SUCCESS,
  payload: SingleModuleDetail,
})

export const fetchSingleModuleDetailFailure = error => ({
  type: FETCH_SINGLE_MODULE_DETAIL_FAILURE,
  payload: error,
})
