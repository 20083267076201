import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { FETCH_VIEW_TOPIC_DETAIL_REQUEST } from "./actionType"

import {
  fetchViewTopicDetailFailure,
  fetchViewTopicDetailSuccess,
} from "./action"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchViewTopicDetail(action) {
  try {
    const response = yield call(
      axios.get,
      `${BASE_URL}/api/retrievetopic/${action.payload}/`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchViewTopicDetailSuccess(response.data))
  } catch (error) {
    yield put(fetchViewTopicDetailFailure(error))
  }
}

function* fetchViewTopicDetailSaga() {
  yield takeEvery(FETCH_VIEW_TOPIC_DETAIL_REQUEST, fetchViewTopicDetail)
}

export default fetchViewTopicDetailSaga
