import {
  FETCH_VIEW_FLASHCARD_REQUEST,
  FETCH_VIEW_FLASHCARD_FAILURE,
  FETCH_VIEW_FLASHCARD_SUCCESS,
} from "./actionType"

export const fetchViewFlashcardRequest = (id, successCallBack2) => (
  console.log("viewid==>", id),
  {
    type: FETCH_VIEW_FLASHCARD_REQUEST,
    payload: { id, successCallBack2 },
  }
)

export const fetchViewFlashcardSuccess = ViewFlashcard => ({
  type: FETCH_VIEW_FLASHCARD_SUCCESS,
  payload: ViewFlashcard,
})

export const fetchViewFlashcardFailure = error => ({
  type: FETCH_VIEW_FLASHCARD_FAILURE,
  payload: error,
})
