import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import "../valetCSS/valetapp.css"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { Container, Row, Col, Card, CardBody, Input } from "reactstrap"
import * as Yup from "yup"
import MetaTags from "react-meta-tags"
import Select from "react-select"
import { useRef } from "react"
import { fetchViewCourseRequest } from "store/actions"
import { fetchViewModuleRequest } from "store/actions"
import { addTopicNameRequest } from "store/actions"
import { fetchViewTopicDetailRequest } from "store/actions"
import { fetchSingleModuleDetailRequest } from "store/actions"
import { addFavModuleRequest } from "store/actions"
import { addFavTopicRequest } from "store/actions"
const ELTopicDetail = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { Mid } = useParams()
  const [addTopic, setAddTopic] = useState("")
  const [ModuleID, setModuleID] = useState(Mid)
  const [heart, setHeart] = useState(false)
  const [selectedCard, setSelectedCard] = useState(null)
  const ViewCourseData = useSelector(
    state => state.fetchViewCourseReducer?.ViewCourse?.data
  )
  useEffect(() => {
    dispatch(fetchViewCourseRequest())
  }, [])

  const ViewModuleData = useSelector(
    state => state.fetchViewModuleReducer?.ViewModule?.data
  )
  const ViewTopicNameData = useSelector(
    state => state.fetchViewTopicDetailReducer?.ViewTopicDetail
  )
  const ViewSIngleModuleData = useSelector(
    state => state.fetchSingleModuleDetailReducer?.SingleModuleDetail
  )
  console.log("ViewCourseData", ViewModuleData)
  console.log("ViewTopicNameData", ViewTopicNameData)
  console.log("ViewSIngleModuleData", ViewSIngleModuleData)
  useEffect(() => {
    dispatch(fetchViewModuleRequest(ViewModuleCallBack))
  }, [])
  const ViewModuleCallBack = () => {}

  console.log("Mid", Mid)
  console.log("selectedCard", selectedCard)
  useEffect(() => {
    setModuleID(Mid)
    dispatch(fetchViewTopicDetailRequest(Mid))
    dispatch(fetchSingleModuleDetailRequest(Mid))
  }, [Mid])
  const [inputFields, setInputFields] = useState([
    // Initial input field
    { id: 0, value: "" },
  ])

  const handleAddInput = () => {
    const newInputFields = [
      ...inputFields,
      { id: inputFields.length, value: "" },
    ]
    setInputFields(newInputFields)
  }
  const handleDeleteInput = () => {
    if (inputFields.length > 1) {
      const newInputFields = inputFields.slice(0, inputFields.length - 1)
      setInputFields(newInputFields)
    }
  }
  const heartModuleHandler = (id, isFav) => {
    dispatch(addFavModuleRequest(id, isFav, addFavModuleCallBack))
    // dispatch(fetchSingleModuleDetailRequest(ViewSIngleModuleData?.id))
  }
  const addFavModuleCallBack = () => {
    dispatch(fetchSingleModuleDetailRequest(ViewSIngleModuleData?.id))
  }
  const heartTopicHandler = (id, isFav, moduleId) => {
    dispatch(addFavTopicRequest(id, isFav, moduleId, addFavTopicCallBack))
    dispatch(fetchViewTopicDetailRequest(moduleId))
  }
  const addFavTopicCallBack = () => {
    dispatch(fetchViewTopicDetailRequest(ViewSIngleModuleData?.id))
  }
  const viewTopicHandler = id => {
    setModuleID(id)
    dispatch(fetchViewTopicDetailRequest(id))
    dispatch(fetchSingleModuleDetailRequest(id))
  }
  const handleTopicClick = id => {
    history.push(`/topic_lessioncard/${ModuleID}/${id}`)
  }
  const setAddTopicHandler = (e, index) => {
    const updatedTopics = [...addTopic]
    updatedTopics[index] = e.target.value
    setAddTopic(updatedTopics)
  }
  const handleIconClick = index => {
    const topicValue = addTopic[index]
    console.log("topicValue", topicValue)
    console.log("ModuleID", ModuleID)
    dispatch(addTopicNameRequest(topicValue, ModuleID, addTopicNameCallBack))
    // dispatch(fetchViewTopicDetailRequest(ModuleID))

    const updatedTopics = [...addTopic]
    updatedTopics[index] = ""
    setAddTopic(updatedTopics)
  }
  const addTopicNameCallBack = () => {
    dispatch(fetchViewTopicDetailRequest(ModuleID))
  }
  console.log("addTopicNAme", addTopic)
  const fileInputRef = useRef(null)

  const handleImageSelect = e => {
    const file = e.target.files[0]
    // if (file) {
    //   onImageSelect(file)
    // }
    console.log("file", file)
  }
  const options = [
    { value: "A-Z", label: "0" },
    { value: "A-Z", label: "1" },
    { value: "A-Z", label: "2" },
    { value: "A-Z", label: "3" },
  ]
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  const nextPage = () => {
    history.push("/topic_detail")
  }
  return (
    <div className={`main-content${classAlign}`}>
      <MetaTags>
        <title>Education Level | StudyBudi</title>
      </MetaTags>
      {/* {(document.title = "Company | Nater")} */}

      <div className="page-content">
        <ToastContainer />
        <div className="inner-page-content">
          {/* <div className="CouseDetailHeading">
            <div style={{ width: "90%", color: "#626565" }}>
              {" "}
              Education Level
            </div>
          </div>
          <div className="courseform" style={{ marginBottom: "20px" }}>
            <div
              style={{
                width: "150px",
                height: "50px",
                backgroundColor: "#0BAFBC",
                borderRadius: "40px",
                display: "flex",
                alignItems: "center",
                paddingLeft: "30px",
                color: "white",
              }}
            >
              Degree
            </div>
            <div className="degreeDetailDiv">
              <div className="degreeDetailText">
                <h6 className="degreeDetaillevel">
                  {ViewCourseData?.Degree_level}
                </h6>
                <h6 style={{ color: "#CAF1F4", letterSpacing: "2px" }}>
                  {ViewCourseData?.Course_name}
                </h6>
                <h6 style={{ color: "#65DEE8", letterSpacing: "2px" }}>
                  {ViewCourseData?.University_name}
                </h6>
              </div>
              {ViewCourseData?.Course_year ? (
                <div className="degreeDetailYear">
                  {`YEAR  ${ViewCourseData?.Course_year}`}
                </div>
              ) : (
                ""
              )}
            </div>
          </div> */}
          <div style={{ width: "92%", marginLeft: "2vw", paddingTop: "20px" }}>
            <Container fluid>
              <Row>
                <Col md={6} className="order-2 order-md-1">
                  <div
                    className=" icon-color"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <span
                      style={{
                        // fontSize: "18px",
                        color: "#626565",
                        // cursor: "pointer",
                        display: "flex",
                      }}
                    >
                      Modules
                    </span>
                    <span
                      style={{
                        fontSize: "23px",
                        color: "#CCCCCC",
                        cursor: "pointer",
                        display: "flex",
                      }}
                    >
                      {/* + */}
                    </span>
                  </div>
                  {ViewModuleData &&
                    ViewModuleData.map((report, key) => (
                      <Card
                        className="mini-stats-wid"
                        style={{
                          backgroundColor: "#FDFDFD",
                          // height: "108px",
                          borderRadius: "10px",
                          borderColor:
                            // selectedCard === report.id ? "#5DC6F0" : "",
                            selectedCard !== null
                              ? selectedCard === report.id
                                ? "#5DC6F0"
                                : ""
                              : parseInt(Mid) === report.id
                              ? "#5DC6F0"
                              : "",
                          borderWidth:
                            selectedCard !== null
                              ? selectedCard === report.id
                                ? "2px"
                                : ""
                              : parseInt(Mid) === report.id
                              ? "2px"
                              : "",
                          borderStyle: "solid",
                          cursor: "pointer",
                        }}
                        key={"_col_" + key}
                        onClick={() => {
                          viewTopicHandler(report.id)
                          setSelectedCard(report.id)
                          window.scrollTo(0, 0)
                        }}
                      >
                        <CardBody>
                          <div className="d-flex justify-content-center">
                            <div
                              className={`avatar-sm rounded-circle  mini-stat-icon `}
                            >
                              <span
                                className="avatar-title rounded-circle "
                                style={{
                                  backgroundColor: "#E9F6F7",
                                }}
                              >
                                {" "}
                                {report.image ? (
                                  <img
                                    src={`https://studybudi.co.uk/${report.image}`}
                                    alt="AAAA"
                                    style={{
                                      objectFit: "cover",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                            <div
                              className="flex-grow-1 "
                              style={{
                                marginLeft: "1vw",
                              }}
                            >
                              <div className="P-Div">
                                <p
                                  className="text-muted fw-medium"
                                  style={{
                                    color: "#838888",
                                    marginBottom: "0px",
                                    marginLeft: "2vw",
                                  }}
                                >
                                  {report.Title}
                                </p>
                                <span
                                  style={{
                                    alignSelf: "right",
                                    color: "#BFC5C6",
                                  }}
                                >
                                  6
                                </span>
                              </div>
                              <p
                                className="mb-0"
                                style={{
                                  color: "#838888",
                                  marginLeft: "2vw",
                                  fontSize: "11px",
                                  marginTop: "5px",
                                }}
                              >
                                {report.Reference}
                              </p>
                              <p
                                className="mb-0"
                                style={{
                                  color: "#838888",
                                  marginLeft: "2vw",
                                  marginTop: "10px",
                                  fontSize: "13px",
                                }}
                              >
                                {report.Others}
                              </p>
                              {/* <div className="blueBadgeDiv">
                                <span className="blueBadgeSmall">
                                  Semester 1
                                </span>
                                <span className="blueBadgeSmall">August</span>
                                <span className="blueBadgeSmall">
                                  Coursework
                                </span>
                              </div> */}
                            </div>
                            <div style={{ marginLeft: "auto" }}>
                              <h6 className="mb-0" style={{ color: "#838888" }}>
                                {report.course}
                              </h6>
                            </div>
                          </div>
                          <div
                            className="newTags-list"
                            style={{
                              // marginLeft: "80px",
                              // zIndex: "999",
                              // marginTop: "-20px",
                              marginBottom: "10px",
                            }}
                          >
                            {report.Tags &&
                              report.Tags.map(tag => (
                                // <span key={tag} className="tag">
                                <span
                                  style={{ fontSize: "10px" }}
                                  className="newTags"
                                  key={tag}
                                >
                                  {tag}
                                </span>
                                // </span>
                              ))}
                          </div>
                        </CardBody>
                      </Card>
                    ))}
                </Col>

                {/* /////////////////////////////// Right Part//////////////////////////////////// */}
                <Col md={6} className="order-1 order-md-2">
                  <div
                    className=" icon-color"
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <span
                      style={{
                        // fontSize: "18px",
                        color: "#626565",
                        // cursor: "pointer",
                        display: "flex",
                      }}
                    >
                      Topic Details
                    </span>
                    <span
                      style={{
                        fontSize: "23px",
                        color: "#CCCCCC",
                        cursor: "pointer",
                        display: "flex",
                      }}
                    >
                      {/* + */}
                    </span>
                  </div>
                  <Card
                    style={{
                      border:
                        //  selectedCard ?
                        "2px solid #5DC6F0",
                      // : ""
                    }}
                  >
                    {/* {ViewSIngleModuleData.length != 0 ? ( */}
                    {/* {selectedCard ? ( */}
                    <CardBody>
                      <div className="d-flex">
                        <div className="me-4">
                          <div className=" mt-md-0">
                            <div
                              className={`avatar-md rounded-circle  mini-stat-icon `}
                            >
                              <span
                                className="avatar-title rounded-circle "
                                style={{
                                  backgroundColor: "#E9F6F7",
                                  // paddingBottom: "100px",
                                }}
                                // style={{ backgroundColor: "white" }}
                              >
                                {ViewSIngleModuleData?.image ? (
                                  <img
                                    src={ViewSIngleModuleData?.image}
                                    // alt="AAAA"
                                    style={{
                                      objectFit: "cover",
                                      width: "100%",
                                      height: "100%",
                                      borderRadius: "100%",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                            {/* <img
                          className="rounded-circle avatar-lg"
                          alt="Skote"
                          // src={avatar4}
                        /> */}
                          </div>
                        </div>

                        <div
                          className="flex-grow-1"
                          style={{ letterSpacing: "2px" }}
                        >
                          <h5
                            className="ModuleNameFontSize"
                            // style={{ fontSize: "17px" }}
                          >
                            {ViewSIngleModuleData?.Title}
                          </h5>

                          {/* <p className="mb-3" style={{ color: "#8C9090" }}>
                              {ViewSIngleModuleData?.Reference}
                            </p>

                            <p
                              className="mb-2"
                              style={{
                                fontSize: "12px",
                                letterSpacing: "2px",
                                // fontWeight: "bold",
                              }}
                            >
                              {ViewSIngleModuleData?.Others}
                            </p> */}
                          {/* <div
                              className="tags-list"
                              style={{ marginTop: "20px" }}
                            >
                              {options &&
                                options.map(tag => (
                                  // <span key={tag} className="tag">
                                  <span className="newTags" key={tag}>
                                    Semester 1
                                  </span>
                                  // </span>
                                ))}
                            </div> */}
                        </div>

                        <div className="icon-tabs-top">
                          {" "}
                          {/* <i
                              className="bx bx-heart icon-color "
                              style={{ fontSize: "18px", color: "#CCCCCC" }}
                            /> */}
                          {ViewSIngleModuleData?.is_favorite ? (
                            <i
                              className="mdi mdi-heart"
                              style={{
                                fontSize: "20px",
                                color: "#92DEE4", // Custom text color
                                cursor: "pointer",
                                //
                              }}
                              onClick={() => {
                                heartModuleHandler(
                                  ViewSIngleModuleData?.id,
                                  false
                                )
                                // setHeart(!heart)
                              }}
                            />
                          ) : (
                            <i
                              className="mdi mdi-heart-outline "
                              style={{
                                fontSize: "20px",
                                color: "#CCCCCC",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                heartModuleHandler(
                                  ViewSIngleModuleData?.id,
                                  true
                                )
                                // setHeart(!heart)
                              }}
                            />
                          )}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {/* <i className="mdi mdi-dots-vertical font-size-18" /> */}
                            <span
                              className="icon-color"
                              style={{
                                fontSize: "14px",
                                color: "#CCCCCC",
                                marginLeft: "5px",
                              }}
                            >
                              4
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        className="newTags-list"
                        style={{
                          marginLeft: "80px",
                          zIndex: "999",
                          marginTop: "-8px",
                          marginBottom: "10px",
                        }}
                      >
                        {ViewSIngleModuleData?.Tags &&
                          ViewSIngleModuleData?.Tags.map(tag => (
                            // <span key={tag} className="tag">
                            <span className="newTags" key={tag}>
                              {tag}
                            </span>
                            // </span>
                          ))}
                      </div>
                      {/* <Row>
                              <Col xl="12">
                                <Row>
                                  {ViewModuleData &&
                                    ViewModuleData.map((report, key) => (
                                      <Col md="6" key={"_col_" + key}>
                                        <span className="newTags">
                                          Semesterqwertyu
                                        </span>
                                      </Col>
                                      
                                    ))}
                                </Row>
                              </Col>
                            </Row> */}
                      {/* <div className="blueBadgeSmallDiv">
                          <span className="blueBadgeSmall">
                            Semesterqwertyu
                          </span>
                          <span className="blueBadgeSmall">
                            Semesterqwertyu
                          </span>
                          <span className="blueBadgeSmall">
                            Semesterqwertyu
                          </span>
                          <span className="blueBadgeSmall">
                            Semesterqwertyu
                          </span>
                          <span className="blueBadgeSmall">August</span>
                          <span className="blueBadgeSmall">Coursework</span>
                        </div> */}
                      <hr style={{ marginTop: "0px" }} />
                      {ViewTopicNameData &&
                        ViewTopicNameData.map((report, key) => (
                          <div
                            className="topic-tab "
                            key={key}
                            style={{
                              borderBottom: "1px solid #CCCCCC",
                              marginBottom: "10px",
                            }}
                          >
                            <h3
                              style={{ color: "#404445" }}
                              className="lt-space font-size-16"
                            >
                              {report.Topic_name}
                            </h3>
                            <div className="icon-tabs">
                              {/* <i
                                    className="bx bx-heart icon-color "
                                    style={{
                                      fontSize: "18px",
                                      color: "#CCCCCC",
                                    }}
                                  /> */}

                              {report.is_favorite ? (
                                <i
                                  className="mdi mdi-heart"
                                  style={{
                                    fontSize: "20px",
                                    color: "#92DEE4", // Custom text color
                                    cursor: "pointer",
                                    //
                                  }}
                                  onClick={() => {
                                    heartTopicHandler(
                                      report?.id,
                                      false,
                                      ViewSIngleModuleData?.id
                                    )
                                    // setHeart(!heart)
                                  }}
                                />
                              ) : (
                                <i
                                  className="mdi mdi-heart-outline "
                                  style={{
                                    fontSize: "20px",
                                    color: "#CCCCCC",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    heartTopicHandler(
                                      report?.id,
                                      true,
                                      ViewSIngleModuleData?.id
                                    )
                                    // setHeart(!heart)
                                  }}
                                />
                              )}
                              <i
                                className="mdi mdi-greater-than icon-color"
                                style={{
                                  fontSize: "18px",
                                  color: "#CCCCCC",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleTopicClick(report.id)}
                              />
                            </div>
                          </div>
                        ))}

                      {/* <div style={{ position: "relative" }}>
                  <Input
                    name="title"
                    label="title"
                    type="text"
                    placeholder="Input Topic here"
                    className="input-tab"
                  />
                  <i
                    className="bx bx-comment-check"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "20px",
                      fontSize: "35px",
                      color: "#CCCCCC",
                      cursor: "pointer",
                    }}
                  />
                </div>

                <hr />
                <div
                  className=" icon-color"
                  style={{ fontSize: "34px", color: "#CCCCCC" }}
                >
                  +
                </div> */}
                      {inputFields.map((input, index) => (
                        <div key={input.id} style={{ position: "relative" }}>
                          <Input
                            name={`title_${input.id}`}
                            label={`title_${input.id}`}
                            value={addTopic[index] || ""}
                            onChange={e => setAddTopicHandler(e, index)}
                            type="text"
                            placeholder="Input Topic here"
                            className="input-tab"
                          />
                          <i
                            className="bx bx-comment-check"
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "20px",
                              fontSize: "35px",
                              color: "#CCCCCC",
                              cursor: "pointer",
                            }}
                            onClick={() => handleIconClick(index)}
                          />
                        </div>
                      ))}
                      <hr />
                      <div
                        className=" icon-color"
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "34px",
                            color: "#CCCCCC",
                            cursor: "pointer",
                            display: "flex",
                          }}
                          onClick={handleAddInput}
                        >
                          {/* + */}
                          <button
                            type="submit"
                            style={{
                              cursor: "pointer",
                              width: "7rem",
                              height: "2rem",
                              // backgroundColor: "#EFEFEF",
                              backgroundColor: "#171c46",
                              border: "1px solid #BCBCBC",
                              color: "#BCBCBC",
                              borderRadius: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {"Add New"}
                          </button>
                        </span>
                        {inputFields.length > 1 && ( // Conditionally render delete button
                          <i
                            className="mdi mdi-delete-outline"
                            style={{
                              fontSize: "28px",
                              color: "#CCCCCC",
                              cursor: "pointer",
                            }}
                            onClick={handleDeleteInput}
                          />
                        )}
                      </div>
                    </CardBody>
                    {/* ) : (
                      ""
                    )} */}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  )
}
ELTopicDetail.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ELTopicDetail)
