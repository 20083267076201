import React from "react"
import PropTypes from "prop-types"

function FourZeroFour() {
  document.title = "Page Not Found"
  return (
    <div style={{ padding: "2rem" }}>
      <h1>Page Not Found</h1>
    </div>
  )
}
FourZeroFour.propTypes = {
  component: PropTypes.any,
  path: PropTypes.any,
  t: PropTypes.any,
}

export default FourZeroFour
