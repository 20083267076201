import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"

import { Label } from "reactstrap"
// import "../../App.css"
import { useHistory } from "react-router-dom"

//redux
import { useSelector, useDispatch } from "react-redux"
// Formik validation
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.png"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// import naterpurple_logo from "../../pages/Components/Assets/Images/NaterPurple_logo.png"
// import new_nater_logo from "../../pages/Components/Assets/Images/new_nater_logo.jpg"
// import naterlogin_logo from "../../pages/Components/Assets/Images/naterlogin_logo.png"
// import tweetnow_logo from "../../pages/Components/Assets/Images/tweetnowlogo.png"
import StudyBudiLogo from "../../Assets/Images/StudyBudiLogo.png"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import { MdEmail } from "react-icons/md"

//Import config
import { withTranslation } from "react-i18next"

// import { loginRequest } from "../../store/actions"

const WelcomePage = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [show, setShow] = useState(false)
  const initialValues = {
    email: "",
    password: "",
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i,
        "Invalid email"
      )
      .required("Email is required"),
    password: Yup.string()
      .required("Please enter your password")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain at least one letter, one number, and one special character"
      ),
  })

  const gologin = values => {
    history.push("/login")
  }
  const goSignup = values => {
    history.push("/signup")
  }

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Welcome | StudyBudi</title>
      </MetaTags>
      <ToastContainer autoClose={2000} />
      <div className="welcomePageBackground">
        <div className="App">
          {/* <div className="registerLogin"> */}
          <div>
            <img
              src={StudyBudiLogo}
              style={{
                width: "201px",
                height: "201px",
                //   objectFit: "cover",
                // borderRadius: "10%",
              }}
            />
          </div>
          {/* <h1>Login</h1> */}
          <br />

          <div className="">
            <div
              style={{
                color: "#171C46",
                // fontSize: "16px",
                // fontFamily: "Helvetica Neue",
                width: "353px",
                // border: "2px solid black",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  color: "#171C46",
                  fontSize: "22px",
                  width: "100%",

                  // fontFamily: "Helvetica Neue",
                }}
              >
                Welcome to StudyBudi
              </span>
              <br />
              <br />
              <span
                style={{
                  // fontWeight: "bold",
                  // color: "#171C46",
                  color: "#565F7F",
                  fontSize: "18px",
                  // width: "100%",

                  // fontFamily: "Helvetica Neue",
                }}
              >
                The interactive flashcard creation app <br />
                turning study materials or topic inputs <br />
                into flashcards to aid your revision.
              </span>
            </div>
            <br />
            <br />
            <div className="submit-btn-lgn">
              <button
                className="welcomebtnLgIn"
                type="submit"
                // disabled={isSubmitting}
                onClick={gologin}
              >
                LOG IN
              </button>
            </div>

            <div className="submit-btn-lgn">
              <button
                className="welcomebtnSnUp"
                type="submit"
                // disabled={isSubmitting}
                onClick={goSignup}
              >
                SIGN UP
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

WelcomePage.propTypes = {
  // history: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(WelcomePage)
