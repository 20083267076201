import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

import logo from "../../assets/images/logo.png"
import logoLightPng from "../../assets/images/logo.png"
import sidebar_logo from "../../pages/Components/Assets/Images/aac_logo.png"
import valet_logo from "../../pages/Components/Assets/Images/valet_logo.png"
import nater_logo from "../../pages/Components/Assets/Images/nater_logo.png"
import naterpurple_logo from "../../pages/Components/Assets/Images/NaterPurple_logo.png"
import new_nater_logo from "../../pages/Components/Assets/Images/new_nater_logo.jpg"
import naterlogin_logo from "../../pages/Components/Assets/Images/naterlogin_logo.png"
import StudyBudiLogo from "../../pages/Components/Assets/Images/StudyBudiLogo.png"

import logoDark from "../../pages/Components/Assets/Images/aac_logo.png"

const Sidebar = props => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div
          className="navbar-brand-box"
          style={{ backgroundColor: "#171C46" }}
        >
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img
                src={StudyBudiLogo}
                alt=""
                height="150"
                // style={{ borderRadius: "50%" }}
              />
            </span>
            <span className="logo-lg">
              <img
                src={StudyBudiLogo}
                alt=""
                height="150"
                // style={{ borderRadius: "50%" }}
              />
            </span>
          </Link>

          <div>
            {/* <span className="logo-sm">
              <img src={dashlogo} alt="" height="22" />
            </span> */}
            {/* <span style={{ float: "left" }}> */}
            <span style={{ alignItems: "center" }}>
              <img
                src={StudyBudiLogo}
                alt=""
                height="100"
                className="sidebarLogo"

                // style={{ borderRadius: "20%", marginTop: "5px" }}
              />
            </span>
          </div>
        </div>
        <div data-simplebar className="h-150">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
