import React, { useState } from "react"
import { useEffect } from "react"
import {
  Card,
  CardBody,
  Button,
  CardTitle,
  Col,
  Collapse,
  Row,
  Input,
} from "reactstrap"
// import "./acc.css"
import { FaRegEdit } from "react-icons/fa"
import { FaRegCopy } from "react-icons/fa"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { withTranslation } from "react-i18next"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
  DegreeOptions,
  CourseOptions,
  UniversityOptions,
} from "../Course/DegreeDetail"
import * as Yup from "yup"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import PropTypes from "prop-types"
import classnames from "classnames"
import { addCourseRequest } from "store/actions"
import { fetchViewCourseRequest } from "store/actions"
import { addUserDetailRequest } from "store/actions"
import { fetchViewUserDetailRequest } from "store/actions"
import { addReferLinkRequest } from "store/actions"
import { fetchViewReferLinkRequest } from "store/actions"
import { editCourseRequest } from "store/actions"
import { useRef } from "react"
// SRTIPE PAYMENT
import axios from "axios"
import StripeCheckout from "react-stripe-checkout"
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai" // user
import { GoPencil } from "react-icons/go" // user

function Account(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const [selectedSubscription, setSelectedSubscription] = useState(null)
  console.log("selectedSubscription", selectedSubscription)
  const [col1, setcol1] = useState(false)
  const [col2, setcol2] = useState(false)
  const [col3, setcol3] = useState(false)
  const [col4, setcol4] = useState(false)
  const [upgradeBtn, setUpgradeBtn] = useState(false)
  const [degreeUpdate, setDegreeUpdate] = useState(false)
  useEffect(() => {
    dispatch(fetchViewCourseRequest())
    dispatch(fetchViewUserDetailRequest())
    dispatch(fetchViewReferLinkRequest())
  }, [])
  const ViewReferLinkData = useSelector(
    state => state.fetchViewReferLinkReducer?.ViewReferLink
  )
  const ViewCourseData = useSelector(
    state => state.fetchViewCourseReducer?.ViewCourse?.data
  )
  console.log("ViewReferLinkData", ViewReferLinkData)
  console.log("ViewCourseData", ViewCourseData)
  const handleSubscriptionChange = event => {
    setSelectedSubscription(event.target.value)
  }

  const t_col1 = () => {
    setcol1(!col1)
    setcol2(false)
    setcol3(false)
    setcol4(false)
  }

  const t_col2 = () => {
    setcol2(!col2)
    setcol1(false)
    setcol3(false)
    setcol4(false)
  }

  const t_col3 = () => {
    setcol3(!col3)
    setcol1(false)
    setcol2(false)
    setcol4(false)
  }

  const t_col4 = () => {
    setcol4(!col4)
    setcol1(false)
    setcol2(false)
    setcol3(false)
  }

  const [degreeOption, setDegreeOption] = useState(null)
  const [degreeOptionError, setDegreeOptionError] = useState("")

  const [courseNameOption, setCourseNameOption] = useState(null)
  const [courseNameOptionError, setCourseNameOptionError] = useState("")

  const [universityNameOption, setUniversityNameOption] = useState(null)
  const [universityNameOptionError, setUniversityNameOptionError] = useState("")

  const [isEdit, setIsEdit] = useState(false)
  const [isEditPassword, setIsEditPassword] = useState(false)

  const [image, setImage] = useState(null)
  const [previewImage, setPreviewImage] = useState("")

  const fileInputRef = useRef(null)
  const handleImageSelect = e => {
    setImage(e.target.files[0])
    const file = e.target.files[0]
    if (file) {
      setPreviewImage(URL.createObjectURL(file))
    }
  }
  console.log("image", image)
  console.log("degreeOption", degreeOption)
  const updateDegreeHandle = () => {
    setDegreeOption({
      value: ViewCourseData?.Degree_level,
      label: ViewCourseData?.Degree_level,
    })
    setCourseNameOption({
      value: ViewCourseData?.Course_name,
      label: ViewCourseData?.Course_name,
    })
    setUniversityNameOption({
      value: ViewCourseData?.University_name,
      label: ViewCourseData?.University_name,
    })
    setDegreeUpdate(true)
  }
  const NotupdateDegreeHandle = () => {
    setDegreeUpdate(false)
  }
  const DegreeChange = degreeOption => {
    setDegreeOption(degreeOption)
    setDegreeOptionError("")
    // handle further with the selected option
    console.log(`degree Option:`, degreeOption)
  }
  const CourseNameChange = courseNameOption => {
    setCourseNameOption(courseNameOption)
    setCourseNameOptionError("")
    // handle further with the selected option
    console.log(`degree Option:`, courseNameOption)
  }
  const UniversityNameChange = universityNameOption => {
    setUniversityNameOption(universityNameOption)
    setUniversityNameOptionError("")
    // handle further with the selected option
    console.log(`degree Option:`, universityNameOption)
  }

  const initialValues = {
    courseYear: ViewCourseData?.Course_year,
  }
  const validationSchema = Yup.object({
    courseYear: Yup.string()
      .matches(
        /^[0-9]{1}$/,
        " It must be 1 digits long and contain only numbers."
      )
      .required(props.t("Course Year is Required")),
  })

  const handleSubmit = values => {
    if (!degreeOption) {
      setDegreeOptionError(props.t("Degree Level is Required"))
      return
    } else {
      setDegreeOptionError("")
    }
    if (!courseNameOption) {
      setCourseNameOptionError(props.t("Course Name is Required"))
      return
    } else {
      setCourseNameOptionError("")
    }
    if (!universityNameOption) {
      setUniversityNameOptionError(props.t("University Name is Required"))
      return
    } else {
      setUniversityNameOptionError("")
    }

    const CourseData = {
      Degree_level: degreeOption.value,
      Course_name: courseNameOption.value,
      Course_year: values.courseYear,
      University_name: universityNameOption.value,
    }
    dispatch(
      editCourseRequest(CourseData, ViewCourseData?.id, editCourseCallBack)
    )
  }
  const editCourseCallBack = response => {
    dispatch(fetchViewCourseRequest())
    setDegreeUpdate(false)
    // toast.success("Course Added Successfully")
  }

  const ViewUserDetailData = useSelector(
    state => state.fetchViewUserDetailReducer?.ViewUserDetail?.data
  )
  console.log("ViewUserDetailData", ViewUserDetailData)
  console.log("ViewUserDetailData Username", ViewUserDetailData?.username)

  // user detail edit formik
  const initialValues2 = {
    username: ViewUserDetailData?.username,
    // username: "Aayush",
    email: ViewUserDetailData?.email,
    Phone_number: ViewUserDetailData?.Phone_number,
    password: ViewUserDetailData?.password,
  }
  const validationSchema2 = Yup.object({
    username: Yup.string().required("Username is required!"),
    email: Yup.string()
      .matches(
        /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i,
        "Invalid email"
      )
      .required("Email is required!"),
    Phone_number: Yup.string()
      .matches(
        /^[0-9]{10,15}$/,
        "Phone number must be between 10 to 15 digits long and contain only numbers."
      )
      .required(props.t("Phone number is required!")),
    // Phone_number: Yup.string()
    //   .matches(
    //     /^[0-9]{1,15}$/,
    //     " It must be up to 15 digits long and contain only numbers."
    //   )

    //   .required(props.t("Phone number is required!")),

    // password: Yup.string()
    //   .required("Password is required!")
    //   .min(8, "Password must be at least 8 characters long.")
    //   .matches(
    //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //     "Password must contain at least one letter, one number, and one special character."
    //   ),
  })

  const userEditHandleSubmit = values => {
    console.log("button", image)
    const formData = new FormData()
    formData.append("username", values.username)
    formData.append("email", values.email)
    formData.append("Phone_number", values.Phone_number)
    formData.append("password", values.password)
    formData.append("image", image)
    const userData = {
      username: values.username,
      email: values.email,
      Phone_number: values.Phone_number,
      password: values.password,
    }
    image
      ? dispatch(addUserDetailRequest(formData, userEditCallBack))
      : dispatch(addUserDetailRequest(userData, userEditCallBack))
  }
  const userEditCallBack = response => {
    dispatch(fetchViewUserDetailRequest())
    setIsEdit(false)
    setIsEditPassword(false)
  }
  const NotEdituserDetailHandler = () => {
    // setTimeout(() => {
    dispatch(fetchViewUserDetailRequest())
    // }, 2000)

    setIsEdit(false)
    setIsEditPassword(false)
  }

  // STRIPE PAYMENT
  const onToken = token => {
    const body = {
      token: token.id,
    }
    axios
      .post(`${BASE_URL}/api/payment_view/`, body, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then(response => {
        console.log("sdhghdshjdsjhdshj", response)
        // alert("Payment Success")
      })
      .catch(error => {
        console.log("Payment Error: ", error)
        // alert("Payment Error")
      })
  }
  const subscriptionOptions = [
    { id: "annual", label: "£29.99 Annually", value: "annual" },
    { id: "monthly", label: "£4.99 Monthly", value: "monthly" },
  ]

  const [selectedOption, setSelectedOption] = useState("option1")

  const handleOptionChange = e => {
    setSelectedOption(e.target.value)
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }

  // refer a friend
  const [link, setLink] = useState("") // Store the link
  const [randomCode, setRandomCode] = useState("")
  const [isInputDisabled, setIsInputDisabled] = useState(true) // Initially disable input
  const inputRef = React.createRef()
  const UserId = sessionStorage.getItem("UserId")
  useEffect(() => {
    let min = 1000000000 // Minimum 10 digit number
    let max = 9999999999 // Maximum 10 digit number

    let randomValue = Math.floor(Math.random() * (max - min + 1)) + min

    setRandomCode(randomValue)
  }, [])
  console.log("randomCode1", randomCode)
  const generateLink = () => {
    // Replace this with your link generation logic
    // const generatedLink = `https://studybudi.com/&acDEHJkiol=qec/${UserId}`

    // const baseLink = "https://studybudi.com/&acDEHJkiol=qec"
    const baseLink = "https://studybudi.co.uk/#/signup"

    const generatedLink = `${baseLink}/${randomCode}`
    console.log(generatedLink)
    setLink(generatedLink)
    setIsInputDisabled(false)
    console.log("randomCode", randomCode)
    const ReferLinkData = {
      status: 1,
      refer_url: generatedLink,
      referer_code: randomCode,
    }
    dispatch(addReferLinkRequest(ReferLinkData, addReferLinkCallBack))
  }
  const addReferLinkCallBack = response => {
    dispatch(fetchViewReferLinkRequest())
  }
  // const copyToClipboard = async () => {
  //   // Ensure you have permission to write to the clipboard
  //   const permissionStatus = await navigator.permissions.query({
  //     name: "clipboard-write",
  //   })

  //   if (
  //     permissionStatus.state === "granted" ||
  //     permissionStatus.state === "prompt"
  //   ) {
  //     try {
  //       await navigator.clipboard.writeText(inputRef.current.value)
  //       toast.success("Copied Successfully")
  //     } catch (err) {
  //       console.log("copy error", err)
  //       toast.error("Failed to copy text: ", err)
  //     }
  //   } else {
  //     toast.error("Clipboard permission not granted")
  //   }
  // }

  const copyToClipboard = () => {
    const textToCopy = inputRef.current.value

    // Check if the user is on Safari (iOS Safari in particular)
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    if (isSafari) {
      const textArea = document.createElement("textarea")
      textArea.value = textToCopy
      document.body.appendChild(textArea)
      textArea.select()

      try {
        const successful = document.execCommand("copy")
        if (successful) toast.success("Copied Successfully")
        else throw new Error("Failed to copy")
      } catch (err) {
        console.log("copy error", err)
        toast.error("Failed to copy text: ", err)
      }

      document.body.removeChild(textArea)
    } else {
      // For modern browsers:
      navigator.permissions.query({ name: "clipboard-write" }).then(result => {
        if (result.state === "granted" || result.state === "prompt") {
          navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
              toast.success("Copied Successfully")
            })
            .catch(err => {
              console.log("copy error", err)
              toast.error("Failed to copy text: ", err)
            })
        } else {
          toast.error("Clipboard permission not granted")
        }
      })
    }
  }

  return (
    <div className={`main-content${classAlign}`}>
      <MetaTags>
        <title>Account | StudyBudi</title>
      </MetaTags>
      <ToastContainer />
      <div className="page-content">
        <div className="inner-page-content-account">
          <Row
            className="AccordinRow"
            // style={{
            //   marginLeft: "2rem",
            //   marginRight: "2rem",
            //   paddingTop: "30px",
            // }}
          >
            <Card>
              <CardBody style={{ padding: "0px" }}>
                <Row>
                  <Col style={{ padding: "0px" }} lg={12}>
                    {/* <CardTitle className="h4">Accordion</CardTitle>
                    <p className="card-title-desc">
                      Extend the default collapse behavior to create an
                      accordion.
                    </p> */}

                    <div className="accordion" id="accordion">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              {
                                collapsed: !col1,
                              }
                            )}
                            type="button"
                            onClick={t_col1}
                            style={{ cursor: "pointer" }}
                          >
                            ACCOUNT (FREE)
                          </button>
                        </h2>

                        <Collapse
                          isOpen={col1}
                          className="accordion-collapse "
                          style={{
                            borderBottomRightRadius: "15px",
                            borderBottomLeftRadius: "15px",
                          }}
                        >
                          {!upgradeBtn ? (
                            <div
                              className="accordion-body bg-color-acc "
                              style={{
                                borderBottomRightRadius: "15px",
                                borderBottomLeftRadius: "15px",
                              }}
                            >
                              <div className="mb-4 heading-box">
                                <h5 className="font-color-acc ">
                                  STANDARD ACCOUNT (FREE)
                                </h5>
                                <span className="badge-acc">ACTIVE</span>
                              </div>

                              <div className="font-color-acc">
                                <ul className="inline-list">
                                  <li className="inline-li">
                                    ✓ 80 Free Flashcards a Week
                                  </li>
                                  <li className="inline-li">
                                    ✓ Maximum of 2 x Document Input Card
                                    Generation per Week
                                  </li>
                                  <li className="inline-li">
                                    ✓ Maximum of 2 x Manual Input Card
                                    Generation per Week
                                  </li>
                                  <li className="inline-li">
                                    ✓ Unique referral code, every new referral
                                    means 30 added cards for user and friend
                                  </li>
                                </ul>
                              </div>
                              <hr
                                style={{
                                  height: "3px",
                                  backgroundColor: "white",
                                }}
                              />
                              <div
                                className="heading-box mb-5"
                                style={{ marginBottom: "50px !important" }}
                              >
                                {" "}
                                <h5 className="font-color-acc mb-4 ">
                                  PREMIUM ACCOUNT Only £29.99/Year or
                                  £4.99/Month
                                </h5>
                                <span className="badge-acc-disabled">
                                  INACTIVE
                                </span>
                              </div>
                              <div className="font-color-acc">
                                <ul className="inline-list">
                                  <li>✓ 800 Free Flashcards a Week</li>
                                  <li>✓ Ad Free</li>
                                  <li>
                                    ✓ Unlimited Manual Input Card Generation
                                  </li>
                                  <li>
                                    ✓ Unlimited Document Upload Card Generation
                                  </li>
                                  <li>
                                    ✓ Access & Invite to Pre-release Features
                                  </li>
                                  <li>
                                    ✓ Exclusive Offers for Pre-release Features
                                  </li>
                                  <li>
                                    ✓ Unique referral code, every new referral
                                    means 30 added cards for user and friend
                                  </li>
                                </ul>
                              </div>
                              <button
                                onClick={() => {
                                  setUpgradeBtn(!upgradeBtn)
                                }}
                                // onClick={handleSubmit}
                                type="submit"
                                // onClick={nextClick}
                                style={{
                                  cursor: "pointer",
                                  width: "7rem",
                                  height: "2rem",
                                  // backgroundColor: "#EFEFEF",
                                  backgroundColor: "#171c46",
                                  // border: "1px solid #BCBCBC",
                                  color: "#BCBCBC",
                                  borderRadius: "5px",
                                }}
                              >
                                {"Upgrade Now"}
                              </button>
                              {/* <span
                                className="upgrade btn"
                                onClick={() => {
                                  setUpgradeBtn(!upgradeBtn)
                                }}
                                style={{
                                  border: "2px solid black",
                                  backgroundColor: "#171c46!important",
                                  color: "#BCBCBC !important",
                                }}
                              >
                                Upgrade now
                              </span> */}
                            </div>
                          ) : (
                            <div
                              className="accordion-body bg-color-acc fx js-c al-c box-width"
                              style={{
                                borderBottomRightRadius: "15px",
                                borderBottomLeftRadius: "15px",
                              }}
                            >
                              <div className="card-payment">
                                <h5 className="heading-pre">PREMIUM ACCOUNT</h5>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <div>
                                    <h5 className="heading-nor">
                                      Choose your Payment Plan
                                    </h5>
                                  </div>
                                  <div>
                                    <div className="form-check">
                                      <Input
                                        className="form-check-input"
                                        type="radio"
                                        name="subscription"
                                        id="annual"
                                        value="29.99"
                                        onClick={handleSubscriptionChange}
                                        checked={
                                          selectedSubscription === "29.99"
                                        }
                                      />
                                      <label className="form-check-label">
                                        £29.99 Annually
                                      </label>
                                    </div>

                                    <div className="form-check mt-2">
                                      <Input
                                        className="form-check-input"
                                        type="radio"
                                        name="subscription"
                                        id="monthly"
                                        value="4.99"
                                        onClick={handleSubscriptionChange}
                                        checked={
                                          selectedSubscription === "4.99"
                                        }
                                      />
                                      <label className="form-check-label">
                                        £4.99 Monthly
                                      </label>
                                    </div>
                                  </div>
                                  {/* <div>
                                    {subscriptionOptions.map(option => (
                                      <div
                                        key={option.id}
                                        className="form-check"
                                      >
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="subscription"
                                          id={option.id}
                                          value={option.value}
                                          onChange={handleSubscriptionChange}
                                          checked={
                                            selectedSubscription ===
                                            option.value
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={option.id}
                                        >
                                          {option.label}
                                        </label>
                                      </div>
                                    ))}
                                  </div> */}
                                  {/* <div>
                                    <div>
                                      <label>
                                        <input
                                          type="radio"
                                          value="option1"
                                          checked={selectedOption === "option1"}
                                          onClick={handleOptionChange}
                                        />
                                        Option 1
                                      </label>
                                    </div>
                                    <div>
                                      <label>
                                        <input
                                          type="radio"
                                          value="option2"
                                          checked={selectedOption === "option2"}
                                          onClick={handleOptionChange}
                                        />
                                        Option 2
                                      </label>
                                    </div>
                                    <p>Selected Option: {selectedOption}</p>
                                  </div> */}
                                </div>
                                {selectedSubscription ? (
                                  <div style={{ width: "100%" }}>
                                    <div style={{ width: "100%" }}>
                                      {/* {"sjdfhmsdfjsdgfhjsdgfyjsghd"} */}
                                      <hr style={{ margin: "5px 0px" }} />
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <div className="SubTotalDiv">
                                          <h6 style={{ color: "white" }}>
                                            Sub-Total
                                          </h6>
                                          <h6 style={{ color: "white" }}>
                                            {selectedSubscription}
                                          </h6>
                                        </div>
                                      </div>
                                      <hr style={{ margin: "5px 0px" }} />
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <div className="TotalDiv">
                                          <h6 style={{ color: "white" }}>
                                            Total
                                          </h6>
                                          <h6 style={{ color: "white" }}>
                                            {selectedSubscription}
                                          </h6>
                                        </div>
                                      </div>
                                      <hr style={{ margin: "5px 0px" }} />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <StripeCheckout
                                        amount={selectedSubscription}
                                        name="STRIPE_INTEGRATION"
                                        description="Upgrade to a premium account today."
                                        stripeKey="pk_test_51M91jKPBv405lSaIK2QoKf4JFWslJlPsazuLJDrFBWjAlhQ6Eox83aA2ts2umw9jnzMDFZf4GrXKrUQbeqqxRNxU00B0RA1cpY"
                                        currency="GBP"
                                        email={ViewUserDetailData?.email}
                                        billingAddress={false}
                                        token={onToken}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          )}
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              {
                                collapsed: !col2,
                              }
                            )}
                            type="button"
                            onClick={t_col2}
                            style={{ cursor: "pointer" }}
                          >
                            PERSONAL DETAILS
                          </button>
                        </h2>

                        <Collapse isOpen={col2} className="accordion-collapse">
                          {/* <div className="accordion-body bg-color-acc font-color-acc "> */}
                          {/* <div className=" font-color-acc "> */}
                          <Col
                            md="12"
                            //  key={"_col_" + key}
                          >
                            <Card
                              style={{
                                backgroundColor: "#0abfcd ",
                                padding: "0px",
                                margin: "0px",
                              }}
                            >
                              <CardBody
                              // style={{
                              //   backgroundColor: "#0abfcd ",
                              // }}
                              >
                                <div>
                                  <Formik
                                    enableReinitialize
                                    initialValues={initialValues2}
                                    validationSchema={validationSchema2}
                                    onSubmit={userEditHandleSubmit}
                                  >
                                    <Form
                                      id="formuserDetail"
                                      // onSubmit={handleSubmit}
                                      // onClick={handleSubmit}
                                    >
                                      <div className="d-flex">
                                        <div className="me-4">
                                          <div className="mt-4 mt-md-0">
                                            <div
                                              className={`avatar-md rounded-circle  mini-stat-icon `}
                                            >
                                              {/* <span
                                                className="avatar-title rounded-circle "
                                                style={{
                                                  backgroundColor: "#E9F6F7",
                                                  // height: "75px",
                                                  // width: "75px",
                                                  // paddingBottom: "100px",
                                                }}
                                                // style={{ backgroundColor: "white" }}
                                              > */}
                                              <label
                                                htmlFor="fileInput"
                                                style={{
                                                  // border: "2px solid black",
                                                  cursor: "pointer",
                                                  borderRadius: "50%",
                                                  height: "75px",
                                                  width: "75px",
                                                  backgroundColor: "#DCF2F4",
                                                  color: "#C9D6D7",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                {previewImage ||
                                                ViewUserDetailData?.image ? (
                                                  <img
                                                    src={
                                                      previewImage
                                                        ? previewImage
                                                        : `https://studybudi.co.uk${ViewUserDetailData?.image}`
                                                    }
                                                    // alt="Selected Preview"
                                                    style={{
                                                      // marginTop: "4px",
                                                      width: "100%",
                                                      height: "100%",
                                                      objectFit: "cover",
                                                      borderRadius: "100%",
                                                    }}
                                                  />
                                                ) : (
                                                  <>
                                                    Add
                                                    <br />
                                                    Image
                                                  </>
                                                )}
                                                {/* Add
                                                  <br /> Image */}
                                                {/* <img src="/path/to/add-image-icon.png" alt="Add Icon" /> */}
                                              </label>
                                              <input
                                                ref={fileInputRef}
                                                type="file"
                                                id="fileInput"
                                                disabled={isEdit ? false : true}
                                                style={{ display: "none" }}
                                                onChange={handleImageSelect}
                                              />

                                              {/* <img
                                src={}
                                className={`avatar-md rounded-circle  mini-stat-icon `}
                                // alt={report.title}
                                // style={{ width: "20px", height: "20px" }}
                              /> */}
                                              {/* </span> */}
                                            </div>
                                            {/* <img
                            className="rounded-circle avatar-lg"
                            alt="Skote"
                            // src={avatar4}
                          /> */}
                                          </div>
                                        </div>

                                        <div
                                          className="flex-grow-1 userEdit2DIv"
                                          // style={{ letterSpacing: "2px" }}
                                        >
                                          {/* <h5
                                      className="mb-1"
                                      style={{
                                        fontSize: "15px",
                                        color: "white",
                                        fontWeight: "bold",
                                      }}
                                    >
                                     
                                      USERNAME
                                    </h5> */}
                                          {/* <div>
                                      <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                      >
                                        <Form
                                          id="formuserDetail"
                                          // onSubmit={handleSubmit}
                                          // onClick={handleSubmit}
                                        > */}
                                          <div className="">
                                            <div className="UserDetailfields">
                                              <div className="fieldsuserDetail">
                                                {/* <Label className="labelltrClass">Email</Label> */}
                                                <div className="password-lgn1">
                                                  <Field
                                                    name="username"
                                                    className={
                                                      isEdit
                                                        ? "form-control"
                                                        : "form-control-userDetail editDiv"
                                                    }
                                                    placeholder="Enter your name"
                                                    type="text"
                                                    disabled={!isEdit}
                                                  />
                                                </div>
                                                <p className="text-danger error-msg">
                                                  <ErrorMessage name="username" />
                                                </p>
                                              </div>
                                              <div className="fieldsuserDetail">
                                                {/* <Label className="labelltrClass">Email</Label> */}
                                                <div className="password-lgn1">
                                                  <Field
                                                    name="email"
                                                    className={
                                                      // isEdit
                                                      //   ? "form-control"
                                                      //   :
                                                      "form-control-userDetail editDiv"
                                                    }
                                                    placeholder="Enter email address"
                                                    type="email"
                                                    // disabled={!isEdit}
                                                    disabled={true}
                                                  />
                                                  {/* <div className="fieldIcon">
                        <MdEmail />
                      </div> */}
                                                </div>
                                                <p className="text-danger error-msg">
                                                  <ErrorMessage name="email" />
                                                </p>
                                              </div>

                                              <div className="fieldsuserDetail">
                                                {/* <Label className="labelltrClass">Password</Label> */}
                                                <div className="password-lgn1 real-psd">
                                                  <Field
                                                    name="Phone_number"
                                                    // type={
                                                    //   show ? "text" : "password"
                                                    // }
                                                    placeholder="Enter Phone number"
                                                    className={
                                                      isEdit
                                                        ? "form-control"
                                                        : "form-control-userDetail editDiv"
                                                    }
                                                    disabled={!isEdit}
                                                  />
                                                </div>
                                                <p className="text-danger error-msg">
                                                  <ErrorMessage name="Phone_number" />
                                                </p>
                                              </div>
                                              <hr
                                                style={{ margin: "5px 0px" }}
                                              />
                                              <p
                                                style={{
                                                  color: "white",
                                                  fontSize: "16px",
                                                  marginTop: "10px",
                                                }}
                                              >
                                                {"Password"}
                                              </p>
                                              <div className="fieldsuserDetail">
                                                {/* <Label className="labelltrClass">Password</Label> */}
                                                <div className="password-lgn1 real-psd">
                                                  <Field
                                                    name="password"
                                                    type={
                                                      isEditPassword
                                                        ? "text"
                                                        : "password"
                                                    }
                                                    placeholder="Enter password"
                                                    className={
                                                      isEditPassword
                                                        ? "form-control"
                                                        : "form-control-userDetail editDiv"
                                                    }
                                                    disabled={!isEditPassword}
                                                  />
                                                </div>
                                                {/* <p className="text-danger error-msg">
                                                  <ErrorMessage name="password" />
                                                </p> */}
                                              </div>
                                            </div>
                                            <p style={{ cursor: "pointer" }}>
                                              {/* <Link
                                                to="/forgot_password"
                                                className="textfield"
                                              >
                                                Forgot Password?
                                              </Link> */}
                                            </p>
                                            {/* {error && <p>{error}</p>}
              <br /> */}

                                            {/* <div className="submit-btn-lgn">
                                              <button
                                                style={{
                                                  fontWeight: "600",
                                                  fontSize: "0.8rem",
                                                }}
                                                className="loginbtnLgIn"
                                                type="submit"
                                              >
                                                LOG IN
                                              </button>
                                            </div> */}
                                            {/* <p
                  style={{
                    color: "grey",
                    marginTop: "10px",
                  }}
                >
                  {" Didn't have an Account?"}
                  <Link to="/register" className="textfield">
                    Signup
                  </Link>
                </p> */}
                                          </div>
                                          {/* </Form>
                                      </Formik>
                                    </div> */}
                                          {/* <Field
                                      name="email"
                                      className="form-control"
                                      placeholder="Enter email address"
                                      type="email"
                                      // value={values.email}
                                      // onChange={handleChange}
                                      style={{
                                        backgroundColor: "#f1f1f9",
                                        height: "41px",
                                        width: "327px",
                                      }}
                                    /> */}
                                        </div>
                                        <div className="icon-tabs2">
                                          <div>
                                            {isEdit ? (
                                              <div
                                                style={{
                                                  cursor: "pointer",
                                                  zIndex: "999",
                                                  display: "flex",
                                                  marginLeft: "10px",
                                                }}
                                                className="clearfix mt-4 mt-lg-0 float-end"
                                              >
                                                {/* <AiOutlineCheck
                                                  style={{
                                                    marginRight: "5px",
                                                    backgroundColor: "92dee4",
                                                    borderRadius: "50%",
                                                    padding: "3px",
                                                    color: "white",
                                                    fontSize: "25px",
                                                  }}
                                                  type="submit"
                                                  // className={
                                                  //   isAnswer
                                                  //     ? " icon-style-answer"
                                                  //     : " icon-style"
                                                  // }
                                                  onClick={() => {
                                                    // editFLashcardHandler()
                                                    setIsEdit(false)
                                                    // setIsAnswer(false)
                                                  }}
                                                /> */}
                                                <button
                                                  style={{
                                                    marginRight: "5px",

                                                    background:
                                                      "92dee4 !important",
                                                    borderRadius: "50%",

                                                    color: "transparent",

                                                    border: "none", // Removing the default button border
                                                    cursor: "pointer", // Making it look clickable
                                                  }}
                                                  type="submit"
                                                  // onClick={() => {
                                                  //   setIsEdit(false)

                                                  // }}
                                                >
                                                  <AiOutlineCheck
                                                    style={{
                                                      backgroundColor: "92dee4",
                                                      borderRadius: "50%",
                                                      padding: "3px",
                                                      color: "white",
                                                      fontSize: "25px",
                                                    }}
                                                  />
                                                </button>
                                                <AiOutlineClose
                                                  style={{
                                                    marginRight: "5px",
                                                    backgroundColor: "92dee4",
                                                    borderRadius: "50%",
                                                    padding: "3px",
                                                    color: "red",
                                                    fontSize: "25px",
                                                  }}
                                                  // className={
                                                  //   isAnswer
                                                  //     ? "icon-style-answer"
                                                  //     : "icon-style"
                                                  // }
                                                  onClick={() => {
                                                    NotEdituserDetailHandler()

                                                    // setIsAnswer(false)
                                                    // isAnswer
                                                    //   ? setQNA(previousAnswer)
                                                    //   : setQNA(previousQuestion)
                                                  }}
                                                />

                                                {/* <i
                          style={{ fontSize: "40px" }}
                          className="bx bx-comment-check align-middle me-1"
                        />
                        <i
                          style={{ fontSize: "40px" }}
                          className="mdi mdi-close-thick align-middle me-1"
                          onClick={() => {
                            setIsEdit(false)
                          }}
                        /> */}
                                              </div>
                                            ) : (
                                              <div
                                                style={{ cursor: "pointer" }}
                                                className="clearfix mt-4 mt-lg-0 float-end"
                                              >
                                                {/* <GoPencil
                                          // className={
                                          //   isAnswer
                                          //     ? "bx bxs-cog align-middle me-1 icon-style-answer"
                                          //     : "bx bxs-cog align-middle me-1 icon-style"
                                          // }
                                          onClick={() => {
                                            setIsEdit(true)
                                          }}
                                        /> */}
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "5px",
                                                    cursor: "pointer",
                                                    // justifyContent: "flex-end",
                                                  }}
                                                  onClick={() => {
                                                    setIsEdit(true)
                                                  }}
                                                >
                                                  <p
                                                    style={{
                                                      color: "white",
                                                      fontSize: "15px",
                                                    }}
                                                  >
                                                    {"Edit"}
                                                  </p>

                                                  <FaRegEdit
                                                    style={{
                                                      color: "white",
                                                      fontSize: "20px",
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                          <div style={{ marginTop: "130px" }}>
                                            {isEditPassword ? (
                                              <div
                                                style={{
                                                  cursor: "pointer",
                                                  zIndex: "999",
                                                }}
                                                className="clearfix mt-4 mt-lg-0 float-end"
                                              >
                                                {/* <AiOutlineCheck
                                                  style={{
                                                    marginRight: "5px",
                                                    backgroundColor: "92dee4",
                                                    borderRadius: "50%",
                                                    padding: "3px",
                                                    color: "white",
                                                    fontSize: "25px",
                                                  }}
                                                  type="submit"
                                                  // className={
                                                  //   isAnswer
                                                  //     ? " icon-style-answer"
                                                  //     : " icon-style"
                                                  // }
                                                  onClick={() => {
                                                    // editFLashcardHandler()
                                                    setIsEditPassword(false)
                                                    // setIsAnswer(false)
                                                  }}
                                                /> */}
                                                <button
                                                  style={{
                                                    marginRight: "5px",

                                                    background:
                                                      "92dee4 !important",
                                                    borderRadius: "50%",

                                                    color: "transparent",

                                                    border: "none", // Removing the default button border
                                                    cursor: "pointer", // Making it look clickable
                                                  }}
                                                  type="submit"
                                                  // onClick={() => {
                                                  //   setIsEditPassword(false)
                                                  // }}
                                                >
                                                  <AiOutlineCheck
                                                    style={{
                                                      backgroundColor: "92dee4",
                                                      borderRadius: "50%",
                                                      padding: "3px",
                                                      color: "white",
                                                      fontSize: "25px",
                                                    }}
                                                  />
                                                </button>

                                                <AiOutlineClose
                                                  style={{
                                                    marginRight: "5px",
                                                    backgroundColor: "92dee4",
                                                    borderRadius: "50%",
                                                    padding: "3px",
                                                    color: "red",
                                                    fontSize: "25px",
                                                  }}
                                                  // className={
                                                  //   isAnswer
                                                  //     ? "icon-style-answer"
                                                  //     : "icon-style"
                                                  // }
                                                  onClick={() => {
                                                    setIsEditPassword(false)
                                                  }}
                                                />

                                                {/* <i
                          style={{ fontSize: "40px" }}
                          className="bx bx-comment-check align-middle me-1"
                        />
                        <i
                          style={{ fontSize: "40px" }}
                          className="mdi mdi-close-thick align-middle me-1"
                          onClick={() => {
                            setIsEdit(false)
                          }}
                        /> */}
                                              </div>
                                            ) : (
                                              <div
                                                style={{ cursor: "pointer" }}
                                                className="clearfix mt-4 mt-lg-0 float-end"
                                              >
                                                {/* <GoPencil
                                          // className={
                                          //   isAnswer
                                          //     ? "bx bxs-cog align-middle me-1 icon-style-answer"
                                          //     : "bx bxs-cog align-middle me-1 icon-style"
                                          // }
                                          onClick={() => {
                                            setIsEdit(true)
                                          }}
                                        /> */}
                                                {/* password edit button */}
                                                {/* <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "5px",
                                                    cursor: "pointer",
                                                    // justifyContent: "flex-end",
                                                  }}
                                                  onClick={() => {
                                                    setIsEditPassword(true)
                                                  }}
                                                >
                                                  <p
                                                    style={{
                                                      color: "white",
                                                      fontSize: "15px",
                                                    }}
                                                  >
                                                    {"Edit"}
                                                  </p>

                                                  <FaRegEdit
                                                    style={{
                                                      color: "white",
                                                      fontSize: "20px",
                                                    }}
                                                  />
                                                </div> */}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </Form>
                                  </Formik>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          {/* <strong className="font-color-acc ">
                                This is the second item&apos;s accordion body.
                              </strong>{" "}
                              It is hidden by default, until the collapse plugin
                              adds the appropriate classes that we use to style
                              each element. These classes control the overall
                              appearance, as well as the showing and hiding via
                              CSS transitions. You can modify any of this with
                              custom CSS or overriding our default variables.
                              It&apos;s also worth noting that just about any
                              HTML can go within the{" "}
                              <code>.accordion-body</code>, though the
                              transition does limit overflow. */}
                          {/* </div> */}
                          {/* </div> */}
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              {
                                collapsed: !col3,
                              }
                            )}
                            type="button"
                            onClick={t_col3}
                            style={{ cursor: "pointer" }}
                          >
                            COURSE DETAILS
                          </button>
                        </h2>
                        <Collapse isOpen={col3} className="accordion-collapse">
                          <div
                            className="accordion-body"
                            style={{ padding: "0px" }}
                          >
                            <div className="text-muted">
                              {degreeUpdate ? (
                                <div>
                                  <div className="courseform-Accordine">
                                    {/* <div
                                      style={{
                                        width: "150px",
                                        height: "50px",
                                        backgroundColor: "#0BAFBC",
                                        borderRadius: "40px",
                                        display: "flex",
                                        alignItems: "center",
                                        paddingLeft: "30px",
                                        color: "white",
                                        marginBottom: "20px",
                                      }}
                                    >
                                      Degree
                                    </div> */}
                                    <div
                                      style={{
                                        width: "90%",
                                        // border: "2px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "150px",
                                          height: "50px",
                                          backgroundColor: "#0BAFBC",
                                          borderRadius: "40px",
                                          display: "flex",
                                          alignItems: "center",
                                          paddingLeft: "30px",
                                          color: "white",
                                        }}
                                      >
                                        Degree
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: "5px",
                                          cursor: "pointer",
                                          // justifyContent: "flex-end",
                                        }}
                                        onClick={NotupdateDegreeHandle}
                                      >
                                        <AiOutlineClose
                                          style={{
                                            marginRight: "5px",
                                            backgroundColor: "92dee4",
                                            borderRadius: "50%",
                                            padding: "3px",
                                            color: "red",
                                            fontSize: "25px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <CreatableSelect
                                      value={degreeOption}
                                      onChange={DegreeChange}
                                      options={DegreeOptions}
                                      isSearchable={true}
                                      className="coursedropdown"
                                      classNamePrefix="my-select"
                                      placeholder="Degree Level eg.BSc"
                                    />
                                    <p className="textdanger1">
                                      {props.t(degreeOptionError)}
                                    </p>
                                    {/* <Select
              className="basic-single"
              classNamePrefix="select"
              // defaultValue={colourOptions[0]}
              // name="color"
              options={options}
            /> */}
                                    {/* <select
              value={sortOption}
              onChange={handleSortChange}
              className="coursedropdown"
              placeholder="course"
            >
              <option value="" disabled selected>
                {props.t("Select Course")}
              </option>

              <option value="A-Z">{props.t(Constant.az)}</option>
              <option value="Z-A">{props.t(Constant.za)}</option>
              <option value="Newest">{props.t(Constant.newest)}</option>
              <option value="Oldest">{props.t(Constant.oldest)}</option>
            </select> */}
                                    <Formik
                                      initialValues={initialValues}
                                      validationSchema={validationSchema}
                                      onSubmit={handleSubmit}
                                    >
                                      {({ touched }) => (
                                        <Form>
                                          <CreatableSelect
                                            value={courseNameOption}
                                            onChange={CourseNameChange}
                                            options={CourseOptions}
                                            isSearchable={true}
                                            className="coursedropdown"
                                            classNamePrefix="my-select"
                                            placeholder="Course Name"
                                          />
                                          <p className="textdanger1">
                                            {props.t(courseNameOptionError)}
                                          </p>

                                          {/* <div className="inputdiv">
                    <Field
                      className="form-control"
                      style={{ height: "50px", backgroundColor: "white" }}
                      type="text"
                      name="courseName"
                      placeholder={"Course Name"}
                      maxLength={50}
                    />
                    <p className="textdanger1">
                      <ErrorMessage name={props.t("courseName")} />
                    </p>
                  </div> */}

                                          <div className="inputdiv">
                                            <Field
                                              className="form-control"
                                              style={{ height: "50px" }}
                                              type="text"
                                              name="courseYear"
                                              placeholder={"Course Year"}
                                            />

                                            <p className="textdanger1">
                                              <ErrorMessage
                                                name={props.t("courseYear")}
                                              />
                                            </p>
                                          </div>
                                          <CreatableSelect
                                            value={universityNameOption}
                                            onChange={UniversityNameChange}
                                            options={UniversityOptions}
                                            isSearchable={true}
                                            className="coursedropdown"
                                            classNamePrefix="my-select"
                                            placeholder="University Name"
                                          />
                                          <p className="textdanger1">
                                            {props.t(universityNameOptionError)}
                                          </p>
                                          {/* <div className="inputdiv">
                    <Field
                      className="form-control"
                      style={{ height: "50px" }}
                      type="text"
                      name="universityName"
                      placeholder={"University Name"}
                      maxLength={50}
                    />
                    <p className="textdanger1">
                    
                      <ErrorMessage name="universityName" />
                    </p>
                  </div> */}
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-end",
                                              justifyContent: "flex-end",
                                              paddingTop: "10px",
                                              paddingBottom: "10px",
                                              paddingRight: "15px",
                                              color: "white",
                                              // border: "2px solid black",
                                            }}
                                          >
                                            <button
                                              // onClick={handleSubmit}
                                              type="submit"
                                              // onClick={nextClick}
                                              style={{
                                                cursor: "pointer",
                                                width: "5rem",
                                                height: "2rem",
                                                backgroundColor: "#EFEFEF",
                                                border: "1px solid #BCBCBC",
                                                color: "#BCBCBC",
                                                borderRadius: "5px",
                                              }}
                                            >
                                              {"Next "}
                                            </button>
                                          </div>
                                          {/* <div className="inlineFieldsBtn">
                    <button
                      className={`cancelbtn${classAlign}`}
                      type="button"
                      onClick={() => cancelHandler()}
                    >
                      {props.t(Constant.cancel)}
                    </button>
                    <button className={`submitbtn${classAlign}`} type="submit">
                      {props.t(Constant.submit)}
                    </button>
                  </div> */}
                                        </Form>
                                      )}
                                    </Formik>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div className="courseform-Accordine">
                                    <div
                                      style={{
                                        width: "90%",
                                        // border: "2px solid black",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "150px",
                                          height: "50px",
                                          backgroundColor: "#0BAFBC",
                                          borderRadius: "40px",
                                          display: "flex",
                                          alignItems: "center",
                                          paddingLeft: "30px",
                                          color: "white",
                                        }}
                                      >
                                        Degree
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: "5px",
                                          cursor: "pointer",
                                          // justifyContent: "flex-end",
                                        }}
                                        onClick={updateDegreeHandle}
                                      >
                                        <p
                                          style={{
                                            color: "white",
                                            fontSize: "15px",
                                          }}
                                        >
                                          {"Edit"}
                                        </p>

                                        <FaRegEdit
                                          style={{
                                            color: "white",
                                            fontSize: "20px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="degreeDetailDiv">
                                      <div className="degreeDetailText">
                                        <h6 className="degreeDetaillevel">
                                          {ViewCourseData?.Degree_level}
                                        </h6>
                                        <h6
                                          style={{
                                            color: "#CAF1F4",
                                            letterSpacing: "2px",
                                          }}
                                        >
                                          {ViewCourseData?.Course_name}
                                        </h6>
                                        <h6
                                          style={{
                                            color: "#65DEE8",
                                            letterSpacing: "2px",
                                          }}
                                        >
                                          {ViewCourseData?.University_name}
                                        </h6>
                                      </div>
                                      {ViewCourseData?.Course_year ? (
                                        <div className="degreeDetailYear">
                                          {`YEAR  ${ViewCourseData?.Course_year}`}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {/* <strong className="text-dark">
                                This is the third item&apos;s accordion body.
                              </strong>{" "}
                              It is hidden by default, until the collapse plugin
                              adds the appropriate classes that we use to style
                              each element. These classes control the overall
                              appearance, as well as the showing and hiding via
                              CSS transitions. You can modify any of this with
                              custom CSS or overriding our default variables.
                              It&apos;s also worth noting that just about any
                              HTML can go within the{" "}
                              <code>.accordion-body</code>, though the
                              transition does limit overflow. */}
                            </div>
                          </div>
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              {
                                collapsed: !col4,
                              }
                            )}
                            type="button"
                            onClick={t_col4}
                            style={{ cursor: "pointer" }}
                          >
                            REFER A FRIEND
                          </button>
                        </h2>
                        <Collapse isOpen={col4} className="accordion-collapse">
                          <Col
                            md="12"
                            //  key={"_col_" + key}
                          >
                            <Card
                              style={{
                                backgroundColor: "#0abfcd ",
                                padding: "0px",
                                margin: "0px",
                              }}
                            >
                              <CardBody
                              // style={{
                              //   backgroundColor: "#0abfcd ",
                              // }}
                              >
                                <div>
                                  <div
                                    style={{
                                      margin: "1vw",
                                      color: "white",
                                      width: "80%",
                                    }}
                                  >
                                    Generate a free shareable referral link,
                                    each sign up under your link generates 20
                                    flashcards for standard accounts. The
                                    highest referring premium users will have
                                    exclusive offers to pre-release features.
                                  </div>
                                  <div
                                  // style={{
                                  //   border: "2px solid red",
                                  //   padding: "4px",
                                  // }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        borderRadius: "5px",
                                        // border: "2px solid black",
                                        marginLeft: "1vw",
                                        width: "85%",
                                        padding: "2px",
                                        backgroundColor: "white",
                                      }}
                                    >
                                      <button
                                        onClick={generateLink}
                                        disabled={
                                          ViewReferLinkData.length !== 0
                                            ? true
                                            : false
                                        }
                                        style={{
                                          fontSize: "10px",
                                          width: "20%",
                                          border: "1px solid",
                                          backgroundColor: "#171c46",

                                          color: "#BCBCBC",

                                          borderRadius: "5px",
                                        }}
                                      >
                                        Generate Link
                                      </button>

                                      <input
                                        style={{
                                          width: "95%",
                                          backgroundColor: "white",
                                          border: "none",
                                          paddingLeft: "5px",
                                        }}
                                        type="text"
                                        ref={inputRef}
                                        value={
                                          ViewReferLinkData.length !== 0
                                            ? ViewReferLinkData?.refer_url
                                            : link
                                        }
                                        disabled={true}
                                        onChange={e => setLink(e.target.value)}
                                      />
                                      <FaRegCopy
                                        onClick={copyToClipboard}
                                        style={{
                                          alignSelf: "center",
                                          marginRight: "5px",
                                          cursor: "pointer",
                                          color: "grey",
                                        }}
                                      />
                                      {/* <button onClick={copyToClipboard}>
                                        Copy
                                      </button> */}
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Collapse>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </div>
      </div>
    </div>
  )
}
Account.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Account)
