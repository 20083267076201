import {
  FETCH_VIEW_RECENT_MODULE_FAILURE,
  FETCH_VIEW_RECENT_MODULE_REQUEST,
  FETCH_VIEW_RECENT_MODULE_SUCCESS,
} from "./actionType"

export const fetchViewRecentModuleRequest = () => ({
  type: FETCH_VIEW_RECENT_MODULE_REQUEST,
})

export const fetchViewRecentModuleSuccess = ViewRecentModule => ({
  type: FETCH_VIEW_RECENT_MODULE_SUCCESS,
  payload: ViewRecentModule,
})

export const fetchViewRecentModuleFailure = error => ({
  type: FETCH_VIEW_RECENT_MODULE_FAILURE,
  payload: error,
})
