import {
  ADD_MODULE_SUCCESS,
  ADD_MODULE_FAILURE,
  ADD_MODULE_REQUEST,
} from "./actionType"

const initialState = {
  addModuleDataSaga: [],
  loading: false,
  error: null,
}

const addModuleReducer = (state = initialState, action) => {
  console.log("Accccction=>", action)
  switch (action.type) {
    case ADD_MODULE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case ADD_MODULE_SUCCESS:
      return {
        ...state,
        addModuleDataSaga: action.payload.data,
        loading: false,
        error: null,
      }
    case ADD_MODULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default addModuleReducer
