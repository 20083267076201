import { put, call, takeEvery, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { toast } from "react-toastify"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"
import { EDIT_FLASHCARD_REQUEST } from "./actionType"
import { editFlashcardFailure, editFlashcardSuccess } from "./action"

function* editFlashcard(action) {
  const { data, id, editFLashcardCallBAck } = action.payload
  try {
    const response = yield call(
      axios.put,
      `${BASE_URL}/api/cuecard/${id}/update/`,

      data,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(editFlashcardSuccess(response.data))

    editFLashcardCallBAck(response.data)
    // toast.success(`${success}`)
  } catch (error) {
    console.log("err====>", error.response)
    const loginError = error.response
    yield put(editFlashcardFailure(error.message))
    // toast.error()
  }
}

export default function* editFlashcardSaga() {
  yield takeLatest(EDIT_FLASHCARD_REQUEST, editFlashcard)
}
