import { put, call, takeEvery, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { toast } from "react-toastify"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"
import { RESTART_BUTTON_REQUEST } from "./actionType"
import { restartButtonFailure, restartButtonSuccess } from "./action"

function* restartButton(action) {
  const { ModuleID, TopicID, PPTID, restartButtonCallBack } = action.payload
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/api/reset-recall-status/`,
      {
        module_detail_id: ModuleID,
        topic_id: TopicID,
        topic_add_id: PPTID,
      },
      //   {
      //     module_detail_id: ModuleID,
      //     topic_id: TopicID,
      //     topic_add: PPTID,
      //   },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    restartButtonCallBack(response)
    yield put(restartButtonSuccess(response.data))

    console.log("addC-->", response.data, action.payload)
  } catch (error) {
    console.log("err====>", error.response)
    const loginError = error.response
    yield put(restartButtonFailure(error.message))
    // toast.error()
  }
}

export default function* restartButtonSaga() {
  yield takeLatest(RESTART_BUTTON_REQUEST, restartButton)
}
