import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"

import { Label } from "reactstrap"
// import "../../App.css"
import { useHistory } from "react-router-dom"

//redux
import { useSelector, useDispatch } from "react-redux"
// Formik validation
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.png"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import naterpurple_logo from "../../pages/Components/Assets/Images/NaterPurple_logo.png"
import new_nater_logo from "../../pages/Components/Assets/Images/new_nater_logo.jpg"
import naterlogin_logo from "../../pages/Components/Assets/Images/naterlogin_logo.png"
import tweetnow_logo from "../../pages/Components/Assets/Images/tweetnowlogo.png"
import StudyBudiLogo from "../../pages/Components/Assets/Images/StudyBudiLogo.png"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import "../../App.css"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import { MdEmail } from "react-icons/md"
import { Link } from "react-router-dom"
//Import config
import { withTranslation } from "react-i18next"
// import { GoogleLogin } from "react-google-login"
import { GoogleLogin } from "@react-oauth/google"
// import { signupRequest } from "../../store/actions"
import { registerUserRequest } from "store/actions"
import { fontSize } from "@mui/system"
import { fetchGoogleDetailRequest } from "../../store/actions"
const SignUP = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { number } = useParams()
  console.log("number", number)
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  const initialValues = {
    email: "",
    Phone_number: "",
    password: "",
    confirm_password: "",
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i,
        "Invalid email"
      )
      .required("Email is required!"),
    Phone_number: Yup.string()
      .matches(
        /^[0-9]{10,15}$/,
        "Phone number must be between 10 to 15 digits long and contain only numbers."
      )
      .required(props.t("Phone number is required!")),
    // Phone_number: Yup.string().required("Phone Number is required!"),
    // Phone_number: Yup.string()
    //   // .matches(
    //   //   /^[0-9]{1,11}$/,
    //   //   " It must be up to 11 digits long and contain only numbers."
    //   // )
    //   .matches(/^[0-9]{1,15}$/, " It contain only numbers.")
    //   .required(props.t("Phone number is required!")),

    password: Yup.string()
      .required("Password is required!")
      .min(8, "Password must be at least 8 characters long.")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain at least one letter, one number, and one special character."
      ),
    confirm_password: Yup.string()
      .required("Confirm Password is required!")
      .min(8, "Password must be at least 8 characters long.")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain at least one letter, one number, and one special character."
      ),
  })

  const formSubmitSignup = values => {
    console.log("value", values)

    dispatch(
      registerUserRequest(
        values.email,
        values.Phone_number,
        values.password,
        values.confirm_password,
        number,
        successCallBack
      )
    )
    // setTimeout(() => {
    //   // role === "admin"
    //   //   ? history.push("/dashboard")
    //   //   : history.push("/dashboardvalet")
    //   if (role === "admin") {
    //     history.push("/dashboard")
    //   } else if (role === "company") {
    //     history.push("/dashboardvalet")
    //   } else {
    //     history.push("/")
    //   }
    // }, 2000)
    // Window.reload()
  }

  const successCallBack = response => {
    // setTimeout(() => {
    //   toast.success("Register Successfully")
    //   // Window.reload()
    // }, 2000)

    Window.reload()

    console.log("signupresponse", response)
  }
  const successCallBack2 = response => {
    // console.log("pushCourse")
    // history.push("/course")
    Window.reload()
  }
  const responseMessage = response => {
    console.log("google response", response)
    dispatch(fetchGoogleDetailRequest(response.credential, successCallBack2))
  }
  const errorMessage = error => {
    console.log(error)
  }
  const responseGoogle = response => {
    console.log("google response", response)
  }
  const toggleButton = () => {
    setShow(prevState => !prevState)
  }
  const toggleButton1 = () => {
    setShow1(prevState => !prevState)
  }

  // const loginnevigate = () => {
  //   history.push("/valet_drivers")
  // }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>SignUp | StudyBudi</title>
      </MetaTags>
      <ToastContainer autoClose={2000} />
      <div className="SignupPageBackground">
        <div className="AppLogin">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={formSubmitSignup}
          >
            <Form
              id="formLogin"
              // onSubmit={handleSubmit}
              // onClick={handleSubmit}
            >
              <img
                src={StudyBudiLogo}
                style={{
                  width: "120px",
                  height: "120px",
                  marginBottom: "20px",
                }}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <GoogleLogin
                  shape="pill"
                  width="327px"
                  text="continue_with"
                  logo_alignment="center"
                  onSuccess={responseMessage}
                  onError={errorMessage}
                />
              </div>

              {/* <div>
                <GoogleLogin
                  className="googlelogin"
                  // clientId="<YOUR_GOOGLE_CLIENT_ID>"
                  clientId="1021611278798-d0joa6kier1itot2icu6phh7fhtt37ag.apps.googleusercontent.com"
                  buttonText="Continue with Google"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={"single_host_origin"}
                />
              </div> */}

              <div style={{ marginBottom: "5px", marginTop: "5px" }}>
                - OR -
              </div>

              <div className="">
                <div className="loginfields">
                  <div className="fieldsSignup">
                    {/* <Label className="labelltrClass">Email</Label> */}
                    <div className="password-lgn">
                      <Field
                        name="email"
                        className="form-control"
                        placeholder="Enter email address"
                        type="email"
                        // value={values.email}
                        // onChange={handleChange}
                        style={{
                          backgroundColor: "#f1f1f9",
                          height: "41px",
                          width: "327px",
                        }}
                      />
                      {/* <div className="fieldIcon">
                        <MdEmail />
                      </div> */}
                    </div>
                    <p className="text-danger error-msg">
                      <ErrorMessage name="email" />
                    </p>
                  </div>
                  <div className="fieldsSignup">
                    {/* <Label className="labelltrClass">Email</Label> */}
                    <div className="password-lgn">
                      <Field
                        name="Phone_number"
                        className="form-control"
                        placeholder="Enter phone number"
                        type="text"
                        maxLength={15}
                        // value={values.email}
                        // onChange={handleChange}
                        style={{
                          backgroundColor: "#f1f1f9",
                          height: "41px",
                          width: "327px",
                        }}
                      />
                      {/* <div className="fieldIcon">
                        <MdEmail />
                      </div> */}
                    </div>
                    <p className="text-danger error-msg">
                      <ErrorMessage name="Phone_number" />
                    </p>
                  </div>
                  <div className="fieldsSignup">
                    {/* <Label className="labelltrClass">Password</Label> */}
                    <div className="password-lgn real-psd">
                      <Field
                        name="password"
                        type={show ? "text" : "password"}
                        placeholder="Enter password"
                        className="form-control"
                        // value={values.password}
                        // onChange={handleChange}
                        style={{
                          backgroundColor: "#f1f1f9",
                          height: "41px",
                          width: "327px",
                        }}
                      />
                      <button
                        type="button"
                        className="passwordBtn"
                        onClick={toggleButton}
                        // style={{ position: "absolute" }}
                      >
                        {show ? <AiFillEye /> : <AiFillEyeInvisible />}
                      </button>
                    </div>
                    <p className="text-danger error-msg">
                      <ErrorMessage name="password" />
                    </p>
                  </div>

                  <div className="fieldsSignup">
                    {/* <Label className="labelltrClass">Password</Label> */}
                    <div className="password-lgn real-psd">
                      <Field
                        name="confirm_password"
                        type={show1 ? "text" : "password"}
                        placeholder="Enter confirm password"
                        className="form-control"
                        // value={values.password}
                        // onChange={handleChange}
                        style={{
                          backgroundColor: "#f1f1f9",
                          height: "41px",
                          width: "327px",
                        }}
                      />
                      <button
                        type="button"
                        className="passwordBtn"
                        onClick={toggleButton1}
                        // style={{ position: "absolute" }}
                      >
                        {show1 ? <AiFillEye /> : <AiFillEyeInvisible />}
                      </button>
                    </div>
                    <p className="text-danger error-msg">
                      <ErrorMessage name="confirm_password" />
                    </p>
                  </div>
                </div>

                <div className="submit-btn-lgn">
                  <button
                    style={{ fontWeight: "600", fontSize: "0.8rem" }}
                    className="signupbtnSnUp"
                    type="submit"
                  >
                    SIGN UP
                  </button>
                </div>
                {/* <p
                  style={{
                    color: "grey",
                    marginTop: "10px",
                  }}
                >
                  {" Didn't have an Account?"}
                  <Link to="/register" className="textfield">
                    Signup
                  </Link>
                </p> */}
              </div>
            </Form>
          </Formik>
        </div>
      </div>

      {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                      </div> */}

      {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div> */}

      {/* <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div> */}

      {/* <ToastContainer /> */}
    </React.Fragment>
  )
}

SignUP.propTypes = {
  // history: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(SignUP)
