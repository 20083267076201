import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import StudyBudiLogo from "../../pages/Components/Assets/Images/StudyBudiLogo.png"
import "../../App.css"
import { withTranslation } from "react-i18next"
import { ForgotPasswordRequest } from "store/actions"

const ForgotPassword = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const initialValues = {
    email: "",
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i,
        "Invalid email"
      )
      .required("Email is required!"),
  })

  const formSubmit = values => {
    console.log("emailvalue", values)
    dispatch(ForgotPasswordRequest(values.email))
  }

  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>forgot Password | StudyBudi</title>
      </MetaTags>
      <ToastContainer autoClose={2000} />
      <div className="welcomePageBackground" style={{ overflowX: "hidden" }}>
        <div className="AppLogin">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={formSubmit}
          >
            <Form
              id="formLogin"
              // onSubmit={handleSubmit}
              // onClick={handleSubmit}
            >
              <img
                src={StudyBudiLogo}
                style={{
                  width: "180px",
                  height: "180px",
                  marginBottom: "20px",
                }}
              />

              <div className="">
                <div className="loginfields">
                  <div className="fieldsSignup">
                    {/* <Label className="labelltrClass">Email</Label> */}
                    <div className="password-lgn1">
                      <Field
                        name="email"
                        className="form-control"
                        placeholder="Enter email address"
                        type="email"
                        // value={values.email}
                        // onChange={handleChange}
                        style={{
                          backgroundColor: "#f1f1f9",
                          height: "41px",
                          width: "327px",
                        }}
                      />
                      {/* <div className="fieldIcon">
                        <MdEmail />
                      </div> */}
                    </div>
                    <p className="text-danger error-msg">
                      <ErrorMessage name="email" />
                    </p>
                  </div>
                </div>

                <div className="submit-btn-lgn" style={{ marginTop: "20px" }}>
                  <button
                    style={{ fontWeight: "600", fontSize: "0.8rem" }}
                    className="loginbtnLgIn"
                    type="submit"
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </React.Fragment>
  )
}

ForgotPassword.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ForgotPassword)
