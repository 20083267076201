import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { connect } from "react-redux"
import { Row, Col } from "reactstrap"
import { Link } from "react-router-dom"

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import megamenuImg from "../../assets/images/megamenu-img.png"

// import images
import github from "../../assets/images/brands/github.png"
import bitbucket from "../../assets/images/brands/bitbucket.png"
import dribbble from "../../assets/images/brands/dribbble.png"
import dropbox from "../../assets/images/brands/dropbox.png"
import mail_chimp from "../../assets/images/brands/mail_chimp.png"
import slack from "../../assets/images/brands/slack.png"

import logo from "../../assets/images/logo.svg"
import logoLightSvg from "../../assets/images/logo-light.svg"
import sidebar_logo from "../../pages/Components/Assets/Images/aac_logo.png"
import valet_logo from "../../pages/Components/Assets/Images/valet_logo.png"
import nater_logo from "../../pages/Components/Assets/Images/nater_logo.png"
import naterpurple_logo from "../../pages/Components/Assets/Images/NaterPurple_logo.png"
import new_nater_logo from "../../pages/Components/Assets/Images/new_nater_logo.jpg"
import naterlogin_logo from "../../pages/Components/Assets/Images/naterlogin_logo.png"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import { GiCancel } from "react-icons/gi"

const Header = props => {
  const [search, setsearch] = useState(false)
  const [megaMenu, setmegaMenu] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)
  const [crossBtn, setCrossBtn] = useState(false)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
      setCrossBtn(true)
      // body.classList.toggle("vertical-collpsed")
    } else {
      // body.classList.toggle("vertical-collpsed")
      // body.classList.toggle("sidebar-enable", "remove")
    }
  }
  console.log("crossBtn", crossBtn)
  // window.addEventListener("resize", function (event) {
  //   var body = document.body
  //   if (document.body.clientWidth <= 998) {
  //     body.classList.add("sidebar-enable")
  //     // body.classList.add("vertical-collpsed")
  //   } else {
  //     // body.classList.remove("sidebar-enable")
  //     // body.classList.remove("vertical-collpsed")
  //   }
  // })
  // useEffect(() => {
  //   var body = document.body
  //   if (window.screen.width <= 998) {
  //     body.classList.toggle("sidebar-enable")
  //     // body.classList.toggle("vertical-collpsed", "remove")
  //   } else {
  //   }
  // }, [])
  useEffect(() => {
    var body = document.body
    if (window.screen.width <= 992) {
      body.classList.remove("sidebar-enable")
      // body.classList.toggle("vertical-collpsed", "remove")
    }
  }, [])

  // new code
  // this useEffect for close sidebar on click anywhere on screen
  useEffect(() => {
    // Define the click handler
    const handleClick = event => {
      var body = document.body

      if (window.screen.width <= 992) {
        body.classList.remove("sidebar-enable")
        console.log("sidebar close")
        setCrossBtn(false)
        // Uncomment the below line if you want to toggle the "vertical-collapsed" class
        // body.classList.toggle("vertical-collpsed");
      }
    }

    // Attach the event listener
    document.addEventListener("click", handleClick)

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClick)
    }
  }, [])

  const screenWidth = screen.width
  console.log("screenWidth", screenWidth)
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <React.Fragment>
      {screenWidth < 999 ? (
        <header id="page-topbar" className={`${classAlign}`}>
          <div className="navbar-header">
            <div className="d-flex">
              {/* <div className="navbar-brand-box d-lg-none d-md-block">
                <div className="logo logo-dark">
                  <span className="logo-sm">
                    <img
                      src={naterlogin_logo}
                      alt=""
                      height="40"
                      style={{ alignItems: "center" }}
                    />
                  </span>
                </div>

                <div className="logo logo-light">
                  <span className="logo-sm">
                    <img src={naterlogin_logo} alt="" height="40" width="40" />
                  </span>
                </div>
              </div> */}

              {crossBtn ? (
                <div
                  className="btn btn-sm px-3 font-size-16 header-item "
                  id="vertical-menu-btn"
                  style={{
                    color: "white",
                    marginTop: "30px",
                  }}
                >
                  <GiCancel style={{ fontSize: "25px" }} />
                </div>
              ) : (
                <button
                  type="button"
                  onClick={e => {
                    e.stopPropagation() // This prevents the click event from bubbling up(prevent from close sidebar on click anywhere on screen)
                    tToggle()
                  }}
                  className="btn btn-sm px-3 font-size-16 header-item "
                  id="vertical-menu-btn"
                  style={{ color: "white" }}
                >
                  <i className="fa fa-fw fa-bars" />
                </button>
              )}
            </div>
            <div className="d-flex">
              <div className="dropdown d-inline-block d-lg-none ms-2">
                <div
                  className={
                    search
                      ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                      : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  }
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              {/* <LanguageDropdown /> */}

              {/* <NotificationDropdown /> */}
              <ProfileMenu />

              <div
                onClick={() => {
                  props.showRightSidebarAction(!props.showRightSidebar)
                }}
                className="dropdown d-inline-block"
              ></div>
            </div>
          </div>
        </header>
      ) : (
        <></>
      )}
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
