import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//E-commerce
import ecommerce from "./e-commerce/reducer"

//Calendar
import calendar from "./calendar/reducer"

//chat
import chat from "./chat/reducer"

//crypto
import crypto from "./crypto/reducer"

//invoices
import invoices from "./invoices/reducer"

//projects
import projects from "./projects/reducer"

//tasks
import tasks from "./tasks/reducer"

//contacts
import contacts from "./contacts/reducer"

//mails
import mails from "./mails/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer"

//valet--------------------------------------------------
//1) Authentication :-
// Login
import authReducer from "./Valet/Authentication/reducer"

//2) SIGNUPAuth
// SIGNUP
import SignupReducer from "./Valet/Registration/reducer"
//3) gooleLogin or SIgnup
import fetchGoogleDetailReducer from "./Valet/GoogleLogin/reducers"
//4) USER
//Course:-
//AddCourse
import addCourseReducer from "./Valet/User/Course/AddCourse/reducer"
//Viewcourse
import fetchViewCourseReducer from "./Valet/User/Course/ViewCourse/reducer"
//Viewcourse
import fetchViewModuleReducer from "./Valet/User/Course/ViewModule/reducer"
// edit course
import editCourseReducer from "./Valet/User/Course/EditCourse/reducer"
//AddModule
import addModuleReducer from "./Valet/User/Course/AddModules/reducer"
// view recent Module
import fetchViewRecentModuleReducer from "./Valet/User/Course/ViewRecentModule/reducer"
//TOpic Detail
import fetchTopicDetailReducer from "./Valet/User/Course/TopicDetail/reducer"
// View Topic Detail
import fetchViewTopicDetailReducer from "./Valet/User/Course/ViewTopicDetail/reducer"
//ADD Topic Name
import addTopicNameReducer from "./Valet/User/Course/AddTopicName/reducer"
// singleModuleDetail
import fetchSingleModuleDetailReducer from "./Valet/User/Course/SingleModule/reducer"
// flashcard list
import fetchViewFlashcardReducer from "./Valet/User/Course/FlashCardList/reducer"
// restart button
import restartButtonReducer from "./Valet/User/Course/RestartButton/reducer"
// single flashcard
import fetchSingleFlashcardDetailReducer from "./Valet/User/Course/SingleFlashcard/reducer"
// add flashcard button
import addFlashcardButtonReducer from "./Valet/User/Course/AddFlashcardButton/reducer"
// view flashcard button
import fetchViewFlashcardButtonReducer from "./Valet/User/Course/ViewFlashcardButton/reducer"
// add fav module
import FavModuleReducer from "./Valet/User/Course/FavModule/reducer"
// add fav topic
import FavTopicReducer from "./Valet/User/Course/FavTopic/reducer"
// edit flashcard
import editFlashcardReducer from "./Valet/User/Course/EditFlashcard/reducer"
// view lesson card
import fetchViewLessoncardDetailReducer from "./Valet/User/Course/ViewLessoncard/reducer"
// add fav lessoncard
import FavLessoncardReducer from "./Valet/User/Course/FavLessoncard/reducer"

// 5) favorites
// favorites
import fetchViewFavouriteReducer from "./Valet/User/Favourites/Favourites/reducer"

// 6) Account
// add user details
import addUserDetailReducer from "./Valet/User/Account/AddUserDetail/reducer"
// view user details
import fetchViewUserDetailReducer from "./Valet/User/Account/ViewUserDetail/reducer"
// add refer link
import addReferLinkReducer from "./Valet/User/Account/AddReferLink/reducer"
// view refer link
import fetchViewReferLinkReducer from "./Valet/User/Account/ViewReferLink/reducer"
//5) forgot password
import ForgotPasswordReducer from "./Valet/ForgotPassword/reducer"
//5) Reset password
import ResetPasswordReducer from "./Valet/ResetPassword/reducer"

//2) Admin:-
//COMPANY
//Add Company
import addCompanyReducer from "./Valet/Admin/Company/AddCompany/reducers"
// company list
import fetchcompanyReducer from "./Valet/Admin/Company/CompanyList/reducers"
//cpmpany list filter
import fetchcompanyfilterReducer from "./Valet/Admin/Company/CompanyListFilter.js/reducers"
// view company
import fetchViewcompanyReducer from "./Valet/Admin/Company/ViewCompany/reducers"
//edit company
import editCompanyReducer from "./Valet/Admin/Company/EditCompany/reducers"
// delete company
import deletecompanyReducer from "./Valet/Admin/Company/DeleteCompany/reducers"
// company profile
import addCompanyprofileReducer from "./Valet/Admin/Company/CompanyProfile/reducers"
// USER
//user list
import fetchuserReducer from "./Valet/Admin/User/UserList/reducers"
// view user
import fetchViewuserReducer from "./Valet/Admin/User/ViewUser/reducers"
// user transaction
import fetchusertransactionReducer from "./Valet/Admin/User/UserTransaction/reducers"
//BOOKING
//booking list
import fetchbookingReducer from "./Valet/Admin/Booking/Booking List/reducers"
// view booking
import fetchViewbookingReducer from "./Valet/Admin/Booking/View Booking/reducers"
// delete booking
import deletebookingReducer from "./Valet/Admin/Booking/Delete Booking/reducers"
//PAYMENT
//payment list
import fetchpaymentReducer from "./Valet/Admin/Payment/PaymentList/reducers"
// view payment
import fetchViewpaymentReducer from "./Valet/Admin/Payment/ViewPayment/reducers"
//Dashboard
//dashboardData
import fetchdashboardReducer from "./Valet/Admin/Dashboard/reducers"

//3) Company:-
//VALET
//Add Valet
import addvaletReducer from "./Valet/Company/Valet/AddValet/reducers"
// Valet List
import fetchvaletReducer from "./Valet/Company/Valet/ValetList/reducer"
// view valet
import fetchViewvaletsReducer from "./Valet/Company/Valet/ViewValet/reducers"
// delete valet
import deletevaletReducer from "./Valet/Company/Valet/DeleteValet/reducers"
//edit valet
import editValetReducer from "./Valet/Company/Valet/EditValet/reducers"

// BOOKING
//booking list
import fetchbookingvaletReducer from "./Valet/Company/BookingValet/BookingValet List/reducers"
// view booking
import fetchViewbookingvaletReducer from "./Valet/Company/BookingValet/ViewBookingValet/reducers"

//PAYMENT
//payment list
import fetchpaymentvaletReducer from "./Valet/Company/PaymentValet/PaymentValetList/reducers"

//Dashboard
//dashboardData
import fetchdashboardvaletReducer from "./Valet/Company/DashboardValet/reducers"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  projects,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas,
  authReducer,
  addCompanyReducer,
  addvaletReducer,
  fetchcompanyReducer,
  fetchvaletReducer,
  fetchViewcompanyReducer,
  editCompanyReducer,
  deletecompanyReducer,
  fetchViewvaletsReducer,
  deletevaletReducer,
  editValetReducer,
  fetchuserReducer,
  fetchViewuserReducer,
  fetchbookingReducer,
  fetchViewbookingReducer,
  deletebookingReducer,
  fetchbookingvaletReducer,
  addCompanyprofileReducer,
  fetchpaymentReducer,
  fetchbookingvaletReducer,
  fetchViewbookingvaletReducer,
  fetchpaymentvaletReducer,
  fetchdashboardReducer,
  fetchdashboardvaletReducer,
  fetchViewpaymentReducer,
  fetchcompanyfilterReducer,
  fetchusertransactionReducer,
  SignupReducer,
  fetchGoogleDetailReducer,
  addCourseReducer,
  fetchViewCourseReducer,
  addModuleReducer,
  ForgotPasswordReducer,
  ResetPasswordReducer,
  fetchViewModuleReducer,
  fetchTopicDetailReducer,
  fetchViewTopicDetailReducer,
  addTopicNameReducer,
  fetchSingleModuleDetailReducer,
  fetchViewFlashcardReducer,
  fetchSingleFlashcardDetailReducer,
  addFlashcardButtonReducer,
  fetchViewFlashcardButtonReducer,
  FavModuleReducer,
  FavTopicReducer,
  fetchViewFavouriteReducer,
  editFlashcardReducer,
  fetchViewLessoncardDetailReducer,
  FavLessoncardReducer,
  addUserDetailReducer,
  fetchViewUserDetailReducer,
  addReferLinkReducer,
  fetchViewReferLinkReducer,
  editCourseReducer,
  fetchViewRecentModuleReducer,
  restartButtonReducer,
})

export default rootReducer
