import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { FETCH_VIEW_USER_DETAIL_REQUEST } from "./actionType"

import {
  fetchViewUserDetailSuccess,
  fetchViewUserDetailFailure,
} from "./action"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchViewUserDetail(action) {
  try {
    const response = yield call(
      axios.get,
      `${BASE_URL}/api/get_profile/`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchViewUserDetailSuccess(response.data))
  } catch (error) {
    yield put(fetchViewUserDetailFailure(error))
  }
}

function* fetchViewUserDetailSaga() {
  yield takeEvery(FETCH_VIEW_USER_DETAIL_REQUEST, fetchViewUserDetail)
}

export default fetchViewUserDetailSaga
