import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { FETCH_VIEW_LESSONCARD_DETAIL_REQUEST } from "./actionType"

import {
  fetchViewLessoncardDetailFailure,
  fetchViewLessoncardDetailSuccess,
} from "./action"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchViewLessoncardDetail(action) {
  const { ModuleId, TopicId } = action.payload
  try {
    const response = yield call(
      axios.get,
      `${BASE_URL}/api/retrieve-topic-add/${ModuleId}/${TopicId}/`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchViewLessoncardDetailSuccess(response.data))
  } catch (error) {
    yield put(fetchViewLessoncardDetailFailure(error))
  }
}

function* fetchViewLessoncardDetailSaga() {
  yield takeEvery(
    FETCH_VIEW_LESSONCARD_DETAIL_REQUEST,
    fetchViewLessoncardDetail
  )
}

export default fetchViewLessoncardDetailSaga
