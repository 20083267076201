import {
  TOPIC_DETAIL_FAILURE,
  TOPIC_DETAIL_REQUEST,
  TOPIC_DETAIL_SUCCESS,
  TOPIC_DETAIL_CANCEL,
  SET_NETWORK_STATUS,
} from "./actionType"

export const fetchTopicDetailRequest = (formData, successCallBack) => ({
  type: TOPIC_DETAIL_REQUEST,
  payload: {
    data: formData,
    successCallBack: successCallBack,
  },
})

export const fetchTopicDetailSuccess = ViewTopicDetail => ({
  type: TOPIC_DETAIL_SUCCESS,
  payload: ViewTopicDetail,
})

export const fetchTopicDetailFailure = error => ({
  type: TOPIC_DETAIL_FAILURE,
  payload: error,
})

export const cancelTopicDetailRequest = () => ({
  type: TOPIC_DETAIL_CANCEL,
})

export const setNetworkStatus = isOnline => ({
  type: SET_NETWORK_STATUS,
  payload: isOnline,
})
