export const Constant = {
  dashboard: "Dashboard",
  booking: "Booking",
  company: "Company",
  valetdriver: "Valet Driver",
  payment: "Payment",
  user: "User",
  action: "Action",
  name: "Name",
  email: "Email",
  phonenumber: "Phone Number",
  carname: "Car Name",
  // carnumber: "Car Model",
  valetID: "Valet ID",
  valetLicence: "Valet Licence Number",
  vehiclenumber: "Vehicle Number",
  address: "Address",
  password: "Password",
  companyid: "Company ID",
  companyprofile: "Company Profile",
  companydocument: "Company Document",
  companyname: "Company Name",
  date: "Date",
  bookingtime: "Booking Time",
  duration: "Duration",
  amount: "Amount",
  submit: "Submit",
  cancel: "Cancel",
  addcompany: "Add Company",
  ok: "Ok",
  addvalet: "Add Valet",
  valetdocument: "Valet Document",
  viewcompany: "Company Detail",
  viewvalet: "Valet Detail",
  search: "Search",
  bookingid: "Booking ID",
  car: "Car",
  date: "Date",
  starttime: "Start Time",
  endtime: "End Time",
  pickupby: "PickUp By",
  retrieveby: "Retrieve By",
  durationminutes: "Duration (minutes)",
  amount: "Amount",
  logout: "Logout",
  viewbooking: "Booking Detail",
  addplus: "Add +",
  Updatecompany: "Update Company",
  totalcar: "Total Car",
  signupat: "SignUp at",
  updatevalet: "Update Valet",
  paymentmode: "Payment Mode",
  status: "Status",
  currency: "Currency",
  userphonenumber: "User Phone Number",
  companyaddress: "Company Address",
  comapnyemail: "Company Email",
  totalnoofvalet: "Total Number Of Valet",
  totalnoofbooking: "Total Number Of Booking",
  companyfees: "Company Fees",
  userid: "User ID",
  carcolor: "Car Color",
  carnumber: "Car Number",
  transactionsummery: "Transaction Summery",
  totalamount: "Total Amount",
  adminamount: "Admin Amount",
  totaltip: "Total Tip",
  bookingstarttime: "Booking Start Time",
  bookingstartdate: "Booking Start Date",
  bookingenddate: "Booking End Date",
  bookingendtime: "Booking End Time",
  totalbooking: "Total Booking",
  totalvalet: "Total Valet",

  companyphonenumber: "Company Phone Number",
  startdate: "Start Date",
  enddate: "End Date",
  noprofileuploaded: "No Profile Uploaded",
  viewpayment: "Payment Detail",
  viewuser: "User Detail",
  valets: "Valet",
  transactionid: "Transaction ID",
  type: "Type",
  pickupno: "PickUp No.",
  retrieveno: "Retrieve No.",
  year: "Year",
  week: "Week",
  month: "Month",
  sortby: "Sort By",
  filterby: "Filter By",
  az: "A-Z",
  za: "Z-A",
  newest: "Newest",
  oldest: "Oldest",
  valetrange: "Valet Range",
  valet: "Valet",
  bookingstatus: "Booking Status",
  amountrange: "Amount Range",
  new: "New",
  running: "Running",
  completed: "Completed",
  paymentmethod: "Payment Method",
  card: "Card",
  wallet: "Wallet",
  bookingrange: "Booking Range",
  carrange: "Car Range",
  day: "Day",
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  bookingfees: "Booking Fees (per hours in QAR)",
  car: "Car",
  userprofile: "User Profile",
  bookingdate: "Booking Date",
  valetprofile: "Valet Profile",
  parkingduration: "Parking Duration",
  onehour: "1 Hour",
  twohour: "2 Hour",
  threehour: "3 Hour",
  fourhour: "4 Hour",
  fivehour: "5 Hour",
  fivehourplus: "5+ Hour",
  usertransaction: "User Transaction",
  valetlicence: "Valet Licence",
  userlicence: "User Licence",
  username: "User Name",
  valetname: "Valet Name",
  months: "Months",
  tip: "Tip",
  go: "Go",
  currentmonth: "Current Month",
  currentyear: "Current Year",

  amountcharged: "Amount Charged",
  registrationdate: "Registration Date",
  companyregistrationdate: "Company Registration Date",
  applepay: "Apple Pay",
  notransactionavailable: "No Transaction Available",
  carmake: "Car Make",
  amountchargedQAR: "Amount Charged (QAR)",
}
