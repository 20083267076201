import {
  FETCH_VIEW_LESSONCARD_DETAIL_REQUEST,
  FETCH_VIEW_LESSONCARD_DETAIL_FAILURE,
  FETCH_VIEW_LESSONCARD_DETAIL_SUCCESS,
} from "./actionType"

export const fetchViewLessoncardDetailRequest = (ModuleId, TopicId) => ({
  type: FETCH_VIEW_LESSONCARD_DETAIL_REQUEST,
  payload: { ModuleId, TopicId },
})

export const fetchViewLessoncardDetailSuccess = ViewLessoncardDetail => ({
  type: FETCH_VIEW_LESSONCARD_DETAIL_SUCCESS,
  payload: ViewLessoncardDetail,
})

export const fetchViewLessoncardDetailFailure = error => ({
  type: FETCH_VIEW_LESSONCARD_DETAIL_FAILURE,
  payload: error,
})
