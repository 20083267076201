// import "../../App.css"
// import "../Components/Pages/Common/Home.css"
import "../valetCSS/valetapp.css"
import { React, useState, useEffect } from "react"
// import FeaturedInfo from "pages/Components/FeaturedInfo/FeaturedInfo"
// import Chart from "pages/Components/Chart/Chart"
// import BarchartCompany from "./BarchartCompany"
// import FeaturedinfoCompany from "./FeaturedinfoCompany"
// import BarChart from "pages/AllCharts/chartjs/barchart"
// import { userData } from "pages/Components/Chart/dummyData"
import { Route, Redirect, useHistory } from "react-router-dom"
import { FaEdit, FaEye } from "react-icons/fa"
import { MdDeleteOutline } from "react-icons/md"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
// import studentDataObject from "../Components/Controllers/studentController"
// import DashboardCards from "./DashboardCards.component"
import { useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import { Constant } from "pages/Resources/Constant"
import MetaTags from "react-meta-tags"
import { useDispatch } from "react-redux"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import Select from "react-select"
import { fetchViewCourseRequest } from "store/actions"
import { fetchViewModuleRequest } from "store/actions"
import { fetchViewRecentModuleRequest } from "store/actions"
function Home(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  // const ViewCourseData = useSelector(
  //   state => state.fetchViewCourseReducer?.ViewCourse?.data
  // )
  useEffect(() => {
    dispatch(fetchViewRecentModuleRequest())
    dispatch(fetchViewModuleRequest(ViewModuleCallBack))
  }, [])
  const ViewModuleData = useSelector(
    state => state.fetchViewModuleReducer?.ViewModule?.data
  )
  const ViewRecentlyModuleData = useSelector(
    state => state.fetchViewRecentModuleReducer?.ViewRecentModule
  )
  console.log("ViewCourseData", ViewModuleData)
  console.log("ViewRecentlyModuleData", ViewRecentlyModuleData)
  // useEffect(() => {
  //   dispatch(fetchViewModuleRequest(ViewModuleCallBack))
  // }, [])
  const ViewModuleCallBack = () => {}
  const [selectedOption, setSelectedOption] = useState(null)

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption)
    // handle further with the selected option
    console.log(`Option selected:`, selectedOption)
  }
  const reports = [
    {
      title: "Module Title",
      iconClass: "bx-user",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-archive-in",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-purchase-tag-alt",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-swim",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-copy-alt",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-run",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-dollar-circle",
      description: "Reference",
      moduldetails: "Module Detail",
    },
    {
      title: "Module Title",
      iconClass: "bx-group",
      description: "Reference",
      moduldetails: "Module Detail",
    },
  ]
  const options = [
    { value: "A-Z", label: "0" },
    { value: "A-Z", label: "1" },
    { value: "A-Z", label: "2" },
    { value: "A-Z", label: "3" },
    { value: "A-Z", label: "4" },
    { value: "A-Z", label: "5" },
  ]
  const options1 = [
    { value: "A-Z", label: "0" },
    { value: "A-Z", label: "1" },
    { value: "A-Z", label: "2" },
    // { value: "A-Z", label: "3" },
  ]
  const moduleDetailHandler = id => {
    history.push(`/topic_lessioncard/${id}`)
  }
  var classAlign = ""
  if (document.body.dir === "rtl") {
    classAlign = "rtlClass"
  } else {
    classAlign = "ltrClass"
  }
  return (
    <div className={`main-content${classAlign}`}>
      <MetaTags>
        <title>Home| StudyBudi</title>
      </MetaTags>

      <div className="page-content">
        <ToastContainer />
        <div className="inner-page-content">
          <div className="CouseDetailHeading">
            <div style={{ width: "90%", color: "#626565" }}>
              {" "}
              Recently Viewed
            </div>
          </div>
          <div className="Carddiv">
            <Row>
              <Col xl="12">
                <Row>
                  {ViewRecentlyModuleData &&
                    ViewRecentlyModuleData.map((report, key) => (
                      <Col md="6" key={"_col_" + key}>
                        <Card
                          className="mini-stats-wid"
                          style={{
                            backgroundColor: "#FDFDFD",
                            cursor: "pointer",
                            // height: "108px",
                            borderRadius: "10px",
                          }}
                          onClick={() => {
                            moduleDetailHandler(report.id)
                            // history.push("/el_topicdetail")
                          }}
                        >
                          <CardBody>
                            <div className="d-flex justify-content-center">
                              <div
                                className={`avatar-sm rounded-circle  mini-stat-icon `}
                              >
                                <span
                                  className="avatar-title rounded-circle "
                                  style={{
                                    backgroundColor: "#E9F6F7",
                                  }}
                                >
                                  {report.image ? (
                                    <img
                                      // src={`https://studybudi.co.uk/${report.image}`}
                                      src={report.image}
                                      alt="AAAA"
                                      style={{
                                        objectFit: "cover",
                                        width: "100%",
                                        height: "100%",
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                              <div
                                className="flex-grow-1 "
                                style={{
                                  marginLeft: "1vw",
                                }}
                              >
                                <div className="P-Div">
                                  <p
                                    className="text-muted fw-medium"
                                    style={{
                                      color: "#838888",
                                      marginBottom: "0px",
                                      marginLeft: "2vw",
                                    }}
                                  >
                                    {report.Title}
                                  </p>
                                  <span
                                    style={{
                                      alignSelf: "right",
                                      color: "#BFC5C6",
                                    }}
                                  >
                                    6
                                  </span>
                                </div>
                                <p
                                  className="mb-0"
                                  style={{
                                    color: "#838888",
                                    marginLeft: "2vw",
                                    fontSize: "11px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {report.Reference}
                                </p>
                                <p
                                  className="mb-0"
                                  style={{
                                    color: "#838888",
                                    marginLeft: "2vw",
                                    marginTop: "10px",
                                    fontSize: "13px",
                                  }}
                                >
                                  {report.Others}
                                </p>
                                {/* <div className="blueBadgeDiv">
                                  <span className="blueBadgeSmall">
                                    Semester 1
                                  </span>
                                  <span className="blueBadgeSmall">August</span>
                                  <span className="blueBadgeSmall">
                                    Coursework
                                  </span>
                                </div> */}
                              </div>
                              <div style={{ marginLeft: "auto" }}>
                                <h6
                                  className="mb-0"
                                  style={{ color: "#838888" }}
                                >
                                  {report.course}
                                </h6>
                              </div>
                            </div>
                            <div
                              className="newTags-list "
                              style={{
                                // marginLeft: "80px",
                                // zIndex: "999",
                                // marginTop: "-20px",
                                marginBottom: "10px",
                              }}
                            >
                              {report.Tags &&
                                report.Tags.map(tag => (
                                  // <span key={tag} className="tag">
                                  <span
                                    style={{ fontSize: "10px" }}
                                    className="newTags"
                                    key={tag}
                                  >
                                    {tag}
                                  </span>
                                  // </span>
                                ))}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                </Row>
              </Col>
            </Row>
          </div>
          {/* <div className="courseform">
            <div
              style={{
                width: "150px",
                height: "50px",
                backgroundColor: "#0BAFBC",
                borderRadius: "40px",
                display: "flex",
                alignItems: "center",
                paddingLeft: "30px",
                color: "white",
              }}
            >
              Degree
            </div>
            <div className="degreeDetailDiv">
              <div className="degreeDetailText">
                <h6 className="degreeDetaillevel">
                  {ViewCourseData?.Degree_level}
                </h6>
                <h6 style={{ color: "#CAF1F4", letterSpacing: "2px" }}>
                  {ViewCourseData?.Course_name}
                </h6>
                <h6 style={{ color: "#65DEE8", letterSpacing: "2px" }}>
                  {ViewCourseData?.University_name}
                </h6>
              </div>
              {ViewCourseData?.Course_year ? (
                <div className="degreeDetailYear">
                  {`YEAR  ${ViewCourseData?.Course_year}`}
                </div>
              ) : (
                ""
              )}
            </div>
          </div> */}

          <div className="CouseDetailHeading">
            <div style={{ width: "70%", color: "#626565" }}> My Modules</div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                width: "30%",
                color: "#CCCCCC",
                fontSize: "15px",
                // border: "2px solid ",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push("/educationlevel")
                // Window.reload()
              }}
            >
              {/* + */}
              <button
                // onClick={handleSubmit}
                type="submit"
                // onClick={nextClick}
                style={{
                  cursor: "pointer",
                  width: "7rem",
                  height: "2rem",
                  // backgroundColor: "#EFEFEF",
                  backgroundColor: "#171c46",
                  border: "1px solid #BCBCBC",
                  color: "#BCBCBC",
                  borderRadius: "5px",
                }}
              >
                {"Add New"}
              </button>
            </div>
          </div>

          <div className="Carddiv">
            <Row>
              <Col xl="12">
                <Row>
                  {ViewModuleData &&
                    ViewModuleData.map((report, key) => (
                      <Col md="6" key={"_col_" + key}>
                        <Card
                          className="mini-stats-wid"
                          style={{
                            backgroundColor: "#FDFDFD",
                            cursor: "pointer",
                            // height: "108px",
                            borderRadius: "10px",
                          }}
                          onClick={() => {
                            moduleDetailHandler(report.id)
                            // history.push("/el_topicdetail")
                          }}
                        >
                          <CardBody>
                            <div className="d-flex justify-content-center">
                              <div
                                className={`avatar-sm rounded-circle  mini-stat-icon `}
                              >
                                <span
                                  className="avatar-title rounded-circle "
                                  style={{
                                    backgroundColor: "#E9F6F7",
                                  }}
                                >
                                  {report.image ? (
                                    <img
                                      src={`https://studybudi.co.uk/${report.image}`}
                                      alt="AAAA"
                                      style={{
                                        objectFit: "cover",
                                        width: "100%",
                                        height: "100%",
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                              <div
                                className="flex-grow-1 "
                                style={{
                                  marginLeft: "1vw",
                                }}
                              >
                                <div className="P-Div">
                                  <p
                                    className="text-muted fw-medium"
                                    style={{
                                      color: "#838888",
                                      marginBottom: "0px",
                                      marginLeft: "2vw",
                                    }}
                                  >
                                    {report.Title}
                                  </p>
                                  <span
                                    style={{
                                      alignSelf: "right",
                                      color: "#BFC5C6",
                                    }}
                                  >
                                    6
                                  </span>
                                </div>
                                <p
                                  className="mb-0"
                                  style={{
                                    color: "#838888",
                                    marginLeft: "2vw",
                                    fontSize: "11px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {report.Reference}
                                </p>
                                <p
                                  className="mb-0"
                                  style={{
                                    color: "#838888",
                                    marginLeft: "2vw",
                                    marginTop: "10px",
                                    fontSize: "13px",
                                  }}
                                >
                                  {report.Others}
                                </p>
                                {/* <div
                                  className="newTags-list"
                                  style={{
                                    paddingLeft: "80px",
                                    zIndex: "999",
                                    // marginTop: "-20px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {options &&
                                    options.map(tag => (
                                      // <span key={tag} className="tag">
                                      <span
                                        style={{ fontSize: "10px" }}
                                        className="newTags"
                                        key={tag}
                                      >
                                        Semesterqwertyu
                                      </span>
                                      // </span>
                                    ))}
                                </div> */}
                                {/* <div
                                  className="blueBadgeDiv"
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "8px",
                                    // paddingLeft: "20px",
                                  }}
                                >
                                  <span className="blueBadgeSmall">
                                    Semesterqwertyu
                                  </span>
                                  <span className="blueBadgeSmall">
                                    Semesterqwertyu
                                  </span>
                                  <span className="blueBadgeSmall">
                                    Semesterqwertyu
                                  </span>
                                  <span className="blueBadgeSmall">
                                    Semesterqwertyu
                                  </span>
                                </div> */}
                              </div>
                              <div style={{ marginLeft: "auto" }}>
                                <h6
                                  className="mb-0"
                                  style={{ color: "#838888" }}
                                >
                                  {report.course}
                                </h6>
                              </div>
                            </div>
                            <div
                              className="newTags-list"
                              style={{
                                // marginLeft: "80px",
                                // zIndex: "999",
                                // marginTop: "-20px",
                                marginBottom: "10px",
                              }}
                            >
                              {report.Tags &&
                                report.Tags.map(tag => (
                                  // <span key={tag} className="tag">
                                  <span
                                    style={{ fontSize: "10px" }}
                                    className="newTags"
                                    key={tag}
                                  >
                                    {tag}
                                  </span>
                                  // </span>
                                ))}
                            </div>
                            {/* <div
                              className="blueBadgeDiv"
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "8px",
                                marginLeft: "50px",
                              }}
                            >
                              <span className="blueBadgeSmall">
                                Semesterqwertyu
                              </span>
                              <span className="blueBadgeSmall">
                                Semesterqwertyu
                              </span>
                              <span className="blueBadgeSmall">
                                Semesterqwertyu
                              </span>
                              <span className="blueBadgeSmall">
                                Semesterqwertyu
                              </span>
                            </div> */}
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}
Home.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Home)
