import { put, call, takeEvery, takeLatest } from "redux-saga/effects"
import axios from "axios"
import { toast } from "react-toastify"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"
import { ADD_COURSE_REQUEST } from "./actionType"
import { addCourseFailure, addCourseSuccess } from "./action"

function* addCourse(action) {
  const {
    Degree_level,
    Course_name,
    Course_year,
    University_name,
    successCallBack,
  } = action.payload
  try {
    const response = yield call(
      axios.post,
      `${BASE_URL}/api/create_course_detail/`,

      { Degree_level, Course_name, Course_year, University_name },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(addCourseSuccess(response.data))
    sessionStorage.setItem("CourseID", response.data.data.id)
    sessionStorage.setItem("UserID", response.data.data.user_id)
    console.log("addC-->", response.data, action.payload)
    successCallBack(response.data)
    // toast.success(`${success}`)
  } catch (error) {
    console.log("err====>", error.response)
    const loginError = error.response
    yield put(addCourseFailure(error.message))
    // toast.error()
  }
}

export default function* addCourseSaga() {
  yield takeLatest(ADD_COURSE_REQUEST, addCourse)
}
