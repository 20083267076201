import { call, put, takeEvery } from "redux-saga/effects"
import axios from "axios"

import { FETCH_SINGLE_FLASHCARD_DETAIL_REQUEST } from "./actionType"

import {
  fetchSingleFlashcardDetailFailure,
  fetchSingleFlashcardDetailSuccess,
} from "./action"
import { BASE_URL } from "store/Valet/BaseURL/baseURL"

function* fetchSingleFlashcardDetail(action) {
  const { id, successCallBack, order } = action.payload
  try {
    const response = yield call(
      axios.get,
      // `${BASE_URL}/api/cuecards/${id}/`,
      `${BASE_URL}/api/cuecard-detail/${id}/`,

      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    yield put(fetchSingleFlashcardDetailSuccess(response.data))
    successCallBack(response.data, order)
  } catch (error) {
    yield put(fetchSingleFlashcardDetailFailure(error))
  }
}

function* fetchSingleFlashcardDetailSaga() {
  yield takeEvery(
    FETCH_SINGLE_FLASHCARD_DETAIL_REQUEST,
    fetchSingleFlashcardDetail
  )
}

export default fetchSingleFlashcardDetailSaga
